import React, {
  useContext,
  useState,
  useEffect,
  // Component,
  useLayoutEffect,
} from 'react'
import useFetcher from '../../../hooks/useFetcher'
import {GlobalContext} from '../../../state'
import tokenFromLS from '../../../utils/tokenFromLS'
import {useFetchAccount} from '../../../hooks/useFetchAccount'
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import InputField from '../../EditFields/Input/InputField'
import Select from '../../EditFields/Select/Select'
import {getSymbolBase, getSymbolQuote} from '../../../utils/symbol'
import {
  buildAccountOptions,
  getExchangeFromAccount,
} from '../../../hooks/useFetchAccount'
// import {AdvancedChart} from 'react-tradingview-embed'
// import { TradingViewEmbed } from "react-tradingview-embed";
// import {isObject} from '@amcharts/amcharts5/.internal/core/util/Type'
import tokenfromLS from '../../../utils/tokenFromLS'
import {useNavigate, useParams} from 'react-router-dom'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {sanitizeGridbotData} from '../../../utils/sanatizer'
import Modal, {useModal} from '../../Modal/Modal'
import Datafeed from "../../../utils/datafeed/datafeed";
import {widget} from "charting_library";
import {isNaN, isObject} from "@amcharts/amcharts5/.internal/core/util/Type";
import CBApi from "../../../utils/datafeed/CBApi";

const GridbotDetailPage = () => {
  const globalState = useContext(GlobalContext)
  const {openModal} = useModal();
  let params = useParams();
  let id = params.id
  // const [botData, setBotData] = useFetchGridbot(id)
  const [botData, setBotData] = useFetcher(
    `gridbot/${id}`,
    'GET',
    sanitizeGridbotData
  )

  const [addFunds, setAddFunds] = useState([])

  const [formSubmitMsg, setformSubmitMsg] = useState(null)
  // const [LogData, setLogData] = useState([])
  const [showModalAddFunds, setShowModalAddFunds] = useState(false)
  const [lastTrades, setLastTrades] = useState([]);
  const [activeChart, setActiveChart] = useState(null)
  const [changeRangeInTV, setChangeRangeInTV] = useState(null)

  const navigate = useNavigate()

  // const [botData, setBotData] = useFetcher(
  //   `gridbot/${id}`,
  //   'GET',
  //   sanitizeGridbotData
  // )

  const [accountData] = useFetchAccount()
  // const [accountData, setAccountData] = useFetcher(
  //   `account/${botData?.account_id}/market_pairs`,
  //   'GET',
  // )

  //(const [symbolData] = useFetchSymbols(botData.account_id)
  const [symbols, setSymbols] = useState([])

  function getSymbols(accountId) {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/account/${accountId}/market_pairs`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()
  }

  // profitcomplete
  const [ProfitComplete, setProfitComplete] = useState([])

  function getBotLastTrades(botId, from = 0, to = 0) {
    const resource = `gridbot/${botId}/order/tv`
    const params = {
      // per_page: 500
    }
    if (from > 0) {
      params.from = from
    }
    if (to > 0) {
      params.to = to
    }
    return CBApi.fetch(resource, 'POST', params)
  }

  function sendOrder(type, id) {
    const botId = botData._id
    console.log('sendOrder', type, id, botId)
    console.log('botData', botData)
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/gridbot/${botId}/order/${type}/${id}`
    const fetchOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()

  }

  const getTvChart = () => {
    if (!accountData || accountData.length === 0 || !botData || !botData.account_id) {
      return null
    }
    const exchange = getExchangeFromAccount(accountData, botData.account_id, 'type')
    let symbol = `${exchange}:${botData.symbol}`

    // get First Element of accountData for userId
    const userId = accountData[0].user_id || 'none'

    const widgetOptions = {
      debug: false,
      autosize: true,
      // datafeed: '${Datafeed}',
      // datafeed: new UDFCompatibleDatafeed("https://devapi.bot.nwan.de/tvapi"),
      // datafeed: new UDFCompatibleDatafeed("https://demo_feed.tradingview.com"),
      datafeed: Datafeed,
      charts_storage_api_version: "1.1",
      charts_storage_url: "https://saveload.tradingview.com",
      client_id: "tradingview.com",
      container: "tv_chart_container",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "symbol_search_hot_key",
        "header_compare",
      ],
      enabled_features: [
        "fix_left_edge",
      ],
      fullscreen: false,
      // interval: "5",
      interval: "1h",
      // interval: "D",

      // interval: "1h",
      library_path: "../../charting_library/",
      locale: "en",
      studies_overrides: undefined,
      symbol: symbol,
      theme: "Dark",
      timezone: "Etc/UTC",
      user_id: "xyv-cb-" + userId,
      // overrides: {
      //   "mainSeriesProperties.style": 8,
      // },
    };

    /**
     * @todo add the 'charting_library' back into this component
     * to make the widget work again.
     */

    const tvWidget = new widget(widgetOptions);
    window.tvWidget = tvWidget;
    tvWidget.onChartReady(() => {

      // Fetch TradingView Lines from the api and ask a function:
      const apiHost = process.env.REACT_APP_API_URI
      const resource = `${apiHost}/gridbot/${id}/cryptowidget`
      const fetchOptions = {
        headers: {
          Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
        },
      }
      const fetchData = async () => {
        try {
          const response = await fetch(resource, fetchOptions)
          return await response.json()
        } catch (error) {
          console.log('error', error)
          return null
        }
      }
      fetchData().then((data) => {
        // console.log('fetchData', data)
        const _current_strategy = data && data.current_strategy ? data.current_strategy : 'long'
        const _current_price = data && data.current_price ? data.current_price : 0.0
        const _current_price_average = data && data.current_price_average ? data.current_price_average : 0.0
        const _current_unrealized_profit = data && data.current_unrealized_profit ? _current_strategy === 'long' ? data.current_unrealized_profit : -data.current_unrealized_profit : 0.0
        const _current_amount_total = data && data.current_amount_total ? _current_strategy === 'long' ? data.current_amount_total : -data.current_amount_total : "0"
        const _current_trailing_price = data && data.current_trailing_price ? data.current_trailing_price : 0.0
        let _current_price_color = 'rgb(237, 85, 101)'
        if (_current_price > _current_price_average) {
          _current_price_color = 'rgb(26, 179, 148)'
        }

        if (!tvWidget) {
          return
        }
        const ActiveChart = tvWidget.activeChart() || null

        if (!ActiveChart) {
          return
        }

        tvWidget.activeChart().onVisibleRangeChanged().subscribe(null, function (range) {
          // console.log('range', range)
          // console.log('range', range.from, range.to)
          // get all past trades:
          // getBotLastTrades(botData._id, range.from, range.to).then(result => setLastTrades(result));
          setChangeRangeInTV(range)
        })

        tvWidget.activeChart().onSymbolChanged().subscribe(null, function (symbol) {
          setChangeRangeInTV(null)
          getBotLastTrades(botData._id).then(result => setLastTrades(result));
        })

        tvWidget.activeChart().onIntervalChanged().subscribe(null, function (interval) {
          setChangeRangeInTV(null)
          getBotLastTrades(botData._id).then(result => setLastTrades(result));
        })

        // Current price line:
        if (ActiveChart && _current_price_average !== 0.0) {
          ActiveChart.createPositionLine()
            .setTooltip('Current average price')
            .setText('Average | ' + _current_unrealized_profit + " " + getSymbolQuote(botData.symbol))
            .setPrice(_current_price_average)
            // .setQuantity(1)
            .setLineColor(_current_price_color)
            // Line style: 0 - solid, 1 - dashed, 2 - dotted, 3 - large dashed, 4 - large dotted
            .setLineStyle(4)
            .setQuantity(_current_amount_total ?? "0")
        }

        // Trailing SO:
        if (ActiveChart && _current_trailing_price > 0.0) {
          ActiveChart.createOrderLine()
            .setTooltip("Trailing Order\n"
              + "Name: " + data.current_trailing_name + "\n" +
              + "Price: " + _current_trailing_price + "\n")
            .setText('Trailing SO | ' + _current_trailing_price + " " + getSymbolQuote(botData.symbol))
            .setPrice(_current_trailing_price)
            .setLineColor("#FFB0B1")
            .setLineStyle(4)

        }

        // Current buy and sell lines:
        for (let i = 0; i < Object.keys(data ?? []).length - 1; i++) {
          if (isObject(data[i]) && data[i].id) {
            let item = data[i]
            // data.forEach((item) => {
            // console.log('item', item)

            // is Object:
            if (isObject(item) && (item.state === 'created' || item.state === 'waiting')) {
              // "0": {
              //   "id": 1589,
              //     "side": "sell",
              //     "state": "created",
              //     "type": "limit",
              //     "amount": 13.051,
              //     "price": 18.3467,
              //     "name": "bo_tp_0",
              //     "exchange_createdate": "2023-11-06 23:00:30",
              //     "exchange_filled": 0,
              //     "exchange_lasttradedate": "2023-11-06 23:00:30",
              //     "exchange_remaining": 13.051
              // },
              if (item.side === 'sell') {
                tvWidget.activeChart().createOrderLine()
                  // .setTooltip(item.tooltip)
                  // .setModifyTooltip(item.modifytooltip)
                  // .setCancelTooltip(item.canceltooltip)
                  // .onMove(function () {
                  //   console.log('Order line moved')
                  // })
                  // .onModify(function () {
                  //   console.log('Order line modified')
                  // })
                  .onCancel(function () {
                    sendOrder('cancel', item.id)
                  })
                  .setText(item.name)
                  .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
                  .setQuantity(item.amount)
                  .setPrice(item.price)
                  .setLineColor("#00A59A")
                  .setLineStyle(4)
                  .setQuantityTextColor("#9aedeb")
                  .setBodyBackgroundColor("#04605a")
                  .setBodyTextColor("#67fdf5")
                  .setQuantityBackgroundColor("#078078")
                  .setQuantityTextColor("#02eadc")
                  .setCancelButtonBackgroundColor("#04605a")
                  .setCancelButtonIconColor("#02eadc")
                  .setBodyBorderColor("#10d6c8")
                  .setQuantityBorderColor("#10d6c8")
                  .setCancelButtonBorderColor("#10d6c8")
              }
              if (item.side === 'buy') {
                tvWidget.activeChart().createOrderLine()
                  // .setTooltip(item.tooltip)
                  .onCancel(function () {
                    sendOrder('cancel', item.id)
                  })
                  .setText(item.name)
                  .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
                  .setQuantity(item.amount)
                  .setPrice(item.price)
                  .setLineColor("#4181BE")
                  .setLineStyle(4)
                  .setQuantityTextColor("#9ab4ed")
                  .setBodyBackgroundColor("#0a3b6b")
                  .setBodyTextColor("#6e8fd6")
                  .setQuantityBackgroundColor("#0a3b6b")
                  .setQuantityTextColor("#6e8fd6")
                  .setCancelButtonBackgroundColor("#0a3b6b")
                  .setCancelButtonIconColor("#6e8fd6")
                  .setBodyBorderColor("#6e8fd6")
                  .setQuantityBorderColor("#6e8fd6")
                  .setCancelButtonBorderColor("#6e8fd6")



              }
            }
            // if (isObject(item) && item.state === 'completed') {
            //   // "1": {
            //   //   "id": 1590,
            //   //     "side": "buy",
            //   //     "state": "filled",
            //   //     "type": "limit",
            //   //     "amount": 13.051,
            //   //     "price": 18.3467,
            //   //     "name": "bo_tp_0",
            //   //     "exchange_createdate": "2023-11-06 23:00:30",
            //   //     "exchange_filled": 13.051,
            //   //     "exchange_lasttradedate": "2023-11-06 23:00:30",
            //   //     "exchange_remaining": 0
            //   // },
            //   if (item.side === 'sell') {
            //     tvWidget.activeChart().createOrderLine()
            //       .setTooltip(item.tooltip)
            //       .setText(item.name)
            //       .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
            //       .setQuantity(item.amount)
            //       .setPrice(item.price)
            //       .setLineColor("#00A59A")
            //   }
            //   if (item.side === 'buy') {
            //     tvWidget.activeChart().createOrderLine()
            //       .setTooltip(item.tooltip)
            //       .setText(item.name)
            //       .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
            //       .setQuantity(item.amount)
            //       .setPrice(item.price)
            //       .setLineColor("#4181BE")
            //   }
            // }
          }
        }
      })

      setActiveChart(tvWidget.activeChart())

      // get all past trades:
      getBotLastTrades(botData._id).then(result => setLastTrades(result));

      // tvWidget.activeChart().createOrderLine()
      //   .setTooltip('My order')
      //   // .setModifyTooltip('Modify my order')
      //   .setCancelTooltip('Cancel my order')
      //   .onMove(function () {
      //     console.log('Order line moved')
      //     return false
      //   })
      //   // .onModify(function () {
      //   //   console.log('Order line modified')
      //   // })
      //   .onCancel(function () {
      //     console.log('Order line canceled')
      //   })
      //   .setText('Text on Order')
      //   .setQuantity("2");
      // tvWidget.activeChart().createPositionLine()
      //   .setTooltip('My position')
      //   .setText('Text on Position')
      //   // .setPrice()
      //    .setQuantity("23");
      //
      // 47860: (e,t,n)=>{
      //   n.d(t, {
      //     g: ()=>i,
      //     i: ()=>f
      //   });
      //   var r = n(28663)
      //     , o = n(79872)
      //     , a = {
      //     Dark: {
      //       "body-background-color": "rgba(24, 33, 41, 0.6)",
      //       "quantity-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-text-color": "rgb(255, 255, 255)",
      //       "sl-quantity-background-color": "#EE7D8B",
      //       "tp-quantity-background-color": "#00A59A",
      //       "buy-quantity-background-color": "#1E72D2",
      //       "sl-line-color": "#DFAD70",
      //       "tp-line-color": "#50B095",
      //       "buy-line-color": "#4181BE",
      //       "pending-line-color": "#929da8"
      //     },
      //     Light: {
      //       "body-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-text-color": "rgb(255, 255, 255)",
      //       "sl-quantity-background-color": "#EE7D8B",
      //       "tp-quantity-background-color": "#00A59A",
      //       "sl-line-color": "#E8AC65",
      //       "tp-line-color": "#50B095",
      //       "buy-line-color": "#4181BE",
      //       "pending-line-color": "#929da8"
      //     }
      //   }
      // , u = function(e, t) {
      //     return "BUY" === e ? "Light" === t ? "#06634f" : "#00ffd1" : "Light" === t ? "#870101" : "#FFB0B1"

      // , s = function(e, t) {
      //     var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "Light"
      //       , r = (null == t ? void 0 : t.iconColor) || u(t.side, n)
      //       , o = (null == t ? void 0 : t.iconSize) || 15
      //       , a = (null == t ? void 0 : t.icon) || ("BUY" === t.side ? 61656 : 61655);
      //     return {
      //       type: "mark",
      //       value: e.createShape({
      //         time: t.execution_time,
      //         price: Number(t.price)
      //       }, {
      //         shape: "icon",
      //         icon: a,
      //         zOrder: "top",
      //         overrides: {
      //           size: o,
      //           color: r
      //         }
      //       })
      //     }
      //   }
      // // , l = function(e, t) {
      //     var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "Light"
      //       , o = e.createOrderLine()
      //       , a = t.options
      //       , i = void 0 === a ? {} : a
      //       , u = i.lineHeight
      //       , s = i.bodyBorderColor
      //       , l = i.bodyTextColor
      //       , f = i.lineColor
      //       , b = i.quantityBorderColor
      //       , p = i.quantityTextColor
      //       , d = i.quantityBackgroundColor
      //       , y = i.cancelButtonBorderColor
      //       , m = i.cancelButtonIconColor
      //       , v = i.cancelButtonBackgroundColor
      //       , h = i.bodyBackgroundColor
      //       , g = i.lineStyle
      //       , w = i.hidePriceLabel;
      //     t.editable && (o.onMove(function() {
      //       t.onMove(this.getPrice(), o)
      //     }).onModify("onModify called", function() {}),
      //     t.onCancel && o.onCancel(t.onCancel)),
      //     void 0 !== w && w && (o._line.customization.forcePriceAxisLabel = !1);
      //     var A = c(n);
      //     return o.setPrice(t.price).setText(t.text).setQuantity(t.quantity).setLineStyle(g || 0).setQuantityBackgroundColor(d || A("quantity-background-color")).setQuantityTextColor(p || A("quantity-text-color")).setBodyBackgroundColor(h || A("body-background-color")),
      //       t.type === r.U_.Sl ? o.setLineLength(u || 7).setBodyBorderColor(s || A("sl-line-color")).setBodyTextColor(l || A("sl-line-color")).setLineColor(f || A("sl-line-color")).setQuantityBackgroundColor(A("sl-quantity-background-color")).setQuantityBorderColor(A("sl-quantity-background-color")).setCancelButtonBorderColor(y || A("sl-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("sl-line-color")) : t.type === r.U_.Tp || t.type === r.U_.Mp ? o.setLineLength(7).setBodyBorderColor(s || A("tp-line-color")).setBodyTextColor(l || A("tp-line-color")).setLineColor(f || A("tp-line-color")).setQuantityBackgroundColor(A("tp-quantity-background-color")).setQuantityBorderColor(A("tp-quantity-background-color")).setCancelButtonBorderColor(y || A("tp-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("tp-line-color")) : t.type === r.U_.Buy ? o.setLineLength(u || 7).setBodyBorderColor(b || A("buy-line-color")).setBodyTextColor(l || A("buy-line-color")).setLineColor(f || A("buy-line-color")).setQuantityBackgroundColor(A("buy-quantity-background-color")).setQuantityBorderColor(A("buy-quantity-background-color")).setCancelButtonBorderColor(y || A("buy-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("buy-line-color")) : t.type === r.U_.Pnd && o.setLineLength(u || 7).setBodyBorderColor(s || A("pending-line-color")).setBodyTextColor(l || A("pending-line-color")).setLineColor(f || A("pending-line-color")).setQuantityBorderColor(b || A("pending-line-color")).setCancelButtonBorderColor(y || A("pending-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("pending-line-color")),
      //     t.trailing && o.setLineStyle(2),
      //       {
      //         type: "orderLine",
      //         value: o
      //       }
      //   }
      //     , f = function(e, t) {
      //     try {
      //       "mark" === e.type ? t.removeEntity(e.value) : e.value.remove()
      //     } catch (e) {}
      //   }

    })
  };

  function getProfitComplete(id) {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/gridbot/${id}/profitcomplete`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()
  }

  useEffect(() => {
    if (botData && botData.account_id) {
      getSymbols(botData.account_id).then((data) => setSymbols(data))
    }
    if (botData && botData._id) {
      getProfitComplete(botData._id).then((data) => setProfitComplete(data))
    }
  }, [botData])

  useEffect(() => {
    // console.log('useEffect', accountData)
    // Special: For add a bot:
    if (
      botData &&
      !botData.account_id &&
      accountData &&
      accountData.length > 0
    ) {
      // console.log('useEffect accountdata: ', accountData)
      setBotData({...botData, account_id: accountData[0]._id})
    }
  }, [accountData])

  useEffect(() => {
    // Old buy and sell lines:
    const Window = window
    const tvWidget = Window.tvWidget
    if (!tvWidget) {
      return
    }
    if (!activeChart) {
      return
    }
    if (!lastTrades) {
      return
    }
    // console.log(activeChart);
    // console.log(lastTrades);

    if (lastTrades?.status === 'ok') {
      // console.log('lastTrades', lastTrades.data)
      const Strategy = botData.strategy ?? 'long';

      const BuyColor = Strategy === 'long' ? '#4181BE' : '#00A59A';
      const SellColor = Strategy === 'long' ? '#00A59A' : '#4181BE';

      // draw all trades:
      lastTrades.data.forEach((trade) => {
        // console.log('time', new Date(trade.exchange_lasttradedate).getTime() )
        const CurrentTimeStamp = new Date(trade.exchange_lasttradedate).getTime() / 1000 - (new Date().getTimezoneOffset() * 60);

        // check activeChart timestamp and trade timestamp
        // tvWidget.activeChart().getVisibleRange().

        // Check CurrentTimeStamp
        if (!isNaN(CurrentTimeStamp) && activeChart && activeChart.getVisibleRange().from <= CurrentTimeStamp && activeChart.getVisibleRange().to >= CurrentTimeStamp) {

          // Check, if the shape already exist:
          if (trade.ShapeId) {
            if (activeChart.getShapeById(trade.ShapeId)) {
              return
            }
          }

          if (trade.side === 'buy') {
            // Icon:
            trade.ShapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              // shape: 'arrow_up',
              shape: 'icon',
              icon: 62299, // 62299 - f35b - arrow_up
              lock: true,
              showInObjectsTree: false,
              disableSelection: true,
              zOrder: "top",
              // Hex Number:
              overrides: {
                color: BuyColor,
                size: 18,

                text: (trade.name ?? ''),

              }
            })
            //
            // if (EntityId) {
            //   console.log('EntityId', EntityId)
            // }
            // Text:
            activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              shape: 'note',
              text: (trade.name ?? '') + ' Id: ' + trade.id + '\nAverage: ' + trade.exchange_average + ' Filled: ' + trade.exchange_filled,
              lock: true,
              showInObjectsTree: false,
              // disableSelection: true,
              zOrder: "top",
              overrides: {
                markerColor: 'rgba(0,0,0,0)', // Transparent
                backgroundColor: BuyColor,
                backgroundTransparency: 30,
                borderColor: '#000000',
                transparency: 0,
              }
            })

          }
          if (trade.side === 'sell') {
            // Icon:
            trade.ShapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              // shape: 'arrow_down',
              shape: 'icon',
              icon: 62296, // 62296 - f358 - arrow_down
              lock: true,
              showInObjectsTree: false,
              zOrder: "top",
              overrides: {
                color: SellColor,
                size: 18,
                text: trade.name ?? '',
              }
            })
            // Text:
            activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              shape: 'note',
              text: (trade.name ?? '') + ' Id: ' + trade.id + '\nAverage: ' + trade.exchange_average + ' Filled: ' + trade.exchange_filled,
              lock: true,
              showInObjectsTree: false,
              // disableSelection: true,
              zOrder: "top",
              overrides: {
                markerColor: 'rgba(0,0,0,0)', // Transparent
                backgroundColor: SellColor,
                backgroundTransparency: 30,
                borderColor: '#000000',
                transparency: 0,
              }
            })
          }
        }
      })
    }
    // CurrentChart.createShape({
    //   time: new Date().getTime(),
    //   price: 0.4967,
    // }, {
    //   shape: 'arrow_down',
    //   overrides: {
    //     size: 5,
    //     color: '#ff0000',
    //   }
    // })

    //}, [lastTrades, window?.tvWidget])
  }, [lastTrades, activeChart, changeRangeInTV])

  useEffect(() => {
    if (botData && accountData) {
      // initOnReady();
      getTvChart();
    }
  }, [botData?.symbol, accountData]);


  function mapSymbols(arr) {
    if (arr && arr.length) {
      return arr.map((item) => ({name: item.symbol, value: item.symbol}))
    }
    return []
  }

  function onChangeFirstInput(evt) {
    setBotData(function (prevState) {
      return {
        ...prevState,
        name: evt.target.value,
      }
    })
  }

  function getLimitsFromSymbol(aSymbol) {
    let ReturnSymbol = {}
    if (symbols.length) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol) {
          ReturnSymbol = item
        }
      })
    }
    return ReturnSymbol
  }

  function getPrecisionFromSymbol(aSymbol) {
    let ReturnSymbol = {
      base: 8,
      quote: 8,
      amount: 0,
      price: 0,
    }
    if (
      symbols &&
      symbols.length &&
      symbols.length > 0 &&
      aSymbol &&
      aSymbol !== ''
    ) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol && item.precision) {
          ReturnSymbol.base = item.precision.base
            ? item.precision.base
            : ReturnSymbol.base
          ReturnSymbol.quote = item.precision.quote
            ? item.precision.quote
            : ReturnSymbol.quote
          ReturnSymbol.amount = item.precision.amount
            ? item.precision.amount
            : ReturnSymbol.amount
          ReturnSymbol.price = item.precision.price
            ? item.precision.price
            : ReturnSymbol.price
        }
      })
    }
    // console.log('getPrecisionFromSymbol', ReturnSymbol)
    return ReturnSymbol
  }

  function getLimitsFromSymbol(aSymbol) {
    let ReturnSymbol = {
      amount: {
        min: null,
        max: null,
      },
      cost: {
        min: null,
        max: null,
      },
      leverage: {
        min: null,
        max: null,
      },
      market: {
        min: null,
        max: null,
      },
      price: {
        min: null,
        max: null,
      },
    }
    if (symbols.length) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol && item.limits) {
          ReturnSymbol.amount.min =
            item.limits && item.limits.amount && item.limits.amount.min
              ? item.limits.amount.min
              : null
          ReturnSymbol.amount.max =
            item.limits && item.limits.amount && item.limits.amount.max
              ? item.limits.amount.max
              : null
          ReturnSymbol.cost.min =
            item.limits && item.limits.cost && item.limits.cost.min
              ? item.limits.cost.min
              : null
          ReturnSymbol.cost.max =
            item.limits && item.limits.cost && item.limits.cost.max
              ? item.limits.cost.max
              : null
          ReturnSymbol.leverage.min =
            item.limits && item.limits.leverage && item.limits.leverage.min
              ? item.limits.leverage.min
              : null
          ReturnSymbol.leverage.max =
            item.limits && item.limits.leverage && item.limits.leverage.max
              ? item.limits.leverage.max
              : null
          ReturnSymbol.market.min =
            item.limits && item.limits.market && item.limits.market.min
              ? item.limits.market.min
              : null
          ReturnSymbol.market.max =
            item.limits && item.limits.market && item.limits.market.max
              ? item.limits.market.max
              : null
          ReturnSymbol.price.min =
            item.limits && item.limits.price && item.limits.price.min
              ? item.limits.price.min
              : null
          ReturnSymbol.price.max =
            item.limits && item.limits.price && item.limits.price.max
              ? item.limits.price.max
              : null
        }
      })
    }
    return ReturnSymbol
  }

  const onSubmitForm = async (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)
    const {diff_price_min, diff_price_max, diff_value, diff_buy_type} = data
    delete data.diff_price_min
    delete data.diff_price_max
    delete data.diff_value
    delete data.diff_buy_type
    // // delete data.id
    data = {
      ...data,
      diff: {
        diffbuy: {
          type: diff_buy_type,
        },
        diffsell: {
          type: diff_buy_type,
        },
        price_min: diff_price_min,
        price_max: diff_price_max,
        value: diff_value,
      },
      gridentry: diff_price_max,
    }
    // console.log('data', data)
    // console.log('botData', botData)
    //   api: api,
    //   secret: {
    //     secretKey,
    //     apiKey,
    //     secretToken,
    //   }
    // }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/gridbot/${id}`
      let Method = 'PUT'
      if (id === 'add') {
        RequestURL = `${process.env.REACT_APP_API_URI}/gridbot`
        Method = 'ADD'
        delete data._id
      }
      const response = await fetch(RequestURL, {
        method: Method,
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })

      const json = await response.json()
      if (json.status === 'ok') {
        navigate('/gridbot')
      } else {
        openModal({
          content: 'Error: ' + (json.error || ' ') + (JSON.stringify(json?.data) || ' ') + (JSON.stringify(json?.error_description) || ' ' + JSON.stringify(json?.message) || ' '),
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        });
      }
    } catch (err) {
      openModal({
        content: 'Error: ' + err,
        showDismissButton: true,
        dismissButtonTxt: 'Close',
        showFooter: true,
      });
    }
  }

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_USD')) {
      return
    }

    let root_stats_trades = am5.Root.new('chart_profit_USD')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_stats_trades.setThemes([am5themes_Animated.new(root_stats_trades)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_stats_trades.container.children.push(
      am5xy.XYChart.new(root_stats_trades, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root_stats_trades, {
        behavior: 'zoomX',
      })
    )
    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root_stats_trades, {}));
    cursor.lineY.set('visible', false)

    let date = new Date()
    date.setHours(0, 0, 0, 0)
    let value = 100

    function generateData() {
      value = Math.round(Math.random() * 10 - 5 + value)
      am5.time.add(date, 'day', 1)
      return {
        date: date.getTime(),
        value: value,
      }
    }

    function generateDatas(count) {
      let data = []
      for (var i = 0; i < count; ++i) {
        data.push(generateData())
      }
      return data
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root_stats_trades, {
        // maxDeviation: 0.3,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root_stats_trades, {}),
        tooltip: am5.Tooltip.new(root_stats_trades, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_stats_trades, {
        renderer: am5xy.AxisRendererY.new(root_stats_trades, {}),
        // tooltip: am5.Tooltip.new(root_stats_trades, {})
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root_stats_trades, {
        name: 'profit_USD',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'profit_USD',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root_stats_trades, {
          labelText: '{valueY}',
        }),
      })
    )

    // Chart title
    let title = chart.plotContainer.children.push(
      am5.Label.new(root_stats_trades, {
        text: 'Daily Profit in $',
        fontSize: 20,
        fontWeight: '400',
        x: am5.p50,
        centerX: am5.p50,
      })
    )

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      for (let i = 0; i < ProfitComplete.stats_daily.length; i++) {
        let dataentry = {
          date: new Date(ProfitComplete.stats_daily[i]._id).getTime(),
          profit: parseFloat(ProfitComplete.stats_daily[i].profit),
          profit_USD: parseFloat(ProfitComplete.stats_daily[i].profit_USD),
          profit_percent: parseFloat(ProfitComplete.stats_daily[i].profit_percent),
          count: parseFloat(ProfitComplete.stats_daily[i].count),
          fee_calcUSD: parseFloat(ProfitComplete.stats_daily[i].fee_calcUSD),
        }
        data.push(dataentry)
      }
    }

    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root_stats_trades.dispose()
    }
  }, [ProfitComplete])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_stacked')) {
      return
    }

    let root_profit_stacked = am5.Root.new('chart_profit_stacked')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_stacked.setThemes([am5themes_Animated.new(root_profit_stacked)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_profit_stacked.container.children.push(
      am5xy.XYChart.new(root_profit_stacked, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root_profit_stacked, {
        behavior: 'zoomX',
      })
    )
    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root_profit_stacked, {}));
    cursor.lineY.set('visible', false)

    let date = new Date()
    date.setHours(0, 0, 0, 0)
    let value = 100

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root_profit_stacked, {
        // maxDeviation: 0.3,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root_profit_stacked, {}),
        tooltip: am5.Tooltip.new(root_profit_stacked, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_profit_stacked, {
        renderer: am5xy.AxisRendererY.new(root_profit_stacked, {}),
        // tooltip: am5.Tooltip.new(root_stats_trades, {})
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root_profit_stacked, {
        connect: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'profit_USD',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root_profit_stacked, {
          labelText: '{valueY}',
        }),
      })
    )

    series.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    })

    series.strokes.template.setAll({
      strokeWidth: 2,
    })

    // Chart title
    let title = chart.plotContainer.children.push(
      am5.Label.new(root_profit_stacked, {
        text: 'Accumulative Profit in $',
        fontSize: 20,
        fontWeight: '400',
        x: am5.p50,
        centerX: am5.p50,
      })
    )

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      let lastUSD = 0
      for (let i = ProfitComplete.stats_daily.length - 1; i >= 0; i--) {
        let dataentry = {
          date: new Date(ProfitComplete.stats_daily[i]._id).getTime(),
          profit: parseFloat(ProfitComplete.stats_daily[i].profit),
          profit_USD: parseFloat(ProfitComplete.stats_daily[i].profit_USD) + lastUSD,
          profit_percent: parseFloat(ProfitComplete.stats_daily[i].profit_percent),
          count: parseFloat(ProfitComplete.stats_daily[i].count),
          fee_calcUSD: parseFloat(ProfitComplete.stats_daily[i].fee_calcUSD),
        }
        lastUSD = lastUSD + parseFloat(ProfitComplete.stats_daily[i].profit_USD)
        data.push(dataentry)
      }
    }

    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root_profit_stacked.dispose()
    }
  }, [ProfitComplete])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_percent')) {
      return
    }

    let root_profit_percent = am5.Root.new('chart_profit_percent')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_percent.setThemes([am5themes_Animated.new(root_profit_percent)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_profit_percent.container.children.push(
      am5xy.XYChart.new(root_profit_percent, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root_profit_percent, {
        behavior: 'zoomX',
      })
    )
    // let cursor = chart.set("cursor", am5xy.XYCursor.new(root_profit_percent, {}));
    cursor.lineY.set('visible', false)

    let date = new Date()
    date.setHours(0, 0, 0, 0)
    let value = 100

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root_profit_percent, {
        // maxDeviation: 0.3,
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root_profit_percent, {}),
        tooltip: am5.Tooltip.new(root_profit_percent, {}),
      })
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root_profit_percent, {
        renderer: am5xy.AxisRendererY.new(root_profit_percent, {}),
        // tooltip: am5.Tooltip.new(root_stats_trades, {})
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root_profit_percent, {
        connect: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'profit_percent',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root_profit_percent, {
          labelText: '{valueY}',
        }),
      })
    )

    series.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    })

    series.strokes.template.setAll({
      strokeWidth: 2,
    })

    // Chart title
    let title = chart.plotContainer.children.push(
      am5.Label.new(root_profit_percent, {
        text: 'Profit in Percent',
        fontSize: 20,
        fontWeight: '400',
        x: am5.p50,
        centerX: am5.p50,
      })
    )

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      for (let i = 0; i < ProfitComplete.stats_daily.length; i++) {
        let dataentry = {
          date: new Date(ProfitComplete.stats_daily[i]._id).getTime(),
          profit: parseFloat(ProfitComplete.stats_daily[i].profit),
          profit_USD: parseFloat(ProfitComplete.stats_daily[i].profit_USD),
          profit_percent: parseFloat(ProfitComplete.stats_daily[i].profit_percent),
          count: parseFloat(ProfitComplete.stats_daily[i].count),
          fee_calcUSD: parseFloat(ProfitComplete.stats_daily[i].fee_calcUSD),
        }
        data.push(dataentry)
      }
    }

    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)

    return () => {
      root_profit_percent.dispose()
    }
  }, [ProfitComplete])

  const Precision = getPrecisionFromSymbol(botData ? botData.symbol : '')

  // const LogDiv = (
  //   var es = new EventSource('log/' + getParameterByName('channel'));
  //   es.addEventListener("message", function (event) {
  //   var div = document.createElement("div");
  //   div.appendChild(document.createTextNode(event.data));
  //   document.body.appendChild(div);
  // });
  // </script>
  // )

  // Function to count the grid lines:
  function countGridLines() {
    const MaxStepsCalculation = 10000
    let count = 0
    const LowerGrid = parseFloat(botData.diff.price_min)
    const UpperGrid = parseFloat(botData.diff.price_max)
    const GridStep = parseFloat(botData.diff.value)
    const GridStepType =
      botData.diff && botData.diff.diffbuy && botData.diff.diffbuy.type
        ? botData.diff.diffbuy.type
        : 'static'

    if (GridStepType === 'static') {
      count = Math.round((UpperGrid - LowerGrid) / GridStep)
      // Max: MaxStepsCalculation
      if (count > MaxStepsCalculation) {
        count = MaxStepsCalculation
      }
    } else if (GridStepType === 'percent') {
      // count = Math.round((UpperGrid - LowerGrid) / (LowerGrid * GridStep / 100))
      // Percent every step:
      let CurrentStep = LowerGrid
      while (CurrentStep < UpperGrid) {
        count++
        CurrentStep =
          parseFloat(CurrentStep) +
          (parseFloat(CurrentStep) * parseFloat(GridStep)) / 100
        if (count >= MaxStepsCalculation) {
          break
        }
      }
    }
    // Min: 0
    if (count < 0) {
      count = 0
    }
    return count
  }

  function getCurrentPrice() {
    if (botData && botData.store && botData.store.last) {
      return parseFloat(botData.store.last)
    } else if (botData && botData.lastfilledorderprice) {
      return parseFloat(botData.lastfilledorderprice)
    } else if (botData && botData.diff && botData.diff.price_min) {
      return parseFloat(botData.diff.price_min)
    }
    return 0.0
  }

  function minDiff() {
    const LowerGrid = parseFloat(botData.diff.price_min)
    const UpperGrid = parseFloat(botData.diff.price_max)
    const GridType = botData.type
    const GridStep = parseFloat(botData.diff.value)
    const GridStepType =
      botData.diff && botData.diff.diffbuy && botData.diff.diffbuy.type
        ? botData.diff.diffbuy.type
        : 'static'
    let minDiff = -1
    if (GridStepType === 'static') {
      minDiff = GridStep
    } else if (GridStepType === 'percent') {
      if (GridType === 'SpotInfinity') {
        // Get current price
        const CurrentPrice = parseFloat(getCurrentPrice())
        // Get Current amount_quote
        // const CurrentAmountQuote = parseFloat(botData.amount_quote)
        // Base amount
        // const BaseAmount = CurrentAmountQuote / CurrentPrice
        //
        const limits = getLimitsFromSymbol(botData.symbol)
        // console.log('limits', limits)

        let NewAmountQuote = 0
        // Amount min check
        if (limits.amount && limits.amount.min && limits.amount.min > 0) {
          NewAmountQuote =
            parseFloat(limits.amount.min) * parseFloat(CurrentPrice) +
            parseFloat(botData.amount_quote)
          minDiff =
            (100.0 / parseFloat(botData.amount_quote)) *
            parseFloat(NewAmountQuote) -
            100
        }

        //  market['limits']['cost']['min'] check
        if (limits.cost && limits.cost.min && limits.cost.min > 0) {
          const BaseOrderSize =
            parseFloat(limits.cost.min) / parseFloat(CurrentPrice)
          const CurrentBaseOrder =
            parseFloat(botData.amount_quote) / parseFloat(CurrentPrice) -
            parseFloat(BaseOrderSize)
          const NewPrice =
            parseFloat(botData.amount_quote) / parseFloat(CurrentBaseOrder)
          const CostMinDiff =
            (100.0 / parseFloat(CurrentPrice)) * parseFloat(NewPrice) - 100.0

          if (CostMinDiff > minDiff) {
            minDiff = CostMinDiff
          }
        }
      }
    }
    return minDiff
  }

  const onModalSubmitHandler = (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)

    const sendData = {
      type: data.add_funds_type,
      amount: data.add_funds_amount,
      price: data.add_funds_price,
      side: data.add_funds_side,
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/gridbot/${id}/add_funds`
      let Method = 'POST'
      const response = fetch(RequestURL, {
        method: Method,
        body: JSON.stringify(sendData),
        headers: {
          Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })

      // Get JSON Response
      response.then((res) => res.json()).then((json) => {
        if (json.status === 'ok') {
          setShowModalAddFunds(false)
          setformSubmitMsg(json)
        } else {
          setformSubmitMsg(json)
        }
      })
    } catch (err) {
      console.log(err)
    }

  }

  return (
    botData && (
      <div>
        {showModalAddFunds ? (
          <Modal
            dismissFn={() => setShowModalAddFunds(false)}
            // submitFn={submitModalAddFunds}
            showFooter={true}
            header="Add funds"
            formSubmit="addFundsSubmit"
            // showLoader={showModalLoader}
          >
            <form id="addFundsSubmit" onSubmit={onModalSubmitHandler}>
              <Fieldset legend="Add Funds" columns={1} dismiss={false}>
                <InputField
                  type="hidden"
                  name="id"
                  value={botData ? botData._id : 'add'}
                />
                <Select
                  name="add_funds_type"
                  label="Grid Type"
                  value={addFunds.type}
                  options={[
                    {name: 'Manual', value: 'manual'},
                  ]}
                  tooltip="There are different types:<br>1. Manual: You can add funds manually without a buy action on the exchange."
                  onChange={function (evt) {
                    const options = evt.target.options
                    const index = options.selectedIndex
                    setAddFunds((prevState) => ({
                      ...prevState,
                      type: options[index].value,
                    }))
                  }}
                />
                <InputField
                  type="text"
                  name="add_funds_amount"
                  label="Amount"
                  value={addFunds.amount}
                  pattern="numeric"
                  precision={Precision.base}
                  uom={getSymbolBase(botData.symbol)}
                  onChange={function (evt) {
                    setAddFunds(function (prevState) {
                      return {
                        ...prevState,
                        amount: evt.target.value,
                      }
                    })
                  }}
                />
                <InputField
                  type="text"
                  name="add_funds_price"
                  label="Price"
                  value={addFunds.price}
                  pattern="numeric"
                  precision={Precision.quote}
                  uom={getSymbolQuote(botData.symbol)}
                  onChange={function (evt) {
                    setAddFunds(function (prevState) {
                      return {
                        ...prevState,
                        price: evt.target.value,
                      }
                    })
                  }}
                />
                <Select
                  name="add_funds_side"
                  label="Side"
                  value={addFunds.side}
                  options={[
                    {name: 'Buy', value: 'buy'},
                    {name: 'Sell', value: 'sell'},
                  ]}
                  tooltip="Buy order or sell order"
                  onChange={function (evt) {
                    const options = evt.target.options
                    const index = options.selectedIndex
                    setAddFunds((prevState) => ({
                      ...prevState,
                      side: options[index].value,
                    }))
                  }}
                />
              </Fieldset>
            </form>
          </Modal>
        ) : null}
        <form
          style={{padding: '0 1.6rem', margin: '0 auto'}}
          onSubmit={onSubmitForm}
        >
          <Fieldset
            legend="TradingView"
            columns={1}
            dismiss={true}
            collapsed={true}
            height={800}
          >
            <div className="col-9 pl-0 graph">
              <div id="tv_chart_container" className="TVChartContainer" style={{height: '800px'}}/>
            </div>
          </Fieldset>
          <Fieldset
            legend="Assistent"
            columns={1}
            dismiss={true}
            collapsed={true}
          >
            <div>Bot ID: {botData._id}</div>
            <div>Orders on exchange: {botData.lastsub_id}</div>
            <div>
              Last filled order side:{' '}
              {botData.lastfilledorderside ? botData.lastfilledorderside : '-'}
            </div>
            <div>
              Last filled order price:{' '}
              {parseFloat(
                botData.lastfilledorderprice
                  ? botData.lastfilledorderprice
                  : '-'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              Exchange:{' '}
              {getExchangeFromAccount(accountData, botData.account_id)}
            </div>
            <div>Grid count: {countGridLines()}</div>
            <div>
              Min diff:{' '}
              {minDiff() && minDiff() >= 0 ? minDiff().toFixed(2) + '%' : '-'}
            </div>
          </Fieldset>
          <Fieldset legend="Stats" columns={1} dismiss={true} collapsed={true}>
            <div>
              Grid Profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit
                  ? ProfitComplete.profit
                  : '0'
              ).toFixed(Precision.quote)}{' '}
              {getSymbolQuote(botData.symbol)}
            </div>
            <div>
              Grid Profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profitUSD
                  ? ProfitComplete.profitUSD
                  : '0'
              ).toFixed(8)}{' '}
              USD
            </div>
            <div>
              Grid Profit Total:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent
                  ? ProfitComplete.profit_percent
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit day avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day
                  ? ProfitComplete.profit_percent_day
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 1 day avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_1
                  ? ProfitComplete.profit_percent_day_1
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 7 days avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_7
                  ? ProfitComplete.profit_percent_day_7
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 14 days avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_14
                  ? ProfitComplete.profit_percent_day_14
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Grid Profit last 30 days avg:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.profit_percent_day_30
                  ? ProfitComplete.profit_percent_day_30
                  : '0'
              ).toFixed(2)}{' '}
              %
            </div>
            <div>
              Trade count:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.tradecount
                  ? ProfitComplete.tradecount
                  : '0'
              ).toFixed(0)}
            </div>
            <div>
              amountlockedbase:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.amountlockedbase
                  ? ProfitComplete.amountlockedbase
                  : '0'
              ).toFixed(Precision.base)}
            </div>
            <div>
              amountlockedquote:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.amountlockedquote
                  ? ProfitComplete.amountlockedquote
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              PNL:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.pnl ? ProfitComplete.pnl : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              current_price_bid:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.current_price_bid
                  ? ProfitComplete.current_price_bid
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              current_days:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.current_days
                  ? ProfitComplete.current_days
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_total:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_total
                  ? ProfitComplete.gridlines_count_total
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_sell_max:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_sell_max
                  ? ProfitComplete.gridlines_count_sell_max
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_sell:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_sell
                  ? ProfitComplete.gridlines_count_sell
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_count_buy:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_count_buy
                  ? ProfitComplete.gridlines_count_buy
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              gridlines_amount_quote_reserved:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_amount_quote_reserved
                  ? ProfitComplete.gridlines_amount_quote_reserved
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              gridlines_amount_quote_total:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.gridlines_amount_quote_total
                  ? ProfitComplete.gridlines_amount_quote_total
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              gridlines_amount_quote_total_overall:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.gridlines_amount_quote_total_overall
                  ? ProfitComplete.gridlines_amount_quote_total_overall
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_lockedquote:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_lockedquote
                  ? ProfitComplete.unrealized_lockedquote
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profit
                  ? ProfitComplete.unrealized_profit
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_profitUSD:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profitUSD
                  ? ProfitComplete.unrealized_profitUSD
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              unrealized_profit_percent:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profit_percent
                  ? ProfitComplete.unrealized_profit_percent
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              unrealized_profit_percent_day:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.unrealized_profit_percent_day
                  ? ProfitComplete.unrealized_profit_percent_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              unrealized_profit_percent_overall:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.unrealized_profit_percent_overall
                  ? ProfitComplete.unrealized_profit_percent_overall
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              unrealized_profit_percent_overall_day:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.unrealized_profit_percent_overall_day
                  ? ProfitComplete.unrealized_profit_percent_overall_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit
                  ? ProfitComplete.total_profit
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              total_profitUSD:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profitUSD
                  ? ProfitComplete.total_profitUSD
                  : '0'
              ).toFixed(Precision.quote)}
            </div>
            <div>
              total_profit_percent_locked:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent_locked
                  ? ProfitComplete.total_profit_percent_locked
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent
                  ? ProfitComplete.total_profit_percent
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent_day:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent_day
                  ? ProfitComplete.total_profit_percent_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent_overall:{' '}
              {parseFloat(
                ProfitComplete && ProfitComplete.total_profit_percent_overall
                  ? ProfitComplete.total_profit_percent_overall
                  : '0'
              ).toFixed(2)}
            </div>
            <div>
              total_profit_percent_overall_day:{' '}
              {parseFloat(
                ProfitComplete &&
                ProfitComplete.total_profit_percent_overall_day
                  ? ProfitComplete.total_profit_percent_overall_day
                  : '0'
              ).toFixed(2)}
            </div>
            <div
              id="chart_profit_USD"
              style={{width: '100%', height: '500px'}}
            ></div>
            <div
              id="chart_profit_stacked"
              style={{width: '100%', height: '500px'}}
            ></div>
            <div
              id="chart_profit_percent"
              style={{width: '100%', height: '500px'}}
            ></div>
          </Fieldset>
          {/*<Fieldset*/}
          {/*  legend="TradingView"*/}
          {/*  columns={1}*/}
          {/*  dismiss={true}*/}
          {/*  collapsed={true}*/}
          {/*>*/}
          {/*  <AdvancedChart*/}
          {/*    widgetProps={{*/}
          {/*      theme: 'dark',*/}
          {/*      interval: '1m',*/}
          {/*      // range: '60',*/}
          {/*      style: "1",*/}
          {/*      symbol:*/}
          {/*        getExchangeFromAccount(accountData, botData.account_id) +*/}
          {/*        ':' +*/}
          {/*        (botData.symbol*/}
          {/*          ? botData.symbol.replace('/', '').replace('-', '')*/}
          {/*          : ''),*/}
          {/*      studies: [*/}
          {/*        // "MACD@tv-basicstudies",*/}
          {/*        // "StochasticRSI@tv-basicstudies",*/}
          {/*        "RSI@tv-basicstudies",*/}
          {/*        // "TripleEMA@tv-basicstudies"*/}
          {/*      ],*/}

          {/*    }}*/}
          {/*  />*/}
          {/*</Fieldset>*/}
          <Fieldset legend="Account Settings" columns={2} dismiss={true}>
            {formSubmitMsg ? <div>{JSON.stringify(formSubmitMsg)}</div> : null}
            <InputField
              type="hidden"
              name="id"
              value={botData ? botData._id : 'add'}
            />
            <Select
              name="account_id"
              label="Account"
              options={buildAccountOptions(
                accountData,
                ['BinanceSpot', 'BybitSpot'],
                '',
                true
              )}
              value={botData.account_id}
              tooltip="Select the account to use for this bot"
              // disabled={parseFloat(ProfitComplete && ProfitComplete.tradecount ? ProfitComplete.tradecount : '0') > 0}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  account_id: options[index].value,
                }))
                // reload Symbols
                if (botData && botData.account_id) {
                  getSymbols(botData.account_id).then((data) =>
                    setSymbols(data)
                  )
                }
              }}
            />
            <Select
              name="type"
              label="Grid Type"
              value={botData.type}
              options={[
                {name: 'Spot Classic', value: 'SpotClassic'},
                {name: 'Spot Infinity', value: 'SpotInfinity'},
                {name: 'Spot Infinity V2', value: 'SpotInfinityV2'},
              ]}
              tooltip="Select the type of grid to use:<br>Spot Classic: Spot grid with min and max price range<br>Spot Infinity: Inital buy quantity per grid (amount_quote) and try to hold the quote amount"
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  type: options[index].value,
                }))
              }}
            />
            <Select
              name="symbol"
              label="Symbol"
              value={botData.symbol}
              options={mapSymbols(symbols)}
              tooltip="Select the symbol to trade<br>ATTENTION: if the list remains empty, change the account to Dummy, then back to the desired name"
              // disabled={parseFloat(ProfitComplete && ProfitComplete.tradecount ? ProfitComplete.tradecount : "0') > 0}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  symbol: options[index].value,
                }))
              }}
            />
            <InputField
              type="text"
              name="name"
              label="Name"
              value={botData.name ? botData.name : 'Bot'}
              pattern={'alphanumericwithspace'}
              tooltip="Name of the Bot (alphanumeric with space). The name is used to identify the bot in the list."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    name: evt.target.value,
                  }
                })
              }}
            />
          </Fieldset>

          <Fieldset legend="Main Settings" columns={2} dismiss={true}>
            <InputField
              type="text"
              name="diff_price_min"
              label="Price Min"
              value={botData.diff.price_min}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              // tooltip="<h2>Hallo</h2>Lorem ipsum dolor sit amet,<br> consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
              tooltip="Defines the lower limit up to which the bot remains active. If it falls below, no further buy/sell orders will be placed."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    diff: {
                      ...prevState.diff,
                      price_min: evt.target.value,
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="diff_price_max"
              label="Price Max"
              value={botData.diff.price_max}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              tooltip="Defines the upper limit up to which the bot stays active. If it is exceeded, no further buy/sell orders will be placed."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    diff: {
                      ...prevState.diff,
                      price_max: evt.target.value,
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="diff_value"
              label="Diff"
              value={botData.diff.value}
              pattern="numeric"
              precision={botData.diff.uom === '%' ? 2 : Precision.quote}
              uom={botData.diff.uom}
              tooltip="Defines the spacing of the grid lines. Optimum values are between 0.23-0.35. The lower the value, the more capital-intensive the bot becomes. The value must be set so that it is suitable for the existing capital and minimum order quantity of the respective exchange."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    diff: {
                      ...prevState.diff,
                      value: evt.target.value,
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="count"
              label="Active Grid Orders"
              value={parseInt(botData.count)}
              pattern="integer"
              tooltip="Defines the number of orders on the CEX, proven default for infinity grids: 6"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  count: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="amount"
              label="Quantity per grid (amount)"
              value={botData.amount}
              pattern="numeric"
              precision={Precision.base}
              uom={getSymbolBase(botData.symbol)}
              tooltip="Set it to -1 (this value is irrelevant for the Infinity bot)"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  amount: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="amount_quote"
              label="Quantity per grid (amount_quote)"
              value={botData.amount_quote}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              tooltip="Defines the invested capital for the initial buy or the total volume in quote that the bot tries to hold during the entire runtime."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  amount_quote: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="amount_max"
              label="Quantity max allocation (amount_max)"
              value={botData.amount_max}
              pattern="numeric"
              precision={Precision.base}
              uom={getSymbolBase(botData.symbol)}
              tooltip="Set it to -1. A small emergency brake - the bot is not allowed to buy more than what is stated here. At -1 this function is disabled."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  amount_max: evt.target.value,
                }))
              }}
            />
          </Fieldset>
          <Fieldset
            legend="Advanced Settings Infinity"
            columns={2}
            dismiss={true}
            collapsed={true}
            disabled={botData.type !== 'SpotInfinity'}
          >
            <InputField
              type="switch"
              name="bo.emacross.enabled"
              label="EMA Cross Enabled"
              checked={botData.bo.emacross.enabled}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  bo: {
                    ...prevState.bo,
                    emacross: {
                      ...prevState.bo.emacross,
                      enabled: evt.target.checked,
                    },
                  },
                }))
              }}
            />
            <Select
              name="bo.emacross.timeframe"
              label="Timeframe"
              gridcolumns="2"
              value={botData.bo.emacross.timeframe}
              options={[
                {name: '15s', value: '15s'},
                {name: '1m', value: '1m'},
                {name: '5m', value: '5m'},
                {name: '15m', value: '15m'},
                {name: '1h', value: '1h'},
                {name: '4h', value: '4h'},
                {name: '1d', value: '1d'},
                {name: '3d', value: '3d'},
                {name: '1w', value: '1w'},
                {name: '2w', value: '2w'},
                {name: '1M', value: '1M'},
              ]}
            />
            <InputField
              type="text"
              name="bo.emacross.length1"
              label="EMA Cross Length 1"
              value={parseInt(botData.bo.emacross.length1)}
              pattern="integer"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  bo: {
                    ...prevState.bo,
                    emacross: {
                      ...prevState.bo.emacross,
                      length1: evt.target.value,
                    },
                  },
                }))
              }}
            />
            <InputField
              type="text"
              name="bo.emacross.length2"
              label="EMA Cross Length 2"
              value={parseInt(botData.bo.emacross.length2)}
              pattern="integer"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  bo: {
                    ...prevState.bo,
                    emacross: {
                      ...prevState.bo.emacross,
                      length2: evt.target.value,
                    },
                  },
                }))
              }}
            />
            <InputField
              type="text"
              name="bo.emacross.amount_quote_low"
              label="EMA Cross Amount Quote Low"
              value={parseInt(botData.bo.emacross.amount_quote_low)}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  bo: {
                    ...prevState.bo,
                    emacross: {
                      ...prevState.bo.emacross,
                      amount_quote_low: evt.target.value,
                    },
                  },
                }))
              }}
            />
            <InputField
              type="text"
              name="bo.emacross.amount_quote_high"
              label="EMA Cross Amount Quote High"
              value={parseInt(botData.bo.emacross.amount_quote_high)}
              pattern="numeric"
              precision={Precision.quote}
              uom={getSymbolQuote(botData.symbol)}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  bo: {
                    ...prevState.bo,
                    emacross: {
                      ...prevState.bo.emacross,
                      amount_quote_high: evt.target.value,
                    },
                  },
                }))
              }}
            />
          </Fieldset>
          <Fieldset legend="Advanced Settings" columns={2} dismiss={true}>
            <InputField
              type="text"
              name="countsell"
              label="Grid count (only sell)"
              value={botData.countsell}
              pattern="integer"
              tooltip="Defines the number of grids that are used for selling. If set to 0, the bot will not sell.<br>For Infinity Bots set it to 2. This value defines the active sell orders on the exchange."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countsell: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="countselloptional"
              label="Grid count optional (only sell)"
              value={botData.countselloptional}
              pattern="integer"
              tooltip="For Infinity Bots set it to 1. Additional active sell order lines that are not deleted by the bot if there was an active order at one time. This increases the speed of order placement."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countselloptional: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="countbuy"
              label="Grid count (only buy)"
              value={botData.countbuy}
              pattern="integer"
              tooltip="Defines the number of grids that are used for buying. If set to 0, the bot will not buy.<br>For Infinity Bots set it to 2. This value defines the active buy orders on the exchange."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countbuy: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="countbuyoptional"
              label="Grid count optional (only buy)"
              value={botData.countbuyoptional}
              pattern="integer"
              tooltip="For Infinity Bots set to 1. Additional active buy order lines that are not deleted by the bot if there was an active order at one time. This increases the speed of order placement"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  countbuyoptional: evt.target.value,
                }))
              }}
            />
            <Select
              name="diff_buy_type"
              label="Diff type (percent or absolute)"
              value={
                botData.diff &&
                botData.diff.diffbuy &&
                botData.diff.diffbuy.type
                  ? botData.diff.diffbuy.type
                  : 'static'
              }
              options={[
                {name: 'Static', value: 'static'},
                {name: 'Percent', value: 'percent'},
              ]}
              tooltip="Defines the type of the diff value. If set to static, the diff value is used as is. If set to percent, the diff value is used as a percentage of the current price."
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  diff: {
                    ...prevState.diff,
                    diffbuy: {
                      ...prevState.diff.diffbuy,
                      type: options[index].value,
                    },
                    diffsell: {
                      ...prevState.diff.diffsell,
                      type: options[index].value,
                    },
                  },
                }))
                botData.diff.uom =
                  options[index].value === 'static'
                    ? getSymbolQuote(botData.symbol)
                    : '%'
              }}
            />
            <InputField
              type="switch"
              name="rebuymissingbuyorders"
              label="Rebuy grid lines, if big drop"
              checked={botData.rebuymissingbuyorders}
              tooltip="If set to true, the bot will rebuy missing grid lines if the price drops significantly. This is useful if the bot is not running for a longer time and the price drops significantly.<br>Keep this disabled for Infinity Grids"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  rebuymissingbuyorders: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="cycleresetafterordercomplete"
              label="React after a buy or sell is done"
              checked={botData.cycleresetafterordercomplete}
              tooltip="If set to true, the bot will reset the cycle after a buy or sell is done. Activate this setting for best performance"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  cycleresetafterordercomplete: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="type_sellorderbook"
              label="Second mode: sell on buy orderbook only"
              checked={botData.type_sellorderbook}
              tooltip="sell on buy orderbook only. Keep this disabled for Infinity Grids"
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  type_sellorderbook: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="ignore_spread"
              label="Ignore spread"
              checked={botData.ignore_spread}
              tooltip="If set to true, the bot will ignore the spread and place orders at the current price. This is useful if the spread is too high and the bot is not able to place orders."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  ignore_spread: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="postonly"
              label="Send only postonly orders to the exchange"
              checked={botData.postonly ? botData.postonly : true}
              tooltip="If set to true, the bot will only send postonly orders to the exchange. This is useful if you want to avoid market orders (sometimes less fees)."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  postonly: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="dryrun"
              label="Dry run... no orders are sent"
              checked={botData.dryrun}
              tooltip="If set to true, the bot will not send any orders to the exchange. This is useful if you want to test the bot without sending orders."
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  dryrun: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="cycledelay"
              label="Cycle Delay"
              value={botData.cycledelay}
              min={10}
              max={600}
              step={1}
              pattern="integer"
              tooltip="Defines the delay between two cycles in seconds. The bot will check the current price and place orders if necessary. The default value is 60 seconds."
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    cycledelay: evt.target.value,
                  }
                })
              }}
            />
          </Fieldset>

          {/*<Fieldset legend="Advanced Settings" columns={2} dismiss={true}>*/}
          {/*</Fieldset>*/}

          {/*<Fieldset legend="TEST Dismissable Fieldset" columns={2} dismiss={true}>*/}
          {/*  <InputField*/}
          {/*    type="text"*/}
          {/*    name="bot-name"*/}
          {/*    label="Bot Name"*/}
          {/*    value={botData.name}*/}
          {/*    uom={botData.symbol}*/}
          {/*    onChange={onChangeFirstInput}*/}
          {/*  />*/}
          {/*  <InputField*/}
          {/*    type="text"*/}
          {/*    name="bot-name"*/}
          {/*    label="Bot Symbol disabled"*/}
          {/*    value={botData.symbol}*/}
          {/*    uom={botData.symbol}*/}
          {/*    disabled={true}*/}
          {/*  />*/}
          {/*  <InputField*/}
          {/*    type="switch"*/}
          {/*    name="bot-switch"*/}
          {/*    label="Bot Switch checked and disabled"*/}
          {/*    checked={true}*/}
          {/*    disabled={false}*/}
          {/*  />*/}
          {/*  <InputField type="switch" name="bot-switch" label="Bot Switch"/>*/}
          {/*  <InputField*/}
          {/*    type="hidden"*/}
          {/*    name="hidden-field"*/}
          {/*    value={botData.name}*/}
          {/*  />*/}
          {/*</Fieldset>*/}

          {/*<Fieldset*/}
          {/*  legend="TEST Switchable Fieldset"*/}
          {/*  columns={2}*/}
          {/*  dismiss={true}*/}
          {/*  switchable={true}*/}
          {/*>*/}
          {/*  <InputField*/}
          {/*    type="text"*/}
          {/*    name="bot-name"*/}
          {/*    label="Bot Name"*/}
          {/*    value={botData.name}*/}
          {/*    uom={botData.symbol}*/}
          {/*  />*/}
          {/*  <Select*/}
          {/*    name="exchange-accounts"*/}
          {/*    label="Exchange Accounts"*/}
          {/*    options={buildAccountOptions(accountData)}*/}
          {/*    onChange={function (evt) {*/}
          {/*      const options = evt.target.options*/}
          {/*      const index = options.selectedIndex*/}
          {/*      getSymbols(options[index].value).then((data) =>*/}
          {/*        setSymbols(data)*/}
          {/*      )*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <Slider*/}
          {/*    name="slider-example"*/}
          {/*    label="Slider Example"*/}
          {/*    value={1}*/}
          {/*    min={0}*/}
          {/*    max={2}*/}
          {/*    step={0.1}*/}
          {/*    uom={botData.symbol}*/}
          {/*    onChange={(evt) => console.log(evt)}*/}
          {/*  />*/}
          {/*  <Select*/}
          {/*    name="symbols"*/}
          {/*    label="Symbols"*/}
          {/*    value={botData.symbol}*/}
          {/*    options={mapSymbols(symbols)}*/}
          {/*    onChange={function (evt) {*/}
          {/*      const options = evt.target.options*/}
          {/*      const index = options.selectedIndex*/}
          {/*      setBotData((prevState) => ({*/}
          {/*        ...prevState,*/}
          {/*        symbol: options[index].value,*/}
          {/*      }))*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Fieldset>*/}
          <button type="submit">Submit</button>
        </form>
        {/* External Link for the api page to open the log */}
        <button
          onClick={() =>
            window.open(
              'https://api.crazybot.io/v1/ws/log/console/gridbot-' + botData._id
            )
          }
        >
          Open Log
        </button>
        <button
          onClick={function (evt) {
            setShowModalAddFunds(true)
          }}
        >
          Add Funds
        </button>
      </div>
    )
  )
}

export default GridbotDetailPage
