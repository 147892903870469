function getSymbolBase(symbol) {
  if (symbol) {
    const symbolArr = symbol.split('/')
    return symbolArr[0]
  }
  return ''
}

function getSymbolQuote(symbol) {
  if (symbol) {
    const symbolArr = symbol.split('/')
    return (symbolArr[1] ? symbolArr[1] : symbol)
  }
  return ''
}

function getPrecisionFromSymbol(aSymbol) {
  let ReturnSymbol = {
    base: 8,
    quote: 8,
    amount: 0,
    price: 0,
  }
  // if (
  //   symbols &&
  //   symbols.length &&
  //   symbols.length > 0 &&
  //   aSymbol &&
  //   aSymbol !== ''
  // ) {
  //   symbols.forEach((item) => {
  //     if (item.symbol === aSymbol && item.precision) {
  //       ReturnSymbol = item.precision
  //     }
  //   })
  // }
  return ReturnSymbol
}

export { getSymbolBase, getSymbolQuote, getPrecisionFromSymbol }
