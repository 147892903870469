import React from 'react'
import useGetRights from '../../hooks/useGetRights'
import useGetAPIKey from '../../hooks/useGetAPIKey'
import Fieldset from '../EditFields/Fieldset/Fieldset'
import CBElements from "../Containers/CBElements";

const UserPage = () => {
  // const [userData] = useFetchUser()
  const rights = useGetRights()()
  const apiKey = useGetAPIKey()()

  const renderTelegramButton = (rights) => {
    // console.log(apiKey)
    if ('DEMO' in rights) {
      return (
        <p>
          Connect to the Telegram bot:{' '}
          <a disabled className="as-button" href="#">
            @BotManagerTC_bot (DISABLED in DEMO)
          </a>
        </p>
      )
    } else {
      if (process.env.NODE_ENV === 'production') {
        return (
          <p>
            Connect to the Telegram bot:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="as-button"
              href={`https://t.me/BotManagerTC_bot?start=${apiKey}`}
            >
              @BotManagerTC_bot
            </a>
          </p>
        )
      } else {
        return (
          <p>
            Connect to the Telegram bot:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="as-button"
              href={`https://t.me/BotManagerDEV_bot?start=${apiKey}`}
            >
              @BotManagerDEV_bot
            </a>
          </p>
        )
      }
    }
  }

  const Defaultlayout = {
    lg: [{"w":4,"h":1,"x":20,"y":0,"i":"user-fee","minW":2,"minH":1,"moved":false,"static":false},{"w":20,"h":3,"x":0,"y":0,"i":"user-payment","minW":4,"minH":1,"moved":false,"static":false},{"w":4,"h":2,"x":20,"y":1,"i":"user-voucher","minW":2,"minH":2,"moved":false,"static":false}],
    md: [
      {"w":4,"h":1,"x":16,"y":0,"i":"user-fee","minW":2,"minH":1,"moved":false,"static":false},
      {"w":16,"h":3,"x":0,"y":0,"i":"user-payment","minW":4,"minH":1,"moved":false,"static":false},
      {"w":4,"h":2,"x":16,"y":1,"i":"user-voucher","minW":2,"minH":2,"moved":false,"static":false}
    ],
    sm: [{"w":3,"h":1,"x":9,"y":0,"i":"user-fee","minW":2,"minH":1,"moved":false,"static":false},{"w":9,"h":2,"x":0,"y":0,"i":"user-payment","minW":4,"minH":1,"moved":false,"static":false},{"w":3,"h":1,"x":9,"y":1,"i":"user-voucher","minW":2,"minH":2,"moved":false,"static":false}],
    xs: [{"w":8,"h":1,"x":0,"y":0,"i":"user-fee","minW":2,"minH":1,"moved":false,"static":false},{"w":8,"h":2,"x":0,"y":1,"i":"user-payment","minW":4,"minH":1,"moved":false,"static":false},{"w":8,"h":2,"x":0,"y":3,"i":"user-voucher","minW":2,"minH":2,"moved":false,"static":false}],
    xxs: [{"w":8,"h":1,"x":0,"y":0,"i":"user-fee","minW":2,"minH":1,"moved":false,"static":false},{"w":8,"h":2,"x":0,"y":1,"i":"user-payment","minW":4,"minH":1,"moved":false,"static":false},{"w":8,"h":1,"x":0,"y":3,"i":"user-voucher","minW":2,"minH":2,"moved":false,"static":false}],
  }

  const layoutParameters = {
    // cols: 12,
    // rowHeight: 30,
    // width: 100%:
    // width:
  }

  return (
    <React.Fragment>
    {/*<form style={{ padding: '0 0rem', margin: '0 auto' }}>*/}
        {/*<Fieldset legend="Change Password" columns={1} dismiss={true}>*/}
        {/*  <InputField*/}
        {/*    type="password"*/}
        {/*    name="OldPassword"*/}
        {/*    label="Current password"*/}
        {/*    value=""*/}
        {/*    required*/}
        {/*  />*/}
        {/*  <InputField*/}
        {/*    type="password"*/}
        {/*    name="NewPassword"*/}
        {/*    label="New password"*/}
        {/*    value=""*/}
        {/*    required*/}
        {/*  />*/}
        {/*  <InputField*/}
        {/*    type="password"*/}
        {/*    name="NewPassword2"*/}
        {/*    label="Password confirmation"*/}
        {/*    value=""*/}
        {/*    required*/}
        {/*  />*/}
        {/*  <button type="submit">Change Password</button> // TODO: Implement*/}
        {/*  change password*/}
        {/*</Fieldset>*/}
        <Fieldset legend="Payment" columns={1} dismiss={true}>
          <CBElements GridLayoutParameter={layoutParameters} ElementNames={[
            'user-fee',
            'user-payment',
            'user-voucher',
          ]} Prefix="user" DefaultGridLayout={Defaultlayout} />
        </Fieldset>
        <Fieldset legend="Notification" columns={2} dismiss={true}>
          {renderTelegramButton(rights)}
        </Fieldset>

      {/*</form>*/}
    </React.Fragment>
  )
}

// options={{ height: mainContainerHeight }}
// {sanitizeData(data, isSuccess)}

export default UserPage
