import React, { useState } from 'react'
import calcTrackPos from '../../../utils/calcTrackPos'
import PropTypes from 'prop-types'

const Slider = ({
  name,
  label,
  value,
  min,
  max,
  step,
  uom,
  disabled = false,
  onChange = () => {},
}) => {
  const [sliderValue, setSliderValue] = useState(value)
  const [trackPos, setTrackPos] = useState(calcTrackPos(value, min, max))

  const localOnChange = (parentOnChange) => (evt) => {
    const { target } = evt
    const { value } = target
    setTrackPos(calcTrackPos(value, min, max))
    setSliderValue(value)
    parentOnChange.call(evt.target, evt)
  }

  return (
    <div className="slider-control">
      <div>
        <label htmlFor={name}>{label}</label>
      </div>
      <output htmlFor={name} value={sliderValue}>
        {sliderValue}
      </output>
      <input
        type="range"
        name={name}
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        className="slider"
        disabled={disabled}
        onChange={localOnChange(onChange)}
        style={{
          background: `linear-gradient(to right, #82cfd0 0%, #82cfd0 ${trackPos}%, #475260 ${trackPos}%, #475260 100%)`,
        }}
      />
      {uom ? <div className="unit-of-measure">{uom}</div> : null}
    </div>
  )
}

Slider.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  uom: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Slider
