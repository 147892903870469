import React, {useContext, useState} from 'react'
import {GlobalContext} from '../../../state'
import {useFetchAccount} from '../../../hooks/useFetchAccount'
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import InputField from '../../EditFields/Input/InputField'
import Select from '../../EditFields/Select/Select'
import {
  buildAccountOptions,
} from '../../../hooks/useFetchAccount'
import tokenfromLS from "../../../utils/tokenFromLS";
import {useNavigate, useParams} from "react-router-dom";
import {
  sanitizeSignalProcessorData,
} from '../../../utils/sanatizer'
import useFetcher from "../../../hooks/useFetcher";
// import {JsonEditor as Editor} from 'jsoneditor-react'


const SignalProcessorDetailPage = () => {
  const globalState = useContext(GlobalContext)
  let params = useParams();
  let id = params.id
  // const [botData, setBotData] = useFetchSmartbot(id)
  const [botData, setBotData] = useFetcher(
    `signalprocessor/${id}`,
    'GET',
    sanitizeSignalProcessorData
  )

  const [formSubmitMsg, setformSubmitMsg] = useState(null)

  const navigate = useNavigate();

  const [accountData] = useFetchAccount()

  const onSubmitForm = async (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)

    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)

    data = {
      ...data,
    }
    // console.log(data)
    // console.log(botData)

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/signalprocessor/${id}`
      let Method = 'PUT'
      if (id === 'add') {
        RequestURL = `${process.env.REACT_APP_API_URI}/signalprocessor`
        Method = 'ADD'
        delete data._id
      }
      const response = await fetch(
        RequestURL,
        {
          method: Method,
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )

      const json = await response.json()
      if (json.hasOwnProperty('ok')) {
        navigate('/signalprocessor');
      } else {
        setformSubmitMsg(json)
      }

    } catch (err) {
      console.log(err)
    }
  }

  return (
    botData && (
      <div>
        <form style={{padding: '0 1.6rem', margin: '0 auto'}} onSubmit={onSubmitForm}>
          <Fieldset legend="Account Settings" columns={2} dismiss={true}>
            {formSubmitMsg ? <div>{JSON.stringify(formSubmitMsg)}</div> : null}
            <InputField type="hidden" name="id" value={botData ? botData._id : 'add'}/>
            <Select
              name="account_id"
              label="Account"
              options={buildAccountOptions(accountData)}
              value={botData.account_id}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  account_id: options[index].value,
                }))
              }}
            />
            <InputField
              type="text"
              name="name"
              label="Name"
              pattern="alphanumericwithspace"
              value={botData.name ? botData.name : ''}
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    name: evt.target.value,
                  }
                })
              }}
            />
          </Fieldset>
          <Fieldset legend="Config" columns={1} dismiss={true}>
            <InputField
              type="textarea"
              name="process"
              label="Process"
              height="300px"
              // Object to String
              value={JSON.stringify(botData.process, null, 2)}
              // value={JSON.stringify(botData.process, null, 2)}
              onChange={function (evt) {
                setBotData(function (prevState) {
                  // is it really json?
                  try {
                    return {
                      ...prevState,
                      process: JSON.parse(evt.target.value),
                    }
                  } catch (e) {
                    return {
                      ...prevState,
                      process: prevState.process,
                    }
                  }
                })
              }}
            />
            {/*<div className="App">*/}
            {/*  <Editor*/}
            {/*    mode="tree"*/}
            {/*    history*/}
            {/*    value={botData.process}*/}
            {/*    onChange={function (evt) {*/}
            {/*      setBotData(function (prevState) {*/}
            {/*        return {*/}
            {/*          ...prevState,*/}
            {/*          process: evt,*/}
            {/*        }*/}
            {/*      })*/}
            {/*    }}*/}
            {/*    // ace={ace}*/}
            {/*    // theme="ace/theme/github"*/}
            {/*  />*/}
            {/*</div>*/}
          </Fieldset>
          {/*<Fieldset legend="Advanced" columns={2} dismiss={true} collapsed={false}>*/}
          {/*  <InputField*/}
          {/*    type="switch"*/}
          {/*    name="dryrun"*/}
          {/*    label="Dry Run"*/}
          {/*    checked={botData.dryrun}*/}
          {/*    onChange={function (evt) {*/}
          {/*      setBotData((prevState) => ({*/}
          {/*        ...prevState,*/}
          {/*        dryrun: evt.target.checked,*/}
          {/*      }))*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Fieldset>*/}

          <InputField type="hidden" name="id" value={id}/>
          <button type="submit">Submit</button>
        </form>
        {/* External Link for the api page to open the log */}
        {/*<button onClick={() => window.open('https://api.crazybot.io/v1/ws/log/console/signalprocessor-' + botData._id)}>Open Log</button>*/}
      </div>
    )
  )
}

export default SignalProcessorDetailPage
