import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const SwitchCheckbox = ({
  name,
  label,
  disabled = false,
  checked = false,
  onChange = () => {},
  isFieldsetSwitch = false,
}) => {
  const [localChecked, setLocalChecked] = useState(checked)
  const [localDisabled, setLocalDisabled] = useState(disabled)

  const localChange = (evt) => {
    setLocalChecked((prevChecked) => !prevChecked)
    onChange.call(null, evt)
  }

  useEffect(() => {
    setLocalChecked(checked)
  }, [checked])

  useEffect(() => {
    setLocalDisabled(disabled)
  }, [disabled])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLocalDisabled(true)
  //   }, 5000)
  // }, [])

  if (isFieldsetSwitch) {
    return (
      <div>
        <label className={localDisabled ? 'switch disabled' : 'switch'}>
          <input
            type="checkbox"
            onChange={localChange}
            checked={localChecked}
          />
          <span className="switch-slider"></span>
        </label>
      </div>
    )
  }

  return (
    <div className="input-control switch-control">
      <div>
        <label htmlFor={name}>{label}</label>
      </div>
      <div>
        <label className={localDisabled ? 'switch disabled' : 'switch'}>
          <input
            name={name}
            type="checkbox"
            onChange={localChange}
            checked={localChecked}
          />
          <span className="switch-slider"></span>
        </label>
      </div>
    </div>
  )
}

SwitchCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isFieldsetSwitch: PropTypes.bool,
}

export default SwitchCheckbox
