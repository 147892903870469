import React, { createContext, useState, useContext } from 'react';
import { IoClose } from 'react-icons/io5'
import Loader from '../Loader/Loader'

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showDismissButton, setShowDismissButton] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [dismissButtonTxt, setDismissButtonTxt] = useState('Cancel');
  const [submitButtonTxt, setSubmitButtonTxt] = useState('Submit');
  const [formSubmit, setFormSubmit] = useState('');
  const [dismissFn, setDismissFn] = useState(() => {});
  const [submitFn, setSubmitFn] = useState(() => {});

  const openModal = ({ content,
                       showSubmitButton = false,
                       showDismissButton = true,
                       showFooter = false,
                       showLoader = false,
                       dismissButtonTxt = 'Cancel',
                       submitButtonTxt = 'Submit',
                       formSubmit = '',
                       dismissFn = () => {},
                       submitFn = () => {},
  }) => {
    setModalContent(content);
    setShowSubmitButton(showSubmitButton);
    setShowDismissButton(showDismissButton);
    setShowFooter(showFooter);
    setShowLoader(showLoader);
    setDismissButtonTxt(dismissButtonTxt);
    setSubmitButtonTxt(submitButtonTxt);
    setFormSubmit(formSubmit);
    setDismissFn(() => dismissFn);
    setSubmitFn(() => submitFn);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
    setShowSubmitButton(false);
    setShowFooter(false);
  };

  return (

  <ModalContext.Provider value={{ isModalOpen, modalContent, openModal, closeModal, showSubmitButton, showFooter, showLoader, showDismissButton, dismissButtonTxt, submitButtonTxt, formSubmit, dismissFn, submitFn }}>
    <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};

const Modal = ({}) => {

  const { isModalOpen, modalContent, closeModal, showSubmitButton, showDismissButton, showFooter, showLoader, dismissButtonTxt, submitButtonTxt, formSubmit, dismissFn, submitFn } = useModal();

  if (!isModalOpen) {
    return null;
  }

  function handleDismiss() {
    closeModal();
    if (dismissFn) dismissFn();
  }

  function handleSubmit() {
    closeModal();
    if (submitFn) {
      submitFn();
    }
  }

  function handleFormSubmit() {
    document.getElementById(formSubmit).dispatchEvent(new Event('submit'));
    // console.log(document.getElementById(formSubmit));
    // handleSubmit();
    // Wait for the form to submit
    if (submitFn) {
      submitFn();
    }
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-container">
        <header>
          <div className="header-content">
            <IoClose onClick={handleDismiss} size={26} color="#ff0000" />
          </div>
        </header>
        <div className="modal-content">
          {showLoader ? <Loader /> : modalContent}
        </div>
        {showFooter ? (
          <footer>
            <div className="footer-content">
              {showDismissButton ? <button onClick={handleDismiss}>{dismissButtonTxt}</button> : null}
              {formSubmit ? (
                showSubmitButton ? (
                  <button form={formSubmit} onClick={handleFormSubmit}>{submitButtonTxt}</button>
                ) : null
              ) : (
                showSubmitButton ? (
                  <button onClick={handleSubmit}>{submitButtonTxt}</button>
                ) : null
              )}
            </div>
          </footer>
        ) : null}
      </div>
    </div>
  )
}

export default { Modal }
