import {getPrecisionFromSymbol, getSymbolQuote} from './symbol'

function sanitizeAccountData(data) {
  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeAccountData(item))
    })
    return ReturnData
  } else {
    return {
      _id: data && data._id ? data._id : '',
      name: data && data.name ? data.name : '',
      api: data && data.api ? data.api : '',
      type: data && data.type ? data.type : '',
      subaccount: data && data.subaccount ? data.subaccount : '',
      enabled: data && typeof data.enabled === 'boolean' ? data.enabled : true,
      sandbox: data && typeof data.sandbox === 'boolean' ? data.sandbox : false,
      secret: {
        apiKey: data && data.secret && data.secret.apiKey ? data.secret.apiKey : '',
        secretKey: data && data.secret && data.secret.secretKey ? data.secret.secretKey : '',
        secretToken: data && data.secret && data.secret.secretToken ? data.secret.secretToken : '',
        passphrase: data && data.secret && data.secret.passphrase ? data.secret.passphrase : '',
      },
      profit_total_calcUSD: data && data.profit_total_calcUSD ? data.profit_total_calcUSD : 0.0,
      external_ips: data && data.external_ips ? data.external_ips : '',
      external_ips_options: data && data.external_ips_options ? data.external_ips_options : '',
      disabled_last_reason: data && data.disabled_last_reason ? data.disabled_last_reason : '',
    }
  }
}

function sanitizeSymbolData(data) {
  // console.log('sanitizeAccountData', data)

  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeSymbolData(item))
    })
    return ReturnData
  } else {
    return {
      symbol: data && data.symbol ? data.symbol : '',
      limits: {
        amount: {
          min:
            data && data.limits && data.limits.amount && data.limits.amount.min
              ? data.limits.amount.min
              : 0,
          max:
            data && data.limits && data.limits.amount && data.limits.amount.max
              ? data.limits.amount.max
              : 0,
        },
        cost: {
          min:
            data && data.limits && data.limits.cost && data.limits.cost.min
              ? data.limits.cost.min
              : 0,
          max:
            data && data.limits && data.limits.cost && data.limits.cost.max
              ? data.limits.cost.max
              : 0,
        },
        market: {
          min:
            data && data.limits && data.limits.market && data.limits.market.min
              ? data.limits.market.min
              : 0,
          max:
            data && data.limits && data.limits.market && data.limits.market.max
              ? data.limits.market.max
              : 0,
        },
        price: {
          min:
            data && data.limits && data.limits.price && data.limits.price.min
              ? data.limits.price.min
              : 0,
          max:
            data && data.limits && data.limits.price && data.limits.price.max
              ? data.limits.price.max
              : 0,
        },
      },
      precision: {
        amount:
          data && data.precision && data.precision.amount
            ? data.precision.amount
            : 0,
        base:
          data && data.precision && data.precision.base
            ? data.precision.base
            : 0,
        price:
          data && data.precision && data.precision.price
            ? data.precision.price
            : 0,
        quote:
          data && data.precision && data.precision.quote
            ? data.precision.quote
            : 0,
      },
    }
  }
}

function sanitizeGridbotData(data) {
  const Precision = getPrecisionFromSymbol(data ? data.symbol : '')

  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeGridbotData(item))
    })
    return ReturnData
  } else {
    return {
      _id: data && data._id ? data._id : '',
      account_id: data && data.account_id ? data.account_id : '',
      account_name: data && data.account_name ? data.account_name : '',
      state: data && data.state ? data.state : 'stop',
      name: data && data.name ? data.name : 'Bot',
      type: data && data.type ? data.type : 'SpotClassic',
      symbol: data && data.symbol ? data.symbol : '',
      exchange: data && data.exchange ? data.exchange : '',
      account: data && data.account ? data.account : '',
      strategy: data && data.strategy ? data.strategy : '',
      diff: {
        diffbuy: {
          type:
            data && data.diff && data.diff.diffbuy && data.diff.diffbuy.type
              ? data.diff.diffbuy.type
              : 'static',
          value:
            data && data.diff && data.diff.diffbuy && data.diff.diffbuy.value
              ? data.diff.diffbuy.value
              : 0,
        },
        price_min:
          data && data.diff && data.diff.price_min
            ? data.diff.price_min
            : 0.0,
        price_max:
          data && data.diff && data.diff.price_max
            ? data.diff.price_max
            : 0.0,
        value: data && data.diff && data.diff.value ? data.diff.value : 0,
        uom: (data.diff && data.diff.diffbuy && data.diff.diffbuy.type && data.diff.diffbuy.type === 'percent') ? '%' : getSymbolQuote(data.symbol),
      },
      bo: {
        emacross: {
          enabled: data && data.bo && data.bo.emacross && typeof data.bo.emacross.enabled === 'boolean' ? data.bo.emacross.enabled : false,
          timeframe: data && data.bo && data.bo.emacross && data.bo.emacross.timeframe ? data.bo.emacross.timeframe : '1h',
          length1: data && data.bo && data.bo.emacross && data.bo.emacross.length1 ? data.bo.emacross.length1 : 200,
          length2: data && data.bo && data.bo.emacross && data.bo.emacross.length2 ? data.bo.emacross.length2 : 7,
          amount_quote_low: data && data.bo && data.bo.emacross && data.bo.emacross.amount_quote_low ? data.bo.emacross.amount_quote_low : 0,
          amount_quote_high: data && data.bo && data.bo.emacross && data.bo.emacross.amount_quote_high ? data.bo.emacross.amount_quote_high : 0,
        },
      },
      count: data && data.count ? data.count : 0,
      countbuy: data && data.countbuy ? data.countbuy : -1,
      countbuyoptional: data && data.countbuyoptional ? data.countbuyoptional : -1,
      countsell: data && data.countsell ? data.countsell : -1,
      countselloptional: data && data.countselloptional ? data.countselloptional : -1,
      amount: data && data.amount ? data.amount : 0,
      amount_quote: data && data.amount_quote ? data.amount_quote : 0,
      amount_max: data && data.amount_max ? data.amount_max : -1,
      roomtostart: data && data.roomtostart ? data.roomtostart : 0,
      roomtostartask: data && data.roomtostartask ? data.roomtostartask : 0,
      roomtostartbid: data && data.roomtostartbid ? data.roomtostartbid : 0,
      rebuymissingbuyorders: data && typeof data.rebuymissingbuyorders === 'boolean' ? data.rebuymissingbuyorders : false,
      cycleresetafterordercomplete: data && typeof data.cycleresetafterordercomplete === 'boolean' ? data.cycleresetafterordercomplete : true,
      type_sellorderbook: data && typeof data.type_sellorderbook === 'boolean' ? data.type_sellorderbook : false,
      ignore_spread: data && typeof data.ignore_spread === 'boolean' ? data.ignore_spread : false,
      postonly: data && typeof data.postonly === 'boolean' ? data.postonly : true,
      cycledelay: data && data.cycledelay ? data.cycledelay : 60,
      dryrun: data && typeof data.dryrun === 'boolean' ? data.dryrun : false,

      lastsub_id: data && data.lastsub_id ? data.lastsub_id : '',
      lastfilledorderside:
        data && data.lastfilledorderside ? data.lastfilledorderside : '',
      stats: {
        so: data && data.stats && data.stats.so ? data.stats.so : 0,
      },
      completeprofit: {
        profit_percent: data && data.completeprofit && data.completeprofit.profit_percent ? data.completeprofit.profit_percent : 0,
        profit_percent_day: data && data.completeprofit && data.completeprofit.profit_percent_day ? data.completeprofit.profit_percent_day : 0,
        profit_percent_day_1: data && data.completeprofit && data.completeprofit.profit_percent_day_1 ? data.completeprofit.profit_percent_day_1 : 0,
        profitUSD: data && data.completeprofit && data.completeprofit.profitUSD ? data.completeprofit.profitUSD : 0,

      },
      store: {
        last: data && data.store && data.store.last ? data.store.last : 0,
      }
    }
  }
}

function sanitizeThreeCMaxBotData(data) {
  // console.log('sanitizeAccountData', data)

  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeThreeCMaxBotData(item))
    })
    return ReturnData
  } else {
    return {
      _id: data && data._id ? data._id : '',
      account_id: data && data.account_id ? data.account_id : '',
      name: data && data.name ? data.name : '',
      state: data && data.state ? data.state : 'stop',
      threecommasid: data && data.threecommasid ? data.threecommasid : '',
      stats: {
        account_balance: data && data.stats && data.stats.account_balance ? data.stats.account_balance : 0,
        account_balance_min: data && data.stats && data.stats.account_balance_min ? data.stats.account_balance_min : 0,
        account_balance_sum_usd: data && data.stats && data.stats.account_balance_sum_usd ? data.stats.account_balance_sum_usd : 0,
        active_long_bots: data && data.stats && data.stats.active_long_bots ? data.stats.active_long_bots : 0,
        active_long_bots_max: data && data.stats && data.stats.active_long_bots_max ? data.stats.active_long_bots_max : 0,
        active_short_bots: data && data.stats && data.stats.active_short_bots ? data.stats.active_short_bots : 0,
        active_short_bots_max: data && data.stats && data.stats.active_short_bots_max ? data.stats.active_short_bots_max : 0,
        lastdate: data && data.stats && data.stats.lastdate ? data.stats.lastdate : '',
      },
      max_active_deals: data && data.max_active_deals ? data.max_active_deals : -1,
      max_active_deals_long: data && data.max_active_deals_long ? data.max_active_deals_long : -1,
      max_active_deals_short: data && data.max_active_deals_short ? data.max_active_deals_short : -1,
      max_active_deals_long_on: data && data.max_active_deals_long_on ? data.max_active_deals_long_on : -1,
      max_active_deals_short_on: data && data.max_active_deals_short_on ? data.max_active_deals_short_on : -1,
      usd_amount_min: data && data.usd_amount_min ? data.usd_amount_min : 0,
      usd_amount_min_currency: data && data.usd_amount_min_currency ? data.usd_amount_min_currency : 'USDT',
      blacklist: data && data.blacklist ? data.blacklist : '',
      whitelist: data && data.whitelist ? data.whitelist : '',
      dynamicslsmarttrade: {
        type: data && data.dynamicslsmarttrade && data.dynamicslsmarttrade.type ? data.dynamicslsmarttrade.type : 'MANUAL',
        filter: data && data.dynamicslsmarttrade && data.dynamicslsmarttrade.filter ? data.dynamicslsmarttrade.filter : '',

      },
      blacklistglobal: {
        enabled: data && data.blacklistglobal && typeof data.blacklistglobal.enabled === 'boolean' ? data.blacklistglobal.enabled : false,
        enabled_onepair: data && data.blacklistglobal && typeof data.blacklistglobal.enabled_onepair === 'boolean' ? data.blacklistglobal.enabled_onepair : false,
        staticpairs: data && data.blacklistglobal && data.blacklistglobal.staticpairs ? data.blacklistglobal.staticpairs : '',
        whitelist: data && data.blacklistglobal && data.blacklistglobal.whitelist ? data.blacklistglobal.whitelist : '',
        stats: {
          active_deal_symbols: data && data.blacklistglobal && data.blacklistglobal.stats && data.blacklistglobal.stats.active_deal_symbols ? data.blacklistglobal.stats.active_deal_symbols : 0,
        },
        ignore_long: data && data.blacklistglobal && typeof data.blacklistglobal.ignore_long === 'boolean' ? data.blacklistglobal.ignore_long : false,
        ignore_short: data && data.blacklistglobal && typeof data.blacklistglobal.ignore_short === 'boolean' ? data.blacklistglobal.ignore_short : false,
      },
      use_secrettoken: data && typeof data.use_secrettoken === 'boolean' ? data.use_secrettoken : false,
      ignoreactivatedttp: data && typeof data.ignoreactivatedttp === 'boolean' ? data.ignoreactivatedttp : false,
      panicsellaftermissedtrailing: data && typeof data.panicsellaftermissedtrailing === 'boolean' ? data.panicsellaftermissedtrailing : false,
      panicsellaftermissedtrailing_timer: data && data.panicsellaftermissedtrailing_timer ? data.panicsellaftermissedtrailing_timer : 15,

      cycledelay: data && data.cycledelay ? data.cycledelay : 120,

    }
  }
}

function sanitizeSignalData(data) {
  // console.log('sanitizeAccountData', data)

  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeSignalData(item))
    })
    return ReturnData
  } else {
    return {
      _id: data && data._id ? data._id : '',
      name: data && data.name ? data.name : '',
      symbol: data && data.symbol ? data.symbol : '',
      action: data && data.action ? data.action.toLowerCase() : '',
      createdate: data && data.createdate ? data.createdate : '',
    }
  }
}

function sanitizeSignalProcessorData(data) {
  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeSignalProcessorData(item))
    })
    return ReturnData
  } else {
    return {
      _id: data && data._id ? data._id : '',
      account_id: data && data.account_id ? data.account_id : '',
      account_name: data && data.account_name ? data.account_name : '',
      name: data && data.name ? data.name : 'NoName',
      process: data && data.process ? data.process : '',
      createdate: data && data.createdate ? data.createdate : '',
      lastdate: data && data.lastdate ? data.lastdate : '',

    }
  }
}

function sanitizeSmartBotSignalData(data) {
  // Return null if data is null
  if (!data) {
    return null
  }

  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeSmartBotSignalData(item))
    })
    return ReturnData
  } else {
    return {
      action: data && data.action ? data.action : '',
      timeframe: data && data.timeframe ? data.timeframe : '1h',
      symbol: data && data.symbol ? data.symbol : '',
      createdate: data && data.createdate ? data.createdate : '',
      name: data && data.name ? data.name : '',
    }
  }
}

function sanitizeSmartBotProfitData(data) {
  // Return null if data is null
  if (!data) {
    return null
  }

  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeSmartBotProfitData(item))
    })
    return ReturnData
  } else {
    return {
      exchange_lasttradedate: data && data.exchange_lasttradedate ? data.exchange_lasttradedate : '',
      exchange_filled: data && data.exchange_filled ? data.exchange_filled : 0,
      exchange_average: data && data.exchange_average ? data.exchange_average : 0,
      side: data && data.side ? data.side : '',
      name: data && data.name ? data.name : '',
      base: data && data.base ? data.base : '',
      calcUSD: data && data.calcUSD ? data.calcUSD : 0,
    }
  }
}

function sanitizeSmartBotData(data) {
  // console.log('sanitizeAccountData', data)

  // Return null if data is null
  if (!data) {
    return null
  }

  function sanitizeDCAOrderData(dcaorders) {
    if (Array.isArray(dcaorders)) {
      let ReturnData = []
      dcaorders.map((item) => {
        ReturnData.push(sanitizeDCAOrderData(item))
      })
      return ReturnData
    } else {
      return {
        amount: dcaorders && dcaorders.amount ? dcaorders.amount : 0.0,
        amounttype: dcaorders && dcaorders.amounttype ? dcaorders.amounttype : 'base',
        dcatype: dcaorders && dcaorders.dcatype ? dcaorders.dcatype : 'bo',
        name: dcaorders && dcaorders.name ? dcaorders.name : '',
        optional: dcaorders && dcaorders.optional ? dcaorders.optional : false,
      }
    }
  }

  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeSmartBotData(item))
    })
    return ReturnData
  } else {
    return {
      _id: data && data._id ? data._id : '',
      account_id: data && data.account_id ? data.account_id : '',
      // user_id: data && data.user_id ? data.user_id : '',
      createdate: data && data.createdate ? data.createdate : '',
      type: data && data.type ? data.type : 'dca',
      name: data && data.name ? data.name : 'Bot',
      symbol: data && data.symbol ? data.symbol : '',
      amount: data && data.amount ? data.amount : 0,
      amountuom: (data && data.amounttype ? data.amounttype : 'percent' === 'percent' || data.amounttype === 'walletpertrade') ? '%' : getSymbolQuote(data.symbol),
      amounttype: data && data.amounttype ? data.amounttype : 'percent',
      leverage: data && data.leverage ? data.leverage : 1,
      strategy: data && data.strategy ? data.strategy : 'long',
      hedgemode: data && data.hedgemode && typeof data.hedgemode === 'boolean' ? data.hedgemode : false,
      marginmode: data && data.marginmode ? data.marginmode : 'CROSS',
      timeframe: data && data.timeframe ? data.timeframe : '1h',
      bo: {
        rsi: {
          enabled: data && data.bo && data.bo.rsi && typeof data.bo.rsi.enabled === 'boolean' ? data.bo.rsi.enabled : false,
          length: data && data.bo && data.bo.rsi && data.bo.rsi.length ? data.bo.rsi.length : 14,
          timeframe: data && data.bo && data.bo.rsi && data.bo.rsi.timeframe ? data.bo.rsi.timeframe : '1h',
        },
        ema: {
          enabled: data && data.bo && data.bo.ema && typeof data.bo.ema.enabled === 'boolean' ? data.bo.ema.enabled : false,
          length: data && data.bo && data.bo.ema && data.bo.ema.length ? data.bo.ema.length : 200,
          timeframe: data && data.bo && data.bo.ema && data.bo.ema.timeframe ? data.bo.ema.timeframe : '1h',
        },
        candlecount: data && data.bo && data.bo.candlecount ? data.bo.candlecount : 5,
        resistanceandsupport: data && data.bo && typeof data.bo.resistanceandsupport === 'boolean' ? data.bo.resistanceandsupport : false,
        engulfing_only: data && data.bo && typeof data.bo.engulfing_only === 'boolean' ? data.bo.engulfing_only: false,
      },
      tp: {
        type: data && data.tp && data.tp.type ? data.tp.type : 'percent',
        atr: {
          length: data && data.tp && data.tp.atr && data.tp.atr.length ? data.tp.atr.length : 14,
          timeframe: data && data.tp && data.tp.atr && data.tp.atr.timeframe ? data.tp.atr.timeframe : '1h',
          multiplier: data && data.tp && data.tp.atr && data.tp.atr.multiplier ? data.tp.atr.multiplier : 1,
        },
        rr: {
          multiplier: data && data.tp && data.tp.rr && data.tp.rr.multiplier ? data.tp.rr.multiplier : 1,
        },
        percent: data && data.tp && data.tp.percent ? data.tp.percent : 1,
      },
      sl: {
        type: data && data.sl && data.sl.type ? data.sl.type : 'percent',
        atr: {
          length: data && data.sl && data.sl.atr && data.sl.atr.length ? data.sl.atr.length : 14,
          timeframe: data && data.sl && data.sl.atr && data.sl.atr.timeframe ? data.sl.atr.timeframe : '1h',
          multiplier: data && data.sl && data.sl.atr && data.sl.atr.multiplier ? data.sl.atr.multiplier : 1,
        },
        percent: data && data.sl && data.sl.percent ? data.sl.percent : 1,
        0: {
          enabled: data && data.sl && data.sl[0] && typeof data.sl[0].enabled === 'boolean' ? data.sl[0].enabled : false,
          pricetype: data && data.sl && data.sl[0] && data.sl[0].pricetype ? data.sl[0].pricetype : 'base',
          price: data && data.sl && data.sl[0] && data.sl[0].price ? data.sl[0].price : 0,
          side: data && data.sl && data.sl[0] && data.sl[0].side ? data.sl[0].side : 'sell',
          amounttype: data && data.sl && data.sl[0] && data.sl[0].amounttype ? data.sl[0].amounttype : 'percent',
          amount: data && data.sl && data.sl[0] && data.sl[0].amount ? data.sl[0].amount : 100,
        }
      },
      dryrun: data && typeof data.dryrun === 'boolean' ? data.dryrun : false,
      cycledelay: data && data.cycledelay ? data.cycledelay : 60,
      note: data && data.note ? data.note : '',

      state: data && data.state ? data.state : 'stopped',
      account_name: data && data.account_name ? data.account_name : '',
      profit: {
        total: {
          calcUSD: data && data.profit && data.profit.total && data.profit.total.calcUSD ? parseFloat(data.profit.total.calcUSD).toFixed(4): '-',
        }
      },
      DCAMaxActiveSO: data && data.DCAMaxActiveSO ? data.DCAMaxActiveSO : 1,
      DCAMaxSO: data && data.DCAMaxSO ? data.DCAMaxSO : -1,
      DCARunningTimes: data && data.DCARunningTimes ? data.DCARunningTimes : -1,
      dcaorders: data && data.dcaorders ? data.dcaorders : [],
      events: data && data.events ? data.events : [],
      // dcaorders: data && data.dcaorders ? sanitizeDCAOrderData(data.dcaorders) : [],
      AllowOtherClientOrderId: data && typeof data.AllowOtherClientOrderId === 'boolean' ? data.AllowOtherClientOrderId : false,

      // Assistent:
      lastsub_id: data && data.lastsub_id ? data.lastsub_id : '',
      stats: {
        current_price_bid: data && data.stats && data.stats.current_price_bid ? data.stats.current_price_bid : 0,
        exchange_balance_free: data && data.stats && data.stats.exchange_balance_free ? data.stats.exchange_balance_free : 0,
        exchange_balance_total: data && data.stats && data.stats.exchange_balance_total ? data.stats.exchange_balance_total : 0,
        current_stats_so: data && data.stats && data.stats.current_stats_so ? data.stats.current_stats_so + "/" + (data.stats.current_stats_so_max ?? '?') : "0/0",

        // array:
        orders: data && data.stats && data.stats.orders ? data.stats.orders : [],
      }

    }
  }
}


// orders: [{
//   side: data && data.stats && data.stats.orders && data.stats.orders.side ? data.stats.orders.side : 'buy',
//   price: data && data.stats && data.stats.orders && data.stats.orders.price ? data.stats.orders.price : 0,
//   amount: data && data.stats && data.stats.orders && data.stats.orders.amount ? data.stats.orders.amount : 0,
//   status: data && data.stats && data.stats.orders && data.stats.orders.status ? data.stats.orders.status : 'open',
// }],

function sanitizeBacktestData(data) {
  // console.log('sanitizeBacktestData', data)
  // Return null if data is null
  if (!data) {
    return null
  }

  // Check if data is an array
  if (Array.isArray(data)) {
    let ReturnData = []
    data.map((item) => {
      ReturnData.push(sanitizeBacktestData(item))
    })
    // console.log('ReturnData', ReturnData)
    return ReturnData
  } else {
    return {
      _id: data && data._id ? data._id : '',
      user_id: data && data.user_id ? data.user_id : null,
      bot_name: data && data.bot_name ? data.bot_name : '<loading>',
      bot_type: data && data.bot_type ? data.bot_type : 'unknown',
      startdate: data && data.startdate ? data.startdate : '',
      stopdate: data && data.stopdate ? data.stopdate : '',
      publicview: data && typeof data.publicview === 'boolean' ? data.publicview : false,
      timeframe: data && data.timeframe ? data.timeframe : '',
      withoutoptimization: data && typeof data.withoutoptimization === 'boolean' ? data.withoutoptimization : false,
      bot_id: data && data.bot_id ? data.bot_id : '',
      status: data && data.status ? data.status : '',
      progress: data && data.progress ? data.progress.toFixed(2) : 0,
      progressdate: data && data.progressdate ? data.progressdate : '',
      // bot: {
      //   $ref: data && data.bot && data.bot.$ref ? data.bot.$ref : '',
      //   $id: data && data.bot && data.bot.$id ? data.bot.$id : '',
      // },
      backtestbot: data && data.backtestbot ? data.backtestbot : {},
      createdate: data && data.createdate ? data.createdate : '',
    }
  }
}

export {
  sanitizeAccountData,
  sanitizeSymbolData,
  sanitizeGridbotData,
  sanitizeThreeCMaxBotData,
  sanitizeSignalData,
  sanitizeSignalProcessorData,
  sanitizeSmartBotData,
  sanitizeSmartBotSignalData,
  sanitizeSmartBotProfitData,
  sanitizeBacktestData
}
