import { useContext } from 'react'
import { GlobalContext } from '../state'

const useGetAPIKey = () => {
  const globalState = useContext(GlobalContext)
  return () => {
    if (globalState.apikey.length) {
      return globalState.apikey
    }
    return localStorage.getItem('apikey')
  }
}

export default useGetAPIKey
