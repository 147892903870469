import { useState, useContext } from 'react'
import { GlobalContext } from '../../state'
import { useNavigate } from 'react-router-dom'
import useLogin from '../../hooks/useLogin'
//#import jwt_decode from 'jwt-decode';

function LoginForm({ setGlobalState }) {
  const globalState = useContext(GlobalContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const attemptLogin = useLogin()
  const [ErrorMessages, setErrorMessages] = useState([])

  // Lower case the username
  const onUsernameChange = (event) => setUsername(event.target.value.toLowerCase())
  const onPasswordChange = (event) => setPassword(event.target.value)
  const onLoginSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    attemptLogin(formData)
      .then((res) => {
        if (res === undefined || res === false) {
          setErrorMessages(['Wrong username or password!'])
          return
        }
        // Local Storage wird später wieder gelöscht.
        localStorage.setItem('token', res.token)
        localStorage.setItem('rights', JSON.stringify(res.rights))
        localStorage.setItem('apikey', res.api.key)
        globalState.token = res.token
        globalState.rights = res.rights
        globalState.apikey = res.api.key
        //console.log(res)
        navigate('/dashboard')
      })
      // .catch((err) => console.log(err))
      .catch((err) => {
        setErrorMessages([err])
      })
  }

  // Remove Error Messages after 5 seconds, if there are any
  if (ErrorMessages.length > 0) {
    setTimeout(() => {
      setErrorMessages([])
    }, 5000)
  }

  return (
    <form className="login-form" onSubmit={onLoginSubmit}>
      {ErrorMessages.length > 0 && (
        <div className="login-error-messages">
          {ErrorMessages.map((message) => (
            <div key={message}>{message}</div>
          ))}
        </div>
      )}
      <div className="input-control-login">
        <label htmlFor="username">E-Mail</label>
        <input
          onChange={onUsernameChange}
          type="text"
          id="username"
          name="user"
          placeholder="e.g. noreply@gmail.com"
          autoComplete="username"
          value={username}
          required
        />
      </div>
      <div className="input-control-login">
        <label htmlFor="password">Password</label>
        <input
          onChange={onPasswordChange}
          type="password"
          id="password"
          name="pass"
          placeholder=""
          value={password}
          autoComplete="current-password"
          required
        />
      </div>
      <div className="input-control-login">
        <input type="submit" />
      </div>
    </form>
  )
}

export default LoginForm
