import tokenFromLS from "../tokenFromLS";
import {state as globalState} from "../../state";

class CBApi {
  static API_URL = process.env.REACT_APP_API_URI;

  static fetch(path, method = 'GET', params) {
    const resource = `${CBApi.API_URL}/${path}`;
    let fetchOptions = {
      method: method,
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    if (method !== 'GET') {
      // Post Params:
      fetchOptions.body = JSON.stringify(params);
    }

    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(resource, fetchOptions);
    //     return response.json();
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // }
    // return fetchData();
    return fetch(resource, fetchOptions)
      .then(response => response.json())
      .catch(error => console.error('Error:', error));
  }
}

export default CBApi;