import React, {useContext, useState} from 'react';
import {useFetchThreeCmaxBot} from '../../../hooks/useFetchThreeCmaxbot'
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import InputField from '../../EditFields/Input/InputField'
import Select from '../../EditFields/Select/Select'
import {useFetchAccount, buildAccountOptions} from "../../../hooks/useFetchAccount";
import Slider from "../../EditFields/Slider/Slider";
import tokenfromLS from "../../../utils/tokenFromLS";
import {useNavigate, useParams} from 'react-router-dom'
import {GlobalContext} from "../../../state";
import {sanitizeThreeCMaxBotData} from "../../../utils/sanatizer";
import useFetcher from "../../../hooks/useFetcher";

const ThreeCmaxbotDetailPage = () => {
  // const [threeCmaxbotData, setThreeCmaxbotData] = useFetchThreeCmaxBot(id)
  let params = useParams();
  let id = params.id
  const [threeCmaxbotData, setThreeCmaxbotData] = useFetcher(
    `3cmaxbot/${id}`,
    'GET',
    sanitizeThreeCMaxBotData,
  )
  const [formSubmitMsg, setformSubmitMsg] = useState(null)
  const [accountData] = useFetchAccount()

  const navigate = useNavigate();

  // Global state
  const globalState = useContext(GlobalContext)

  const onSubmitForm = async (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)

    const {blacklistglobal_staticpairs, blacklistglobal_whitelist, blacklistglobal_enabled, blacklistglobal_enabled_onepair, blacklistglobal_ignore_long, blacklistglobal_ignore_short,
      dynamicslsmarttrade_filter, dynamicslsmarttrade_type } = data
    // delete data.secretKey
    // delete data.apiKey
    // delete data.secretToken
    // delete data.apihidden
    // // delete data.id
    data = {
      ...data,
      blacklistglobal: {
        enabled: blacklistglobal_enabled,
        enabled_onepair: blacklistglobal_enabled_onepair,
        staticpairs: blacklistglobal_staticpairs,
        whitelist: blacklistglobal_whitelist,
        ignore_long: blacklistglobal_ignore_long,
        ignore_short: blacklistglobal_ignore_short,
      },
      dynamicslsmarttrade: {
        filter: dynamicslsmarttrade_filter,
        type: dynamicslsmarttrade_type,
      },
    }
    data = sanitizeThreeCMaxBotData(data)
    console.log(data)
    console.log(threeCmaxbotData)

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/3cmaxbot/${id}`
      let Method = 'PUT'
      if (id === 'add') {
        RequestURL = `${process.env.REACT_APP_API_URI}/3cmaxbot`
        Method = 'ADD'
        delete data._id
      }
      const response = await fetch(
        RequestURL,
        {
          method: Method,
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )

      const json = await response.json()
      if (json.hasOwnProperty('ok')) {
        navigate('/3cmaxbot');
      } else {
        setformSubmitMsg(json)
      }

    } catch (err) {
      // console.log(err)
    }
  }

  return (
    threeCmaxbotData && (
      <div>
        <form
          style={{padding: '0 4rem', margin: '0 auto'}}
          onSubmit={onSubmitForm}
        >
          <Fieldset legend="3cmaxbot Settings" columns={1} dismiss={true}>
            {formSubmitMsg ? <div>{JSON.stringify(formSubmitMsg)}</div> : null}
            <Select
              name="account_id"
              label="Account"
              options={buildAccountOptions(accountData, '3commas')}
              value={threeCmaxbotData.account_id}
              // onChange={function (evt) {
              //   const options = evt.target.options
              //   const index = options.selectedIndex
              //   getSymbols(options[index].value).then((data) =>
              //     setSymbols(data)
              //   )
              // }}
            />
            <InputField
              type="text"
              name="threecommasid"
              label="3Commas Account ID"
              value={threeCmaxbotData.threecommasid}
              pattern={[
                '^.{8,}$', // min 8 chars
                '(?=.*\\d)', // number required
                '(?=.*[A-Z])', // uppercase letter
              ]}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    threecommasid: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="name"
              label="Name"
              value={threeCmaxbotData.name}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    name: evt.target.value,
                  }
                })
              }}
            />
          </Fieldset>
          <Fieldset legend="DCA Bot settings" columns={2} collapsed={false}>
            <InputField
              type="text"
              name="max_active_deals"
              label="Total Max Deals (Long + Short)"
              value={parseInt(threeCmaxbotData.max_active_deals)}
              min={-1}
              max={200}
              step={1}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    max_active_deals: evt.target.value,
                  }
                })
              }}
            />
            <div></div>
            <InputField
              type="text"
              name="usd_amount_min"
              label="Don't start deal(s) if the exchange volume is less than"
              value={threeCmaxbotData.usd_amount_min}
              uom={threeCmaxbotData.usd_amount_min_currency}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    usd_amount_min: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="usd_amount_min_currency"
              label="Currency"
              value={threeCmaxbotData.usd_amount_min_currency}
              // placehodler="USDT"
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    usd_amount_min_currency: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="max_active_deals_long"
              label="Total Max Deals (Long)"
              value={parseInt(threeCmaxbotData.max_active_deals_long)}
              min={-1}
              max={100}
              step={1}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    max_active_deals_long: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="max_active_deals_long_on"
              label="Total Max Deals (Long) On"
              value={parseInt(threeCmaxbotData.max_active_deals_long_on)}
              min={-1}
              max={100}
              step={1}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    max_active_deals_long_on: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="max_active_deals_short"
              label="Total Max Deals (Short)"
              value={parseInt(threeCmaxbotData.max_active_deals_short)}
              min={-1}
              max={100}
              step={1}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    max_active_deals_short: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="max_active_deals_short_on"
              label="Total Max Deals (Short) On"
              value={parseInt(threeCmaxbotData.max_active_deals_short_on)}
              min={-1}
              max={100}
              step={1}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    max_active_deals_short_on: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="blacklist"
              label="Blacklist Name"
              value={threeCmaxbotData.blacklist}
              // placehodler="USDT"
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    blacklist: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="whitelist"
              label="Whitelist Name"
              value={threeCmaxbotData.whitelist}
              // placehodler="USDT"
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    whitelist: evt.target.value,
                  }
                })
              }}
            />
          </Fieldset>
          <Fieldset legend="Dynamic SL settings (for Smarttrades)" columns={2} collapsed={true} dismiss={true}>
            <Select
              name="dynamicslsmarttrade_type"
              label="Type"
              value={threeCmaxbotData.dynamicslsmarttrade && threeCmaxbotData.dynamicslsmarttrade.type}
              options={[
                {name: 'Manual', value: 'MANUAL'},
                {name: 'T1->Move to buy price', value: 'T1_CROSSED_MOVE_TO_BUY_PRICE'},
                {name: 'T2->Move to buy price', value: 'T2_CROSSED_MOVE_TO_BUY_PRICE'},
                {name: 'T3->Move to buy price', value: 'T3_CROSSED_MOVE_TO_BUY_PRICE'},
                {name: 'T4->Move to buy price', value: 'T4_CROSSED_MOVE_TO_BUY_PRICE'},
                {name: 'T1->Move to target minus 1', value: 'T1_CROSSED_MOVE_TO_TARGET_CROSSED_MINUS_1'},
                {name: 'T1->Move to target minus 2', value: 'T1_CROSSED_MOVE_TO_TARGET_CROSSED_MINUS_2'},
                {name: 'T2->Move to target minus 1', value: 'T2_CROSSED_MOVE_TO_TARGET_CROSSED_MINUS_1'},
                {name: 'T2->Move to target minus 2', value: 'T2_CROSSED_MOVE_TO_TARGET_CROSSED_MINUS_2'},
              ]}
              // onChange={function (evt) {
              //   const options = evt.target.options
              //   const index = options.selectedIndex
              //   const value = options[index].value
              //
              //   setThreeCmaxbotData(function (prevState) {
              //     return {
              //       ...prevState,
              //       dynamicslsmarttrade: {
              //         ...prevState.dynamicslsmarttrade,
              //         type: evt.target.value
              //       },
              //     }
              //   })
              // }}
            />
            <InputField
              type="text"
              name="dynamicslsmarttrade_filter"
              label="Filter"
              value={threeCmaxbotData.dynamicslsmarttrade && threeCmaxbotData.dynamicslsmarttrade.filter}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    dynamicslsmarttrade: {
                      ...prevState.dynamicslsmarttrade,
                      filter: evt.target.value
                    },
                  }
                })
              }}
            />
            {/*<InputField*/}
            {/*  type="text"*/}
            {/*  name="dynamicslsmarttrade.dynamic.0.tpl"*/}
            {/*  label="TP Low 0"*/}
            {/*  value={threeCmaxbotData.dynamicslsmarttrade && threeCmaxbotData.dynamicslsmarttrade.dynamic && threeCmaxbotData.dynamicslsmarttrade.dynamic[0] && threeCmaxbotData.dynamicslsmarttrade.dynamic[0].tpl && threeCmaxbotData.dynamicslsmarttrade.dynamic[0].tpl}*/}
            {/*  onChange={function (evt) {*/}
            {/*    setThreeCmaxbotData(function (prevState) {*/}
            {/*      return {*/}
            {/*        ...prevState,*/}
            {/*        dynamicslsmarttrade: {*/}
            {/*          ...prevState.dynamicslsmarttrade,*/}
            {/*          dynamic: [*/}
            {/*            {*/}
            {/*              ...prevState.dynamicslsmarttrade.dynamic[0],*/}
            {/*              tpl: evt.target.value*/}
            {/*            },*/}
            {/*            ...prevState.dynamicslsmarttrade.dynamic.slice(1)*/}
            {/*          ]*/}
            {/*        },*/}
            {/*      }*/}
            {/*    })*/}
            {/*  }}*/}
            {/*/>*/}
          </Fieldset>
          <Fieldset legend="Blacklist settings (for global Blacklisting)" columns={1}
                    collapsed={threeCmaxbotData.blacklistglobal.enabled?false:true} dismiss={true}>
            <InputField
              type="switch"
              name="blacklistglobal_enabled"
              label="Blacklist Manager Enabled"
              checked={threeCmaxbotData.blacklistglobal && threeCmaxbotData.blacklistglobal.enabled}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    blacklistglobal: {
                      ...prevState.blacklistglobal,
                      enabled: evt.target.value
                    },
                  }
                })
              }}
            />
            <InputField
              type="switch"
              name="blacklistglobal_enabled_onepair"
              label="Blacklist all pairs that have active deals"
              checked={threeCmaxbotData.blacklistglobal && threeCmaxbotData.blacklistglobal.enabled_onepair}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    blacklistglobal: {
                      ...prevState.blacklistglobal,
                      enabled_onepair: evt.target.value
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="blacklistglobal_staticpairs"
              label="Static Pairs (use 'init' to read current pairs from 3commas)"
              value={threeCmaxbotData.blacklistglobal?threeCmaxbotData.blacklistglobal.staticpairs:''}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    blacklistglobal: {
                      ...prevState.blacklistglobal,
                      staticpairs: evt.target.value
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="blacklistglobal_whitelist"
              label="Whitelist of Pairs (use comma separated list of pairs)"
              value={threeCmaxbotData.blacklistglobal?threeCmaxbotData.blacklistglobal.whitelist:''}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    blacklistglobal: {
                      ...prevState.blacklistglobal,
                      whitelist: evt.target.value
                    },
                  }
                })
              }}
            />
            <InputField
              type="text"
              name="blacklistglobal_stats_active_deal_symbols"
              label="Dynamic Pairs (read only)"
              // disabled="true"
              value={threeCmaxbotData.stats && threeCmaxbotData.stats.active_deal_symbols?threeCmaxbotData.stats.active_deal_symbols:''}
              onChange={null}
            />
            <InputField
              type="switch"
              name="blacklistglobal_ignore_long"
              label="Ignore Long Trades"
              checked={threeCmaxbotData.blacklistglobal && threeCmaxbotData.blacklistglobal.ignore_long}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    blacklistglobal: {
                      ...prevState.blacklistglobal,
                      ignore_long: evt.target.value
                    },
                  }
                })
              }}
            />
            <InputField
              type="switch"
              name="blacklistglobal_ignore_short"
              label="Ignore Short Trades"
              checked={threeCmaxbotData.blacklistglobal && threeCmaxbotData.blacklistglobal.ignore_short}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    blacklistglobal: {
                      ...prevState.blacklistglobal,
                      ignore_short: evt.target.value
                    },
                  }
                })
              }}
            />
          </Fieldset>
          <Fieldset legend="Advanced Settings" columns={2} collapsed={false} dismiss={true}>
            <InputField
              type="text"
              name="cycledelay"
              label="Cycle Delay"
              value={parseInt(threeCmaxbotData.cycledelay)}
              min={10}
              max={600}
              step={1}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    cycledelay: evt.target.value,
                  }
                })
              }}
            />
            <div></div>
            <InputField
              type="switch"
              name="use_secrettoken"
              label="Use Secret Token"
              checked={threeCmaxbotData.use_secrettoken}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    use_secrettoken: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="switch"
              name="ignoreactivatedttp"
              label="DCA Bot: Ignore activated TTP"
              checked={threeCmaxbotData.ignoreactivatedttp}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    ignoreactivatedttp: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="switch"
              name="panicsellaftermissedtrailing"
              label="DCA Bot: Panic sell after missed trailing"
              checked={threeCmaxbotData.panicsellaftermissedtrailing}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    panicsellaftermissedtrailing: evt.target.value,
                  }
                })
              }}
            />
            {/*ToDo: Slider doesn't work (no initial value)*/}
            <InputField
              type="text"
              name="panicsellaftermissedtrailing_timer"
              label="DCA Bot: Panic sell after missed trailing Timer"
              value={parseInt(threeCmaxbotData.panicsellaftermissedtrailing_timer)}
              min={1}
              max={600}
              step={1}
              disabled={!threeCmaxbotData.panicsellaftermissedtrailing}
              onChange={function (evt) {
                setThreeCmaxbotData(function (prevState) {
                  return {
                    ...prevState,
                    panicsellaftermissedtrailing_timer: evt.target.value,
                  }
                })
              }}
            />

            <InputField type="hidden" name="_id" value={threeCmaxbotData ? threeCmaxbotData._id : 'add'}/>
          </Fieldset>
          <button type="submit">Save</button>
        </form>
      </div>
    )
  )
}

export default ThreeCmaxbotDetailPage
