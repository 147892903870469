import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../state'
import tokenFromLS from '../utils/tokenFromLS'
// import {getSymbolBase, getSymbolQuote} from "../utils/symbol";
// import sanitizeData from "../components/Pages/SmartbotPage/sanitizeData";
// import {sanitizeGridbotData} from "../utils/sanatizer";

function useFetchSmartbot(botId) {
  const globalState = useContext(GlobalContext)
  const [botData, setBotData] = useState([])
  useEffect(() => {
    const apiHost = process.env.REACT_APP_API_URI
    const id = botId
    let resource = `${apiHost}/smartbot`
    if (id === 'add') {
      return [];
    }
    if (id) {
      resource = `${apiHost}/smartbot/${id}`
    }
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const json = await response.json()
        // Set UOM for the frontend
        // json.amounttype.uom = getSymbolQuote(json.symbol)
        // if (json.diff && json.diff.diffbuy && json.diff.diffbuy.type && json.diff.diffbuy.type === 'percent') {
        //   json.diff.uom = '%'
        // }
        if (!json.symbol) {
          json.symbol = 'BTC/USD';
        }
        // setBotData(sanitizeData(json))
        setBotData(json)

      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()
  }, [])
  return [botData, setBotData]
}

function useFetchSmartbotLastTrades() {
  const globalState = useContext(GlobalContext)
  const [smartbotLastTradeData, setSmartbotLastTradeData] = useState([])
  useEffect(() => {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/smartbot/lasttrade`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const json = await response.json()
        setSmartbotLastTradeData(json.data)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()

  }, [])
  return [smartbotLastTradeData, setSmartbotLastTradeData]
}


export { useFetchSmartbot, useFetchSmartbotLastTrades }
