import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {GlobalContext} from "../../state";
import {isObject} from "@amcharts/amcharts5/.internal/core/util/Type";
import tokenFromLS from "../../utils/tokenFromLS";

const CryptoWidget = ({
                        name,
  type,
  id,
  backtesterid = null,
  fetchdata = false,
                        // label,
                        // disabled = false,
                        // checked = false,
                        // onChange = () => {
                        // },
                        // // isFieldsetSwitch = false,
                      }) => {
  function sanitizeWidgetData(data) {
    const _current_price = data && data.current_price ? data.current_price : 0.0
    const _current_price_average = data && data.current_price_average ? data.current_price_average : 0.0
    const _current_unrealized_profit = data && data.current_unrealized_profit ? data.current_unrealized_profit : 0.0
    const _current_trailing_name = data && data.current_trailing_name ? data.current_trailing_name : ''
    const _current_trailing_price = data && data.current_trailing_price ? data.current_trailing_price : 0.0
    const _current_strategy = data && data.current_strategy ? data.current_strategy : 'long'

    let _current_price_color = 'rgb(237, 85, 101)'
    if (_current_strategy === 'long') {
      if (_current_price > _current_price_average) {
        _current_price_color = 'rgb(26, 179, 148)'
      }
    } else {
      if (_current_price < _current_price_average) {
        _current_price_color = 'rgb(26, 179, 148)'
      }
    }
    // get the percent between the current price and the average price
    let _current_price_percent = _current_price_average !== 0 ? ((_current_price - _current_price_average) / _current_price_average * 100).toFixed(2) : 0
    if (_current_strategy === 'short') {
      _current_price_percent = _current_price_average !== 0 ? ((_current_price_average - _current_price) / _current_price * 100).toFixed(2) : 0
    }

    // data array of orders
    let _orders = []
    // push the averageprice to the orders
    if (_current_price_average > 0.0) {
      _orders.push({
        id: -2,
        name: 'Average',
        price: _current_price_average,
        amount: 0,
        state: 'created',
        text: 'Avg',
      })
    }
    // push the currentprice to the orders
    if (_current_price > 0.0) {
      _orders.push({
        id: -1,
        name: 'Current',
        price: _current_price,
        amount: 0,
        state: 'created',
        text: 'Current',
      })
    }
    if (_current_trailing_price > 0.0) {
      _orders.push({
        id: -3,
        name: _current_trailing_name,
        price: _current_trailing_price,
        amount: 0,
        state: 'created',
        text: 'TSL',
      })
    }

    if (data) {
      // Check every item in the object
      for (let i = 0; i < Object.keys(data).length - 1; i++) {
        if (isObject(data[i]) && data[i].id) {
          // console.log('isObject(data[i])', isObject(data[i]))

          // Max Position: _high
          // Min Position: _low
          // get the percent between the current price and _high and _low
          // let _position = 0
          // if (_high !== _low) {
          //   _position = ((_high - data[i].price) / (_high - _low) * 100).toFixed(2)
          // }
          if (data[i].price > 0.0) {
            _orders.push({
              id: data[i].id,
              name: data[i].name,
              price: data[i].price,
              amount: data[i].amount,
              state: data[i].state,

              text: data[i].state === 'created' ? 'Placed' : (data[i].state === 'canceled' ? 'Canceled' : (data[i].state === 'waiting' ? 'Waiting' : 'Filled')),
            })
          }
        }
      }
    }

    // Sort the orders by price
    _orders.sort((a, b) => (a.price > b.price) ? 1 : -1)

    // Find low value:
    const _low = Math.min(..._orders.map((o) => o.price))
    // Find high value:
    const _high = Math.max(..._orders.map((o) => o.price))

    let _CurrentPricePositionIndex = 0
    // step by every _orders for the positions in percent
    for (let i = 0; i < _orders.length; i++) {
      // get the percent between the current price and _high and _low
      let _position = 0
      if (_high !== _low) {
        _position = ((_high - _orders[i].price) / (_high - _low) * 100)
      }
      _orders[i].position = _position
      if (_orders[i].name === 'Current') {
        _CurrentPricePositionIndex = i
      }
    }

    return {
      current_price: _current_price,
      current_price_average: _current_price_average,
      current_unrealized_profit: _current_unrealized_profit,
      current_price_color: _current_price_color,
      current_price_percent: _current_price_percent + '%',
      orders: _orders,
      current_price_position_index: _CurrentPricePositionIndex,
    }
  }

  // const [widgetData, setwidgetData] = useFetcher(
  //   `smartbot/${id}/cryptowidget`,
  //   'GET',
  //   sanitizeWidgetData,
  // )
  let [widgetData, setwidgetData] = useState(null)
  const globalState = useContext(GlobalContext)

  const refreshWidgetData = () => {
    const apiHost = process.env.REACT_APP_API_URI
    let resource = `${apiHost}/smartbot/${id}/cryptowidget`
    if (backtesterid) {
      resource = `${apiHost}/backtest/${backtesterid}/smartbot/${id}/cryptowidget`
    }

    let fetchOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState?.token)}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }

    fetch(resource, fetchOptions)
      .then(res => res.json())
      .then(
        (result) => {
          // console.log('result', result)
          setwidgetData(sanitizeWidgetData(result))
        },
        (error) => {
          console.log('error', error)
        }
      )
  }

  useEffect(() => {
    refreshWidgetData()
  }, [])

  // Find the position of the current price and average price
  let currentPercentValue = 0
  let averagePercentValue = 0
  if (widgetData && widgetData.orders) {
    widgetData.orders.map((order, index) => {
      if (order.name === 'Current') {
        currentPercentValue = order.position
        // console.log('order', order)
      }
      if (order.name === 'Average') {
        averagePercentValue = order.position
        // console.log('order', order)
      }
    })
  }

  let cryptoWidgetSelection = (<div className="crypto-widget-selection"></div>)
  if (currentPercentValue > 0 && averagePercentValue > 0) {
    if (currentPercentValue > averagePercentValue) {
      // Red
      cryptoWidgetSelection = (<div className="crypto-widget-selection" style={{'inset': '0px ' + (averagePercentValue) + '% 0px ' + (100-currentPercentValue) + '%', 'position': 'absolute', 'height': '5px', 'backgroundColor': widgetData.current_price_color}}></div>)
    } else {
      // Green
      cryptoWidgetSelection = (<div className="crypto-widget-selection" style={{
        'inset': '0px ' + currentPercentValue + '% 0px ' + (100 - averagePercentValue) + '%',
        'position': 'absolute',
        'height': '5px',
        'backgroundColor': widgetData.current_price_color
      }}></div>)
    }
  }

  // console.log('widgetData', widgetData)
  let CountTopOrders = 0
  let CountBottomOrders = 0

  function reloadWidget() {
    // reload setwidgetData
    setwidgetData(!widgetData)
  }

  return (
    widgetData && (
    <div className="crypto-widget-place" onClick={refreshWidgetData}>
      <div className="crypto-widget" style={{'height': '117px', 'marginTop': '5px', 'minWidth': '150px', 'minHeight': '75px', 'position': 'relative'}}>
        <div className="crypto-widget-chart" style={{'position': 'absolute', 'width': '100%', 'left': '0px', 'right': '0px', 'height': '5px', 'top': '47px', 'backgroundColor': 'rgb(226, 226, 226)'}}>
          {cryptoWidgetSelection}
          {/*<div className="crypto-widget-selection" style={{'inset': '0px 48.9749% 0px 9.79499%', 'position': 'absolute', 'height': '5px', 'background-color': widgetData.current_price_color}}></div>*/}
          {/*<div className="crypto-widget-selection" style={{'inset': '0px 48.9749% 0px 9.79499%', 'position': 'absolute', 'height': '5px', 'background-color': 'rgb(237, 85, 101)'}}></div>*/}
        </div>

        {/*/!*Current Price*!/*/}
        {/*<div style={{'left': '51.0251%', 'position': 'absolute', 'top': '18px', 'background-color': widgetData.current_price_color, 'font-size': '9px', 'line-height': '25px', 'width': '1px', 'height': '34px'}}></div>*/}
        {/*<div style={{'right': '48.9749%', 'left': 'auto', 'position': 'absolute', 'top': '0px', 'white-space': 'nowrap'}}>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'font-weight': 'bold', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': widgetData.current_price_color}}>{widgetData.current_price_percent}</div>*/}
        {/*  /!*<div style={{'display': 'inline-block', 'vertical-align': 'top', 'font-weight': 'bold', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(26, 179, 148)'}}>Current</div>*!/*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'margin-left': '3px', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>{widgetData.current_price}</div>*/}
        {/*</div>*/}

        {/*/!*Average Price*!/*/}
        {/*<div style={{'left': '54.6697%', 'position': 'absolute', 'top': '30px', 'background-color': 'rgb(0, 120, 181)', 'font-size': '9px', 'line-height': '25px', 'width': '1px', 'height': '22px'}}></div>*/}
        {/*<div style={{'right': '45.3303%', 'left': 'auto', 'position': 'absolute', 'top': '12px', 'white-space': 'nowrap'}}>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'font-weight': 'bold', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(0, 120, 181)'}}>Average</div>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'margin-left': '3px', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>{widgetData.current_price_average}</div>*/}
        {/*</div>*/}

        {/*<div style={{'left': '54.6697%', 'position': 'absolute', 'top': '30px', 'background-color': 'rgb(0, 120, 181)', 'font-size': '9px', 'line-height': '25px', 'width': '1px', 'height': '22px'}}></div>*/}
        {/*<div style={{'right': '45.3303%', 'left': 'auto', 'position': 'absolute', 'top': '12px', 'white-space': 'nowrap'}}>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'font-weight': 'bold', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(0, 120, 181)'}}>Wait</div>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'margin-left': '3px', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>0.0997</div>*/}
        {/*</div>*/}

        {/*Display all orders*/}

        {widgetData.orders.map((order) => {
          // Ignore all filled orders with so_ in the name:
          if (order.name.match(/so_/gi) && order.text === 'Filled') {
            return null
          }

          // Reset Counters for Top and Bottom Orders (3 max)
          if (CountTopOrders > 3) {
            CountTopOrders = 0
          }
          if (CountBottomOrders > 3) {
            CountBottomOrders = 0
          }
          // Display Text:
          let _display_text = order.text + ' ' + order.name

          let LeftSide = false
          if (order.price <= widgetData.current_price) {
            LeftSide = true
          }

          // Color
          let _color = 'rgb(0,85,128)'
          if (order.name.match(/tp_/gi)) {
            // Take Profit
            _color = 'rgb(26, 179, 148)'
            // Filled check
            if (order.text === 'Filled' || order.text === 'Canceled') {
              _color = 'rgb(17,119,100)'
            }
            CountBottomOrders++
          } else if (order.name.match(/tso_/gi)) {
            _color = 'rgb(0, 120, 181)'
            CountTopOrders++
          } else if (order.name.match(/sl_/gi)) {
            // Stop Loss
            _color = 'rgb(237, 85, 101)'
            CountBottomOrders++
          } else if (order.name.match(/Average/gi)) {
            // Average
            _color = 'rgb(0, 120, 181)'
            _display_text = 'Avg'
            CountBottomOrders++
          } else if (order.name.match(/Current/gi)) {
            // Current
            _color = widgetData.current_price_color
            _display_text = widgetData.current_price_percent + ' ' + parseFloat(widgetData.current_unrealized_profit ?? 0).toFixed(8)
            CountTopOrders++
          } else {
            CountTopOrders++
          }

          // Top or Bottom
          // let _text_top = '24px'
          // let _line_hight = '10px'
          let _text_top = (38 - (CountTopOrders * 12)).toFixed(4) + 'px'
          let _line_hight = (14 + ((CountTopOrders-1) * 12)).toFixed(4) + 'px'
          let _line_top = (56 - (CountTopOrders * 12)).toFixed(4) + 'px'
          if (order.name.match(/tp_/gi) || order.name.match(/sl_/gi) || order.name.match(/Average/gi)) {
            // _text_top = '63.5px'
            // _line_hight = '22px' // always +12px
            _text_top = (47.5 + (CountBottomOrders * 12)).toFixed(4) + 'px'
            _line_hight = (10 + (CountBottomOrders * 12)).toFixed(4) + 'px'
            _line_top = '42px'
          }


          return (
            <div key={order.id}>
              {LeftSide ? (
                <div>
                  <div style={{'left': (100 - order.position).toFixed(4) + '%', 'position': 'absolute', 'top': _line_top, 'backgroundColor': _color, 'fontSize': '9px', 'lineHeight': '25px', 'width': '1px', 'height': _line_hight}}></div>
                  <div style={{'left': (100 - order.position).toFixed(4) + '%', 'right': 'auto', 'position': 'absolute', 'top': _text_top, 'whiteSpace': 'nowrap'}}>
                    <div style={{'display': 'inline-block', 'verticalAlign': 'top', 'fontWeight': 'bold', 'fontSize': '10px', 'lineHeight': '25px', 'height': '25px', 'color': _color}}>{_display_text}</div>
                    <div style={{'display': 'inline-block', 'verticalAlign': 'top', 'marginLeft': '3px', 'fontSize': '10px', 'lineHeight': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>{order.price}</div>
                  </div>
                </div>
              ) : (
                <div>
                  <div style={{'left': (100 - order.position).toFixed(4) + '%', 'position': 'absolute', 'top': _line_top, 'backgroundColor': _color, 'fontSize': '9px', 'lineHeight': '25px', 'width': '1px', 'height': _line_hight}}></div>
                  <div style={{'right': (order.position).toFixed(4) + '%', 'left': 'auto', 'position': 'absolute', 'top': _text_top, 'whiteSpace': 'nowrap'}}>
                    <div style={{'display': 'inline-block', 'verticalAlign': 'top', 'fontWeight': 'bold', 'fontSize': '10px', 'lineHeight': '25px', 'height': '25px', 'color': _color}}>{_display_text}</div>
                    <div style={{'display': 'inline-block', 'verticalAlign': 'top', 'marginLeft': '3px', 'fontSize': '10px', 'lineHeight': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>{order.price}</div>
                  </div>
                </div>
              )}
            </div>
          )

        })}

        {/*<div style={{'left': '0%', 'position': 'absolute', 'top': '47px', 'background-color': 'rgb(237, 85, 101)', 'font-size': '9px', 'line-height': '25px', 'width': '1px', 'height': '10px'}}></div>*/}
        {/*<div style={{'left': '0%', 'right': 'auto', 'position': 'absolute', 'top': '51.5px', 'white-space': 'nowrap'}}>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'font-weight': 'bold', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(237, 85, 101)'}}>TSL</div>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'margin-left': '3px', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>0.0757</div>*/}
        {/*</div>*/}

        {/*<div style={{'left': '77.221%', 'position': 'absolute', 'top': '47px', 'background-color': 'rgb(26, 179, 148)', 'font-size': '9px', 'line-height': '25px', 'width': '1px', 'height': '22px'}}></div>*/}
        {/*<div style={{'left': 'auto', 'right': '22.779%', 'position': 'absolute', 'top': '63.5px', 'white-space': 'nowrap'}}>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'font-weight': 'bold', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(26, 179, 148)'}}>TP</div>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'margin-left': '3px', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>0.1096</div>*/}
        {/*</div>*/}

        {/*<div style={{'left': '100%', 'position': 'absolute', 'top': '47px', 'background-color': 'rgb(26, 179, 148)', 'font-size': '9px', 'line-height': '25px', 'width': '1px', 'height': '34px'}}></div>*/}
        {/*<div style={{'left': 'auto', 'right': '0%', 'position': 'absolute', 'top': '75.5px', 'white-space': 'nowrap'}}>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'font-weight': 'bold', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(26, 179, 148)'}}>TTP</div>*/}
        {/*  <div style={{'display': 'inline-block', 'vertical-align': 'top', 'margin-left': '3px', 'font-size': '10px', 'line-height': '25px', 'height': '25px', 'color': 'rgb(110, 107, 108)'}}>0.1196</div>*/}
        {/*</div>*/}

      </div>
    </div>
    )
  )
}

CryptoWidget.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  fetchdata: PropTypes.bool,
  // label: PropTypes.string,
  // checked: PropTypes.bool,
  // disabled: PropTypes.bool,
  // onChange: PropTypes.func,
  // isFieldsetSwitch: PropTypes.bool,
}

export default CryptoWidget
