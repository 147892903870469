import hmacSHA256 from 'crypto-js/hmac-sha256';

const useLogin = () => async (formData) => {
  formData.append('hash', process.env.REACT_APP_LOGIN_HASH);
  formData.set(
    'pass',
    hmacSHA256(
      formData.get('pass'),
      process.env.REACT_APP_LOGIN_HASH
    ).toString()
  );
  // with try catch block:
  try {
  const response = await fetch(`${process.env.REACT_APP_API_URI}/login`, {
    method: 'POST',
    body: formData,
  });
  return await response.json();
  } catch (error) {
    throw new Error(`Login request error: ${error.status}`);
  }
};

export default useLogin;
