import React, {useContext, useEffect, useMemo, useState} from 'react'
import {GlobalContext} from '../../../state'
import 'react-tabulator/lib/styles.css' // required styles
import 'react-tabulator/lib/css/tabulator_midnight.min.css' // theme
import tokenFromLS from '../../../utils/tokenFromLS'
import {useNavigate} from "react-router-dom";
import {useModal} from "../../Modal/Modal";
import tokenfromLS from "../../../utils/tokenFromLS";
import {sanitizeGridbotData} from "../../../utils/sanatizer";
import {Box, Button, darken, lighten, ListItemIcon, MenuItem, ThemeProvider, useTheme} from "@mui/material";
import {darkTheme} from "../../Layout/Layout";
import {Delete, Edit, PlayArrow, StopSharp} from "@mui/icons-material";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable
} from "material-react-table";


const GridbotPage = ({mainContainerHeight}) => {
  const {openModal} = useModal();

  // Global state
  const globalState = useContext(GlobalContext)
  // API Host Address
  const apiHost = process.env.REACT_APP_API_URI
  // Resource URI
  const resource = `${process.env.REACT_APP_API_URI}/gridbot`

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [refreshData, setRefreshData] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const navigate = useNavigate();

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        resource,
        apiHost,
      );

      const newSorting = sorting.map((sort) => {
        // Return:
        // { fieldname: 'asc', fieldname2: 'desc' }
        return {
          [sort.id]: sort.desc ? 'desc' : 'asc',
        };
      });
      const newFilters = columnFilters.map((filter) => {
        // Return:
        // { fieldname: 'value' }
        return {
          variable: filter.id,
          value: filter.value,
          operator: 'like',
        };
      });
      const bodydata = {
        page: pagination.pageIndex,
        per_page: pagination.pageSize,
        filter: newFilters ?? [],
        sort: newSorting,
      }
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(bodydata),
        headers: {
          Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        }
      }

      try {
        const response = await fetch(url.href, fetchOptions);
        const json = await response.json();
        setData(sanitizeGridbotData(json.data));
        setRowCount(json.maxcount ?? 0);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
      setRefreshData(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refreshData
  ]);

  const PushButtonStartStop = async (id, start, withModal = true) => {
    try {
      let RequestURL = ``
      let Method = 'GET'
      if (start) {
        RequestURL = `${process.env.REACT_APP_API_URI}/gridbot/${id}/start`
      } else {
        RequestURL = `${process.env.REACT_APP_API_URI}/gridbot/${id}/stop`
      }
      // console.log('PushButtonStartStop 1', id, start)
      const response = await fetch(RequestURL, {
        method: Method,
        headers: {
          Authorization: `Bearer ${tokenfromLS()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      // console.log('PushButtonStartStop 2')

      const json = await response.json()
      if (json.hasOwnProperty('ok')) {
        if (withModal) {
          openModal({
            content: json.message ?? 'Bot successfully ' + (start ? 'started' : 'stopped') + '.',
            showSubmitButton: false,
            showDismissButton: true,
            dismissButtonTxt: 'Close',
            showFooter: true,
          })
          setRefreshData(true);
        } else {
          return true
        }
      } else {
        if (withModal) {
          openModal({
            content: json.message ?? 'An error occurred while ' + (start ? 'starting' : 'stopping') + ' the bot. Please try again later.',
            showSubmitButton: false,
            showDismissButton: true,
            dismissButtonTxt: 'Close',
            showFooter: true,
          })
        } else {
          return false
        }
      }
    } catch (err) {
      if (withModal) {
        openModal({
          content: 'An error occurred while ' + (start ? 'starting' : 'stopping') + ' the bot. Please try again later. Error: ' + err,
          showSubmitButton: false,
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        })
      } else {
        return false
      }
    }
  }

  const submitModalDelete = async (row, showModal = true) => {
    if (!row) {
      return
    }

    const id = row?.id
    if (!id) {
      return
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/gridbot/${id}`
      const response = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokenfromLS()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      const json = await response.json()
      if (json.status === 'ok') {
        // Refresh data
        setRefreshData(true)

      } else {
        if (showModal) {
          openModal({
            content: json.message ?? 'An error occurred while deleting the bot. Please try again later.',
            showSubmitButton: false,
            showDismissButton: true,
            dismissButtonTxt: 'Close',
            showFooter: true,
          })
        }
        return false
      }
    } catch (err) {
      if (showModal) {
        openModal({
          content: 'An error occurred while deleting the bot. Please try again later. Error: ' + err,
          showSubmitButton: false,
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        })
      }
      return false
    }
  }

  const GridbotTable = () => {
    const {openModal} = useModal();
    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: '_id',
          header: 'ID',
          size: 0,
          columnDefType: 'display'
        },
        {
          accessorKey: 'name',
          header: 'Name',
          size: 150,
        },
        {
          accessorKey: 'account_name',
          header: 'Account',
          size: 150,
        },
        {
          accessorKey: 'type',
          header: 'Type',
          size: 50,
        },
        {
          accessorKey: 'symbol',
          header: 'Symbol',
          size: 200,
        },
        {
          accessorKey: 'state',
          header: 'Status',
          size: 30,
        },
        {
          accessorKey: 'stats.so',
          header: 'SO Count',
          size: 30,
        },
        {
          accessorKey: 'completeprofit.profit_percent',
          header: 'Profit %',
          size: 30,
          Cell: ({cell}) => {
            const value = cell.getValue()
            if (value > 0) {
              return <span style={{color: 'green'}}>{value}</span>
            } else if (value < 0) {
              return <span style={{color: 'red'}}>{value}</span>
            } else {
              return <span>{value}</span>
            }
          }
        },
        {
          accessorKey: 'completeprofit.profitUSD',
          header: 'Profit USD',
          size: 30,
          Cell: ({cell}) => {
            const value = cell.getValue().toFixed(4)
            if (value > 0) {
              return <span style={{color: 'green'}}>{value}</span>
            } else if (value < 0) {
              return <span style={{color: 'red'}}>{value}</span>
            } else {
              return <span>{value}</span>
            }
          }
        },
        {
          accessorKey: 'diff.price_min',
          header: 'Price Min',
          size: 30,
        },
        {
          accessorKey: 'store.last',
          header: 'Price Current',
          size: 30,
          Cell: ({cell}) => {
            const value = cell.getValue()
            const minvalue = cell.getValue('diff.price_min')
            const maxvalue = cell.getValue('diff.price_max')
            let color = false

            if (minvalue > value) {
              color = true
            }
            if (maxvalue < value) {
              color = true
            }

            if (color) {
              cell.getElement().style.color = 'red'
              cell.getElement().style.backgroundColor = 'yellow'
            }
            return value
          }
        },
        {
          accessorKey: 'diff.price_max',
          header: 'Price Max',
          size: 30,
        },
        {
          accessorKey: 'amount',
          header: 'Amount',
          size: 30,
        },
      ],
      [],
    );

    const theme = useTheme();
    theme.palette.mode = 'dark';

    //light or dark green
    const baseBackgroundColor = 'rgb(57,64,74)';
    // const baseBackgroundColor =
    //   theme.palette.mode === 'dark'
    //     ? 'rgb(34,34,34)'
    //     : 'rgb(255,255,255)';

    const table = useMaterialReactTable({
      columns,
      data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      initialState: {
        columnVisibility: {_id: false},
        columnPinning: {
          left: ['mrt-row-expand', 'mrt-row-select'],
          right: ['mrt-row-actions'],
        },
        density: 'compact',
      },
      enableRowSelection: true,
      enableGlobalFilter: false,
      enableRowActions: true,
      enableExpandAll: false,
      getRowId: (row) => row._id,
      manualFiltering: true,
      manualPagination: true,
      manualSorting: true,
      muiToolbarAlertBannerProps: isError
        ? {
          color: 'error',
          children: 'Error loading data',
        }
        : undefined,
      onColumnFiltersChange: setColumnFilters,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      rowCount,
      state: {
        columnFilters,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      },

      muiCircularProgressProps: {
        color: 'secondary',
        thickness: 5,
        size: 55,
      },
      muiSkeletonProps: {
        animation: 'pulse',
        height: 28,
      },

      muiTableBodyProps: {
        sx: (theme) => ({
          '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.1),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.2),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
              backgroundColor: lighten(baseBackgroundColor, 0.1),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.2),
              color: '#e1eeff',
            },
        }),
      },
      mrtTheme: (theme) => ({
        baseBackgroundColor: baseBackgroundColor,
        draggingBorderColor: theme.palette.secondary.main,
      }),



      renderTopToolbar: ({table}) => {
        const handleStop = () => {
          let RefreshTable = false
          table.getSelectedRowModel().flatRows.map((row) => {
            PushButtonStartStop(row.id, false, false).then(r => {
              if (r) {
                RefreshTable = true
              }
            });
          });
          if (RefreshTable) {
            setRefreshData(true)
          }
        };

        const handleStart = () => {
          let RefreshTable = false
          table.getSelectedRowModel().flatRows.map((row) => {
            PushButtonStartStop(row.id, true, false).then(r => {
              if (r) {
                RefreshTable = true
              }
            });
          });
          if (RefreshTable) {
            setRefreshData(true)
          }
        };

        const handleInsert = () => {
          navigate('/gridbot/add');
        };

        const handleDelete = () => {

          openModal({
            content: 'Do you really want to delete the selected bots?',
            showSubmitButton: true,
            showDismissButton: true,
            showFooter: true,
            submitButtonTxt: 'Delete',
            submitFn: () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                submitModalDelete(row, false)
              });
              setRefreshData(true)
              openModal({
                content: 'Selected bots have been deleted.',
                showSubmitButton: false,
                showDismissButton: true,
                dismissButtonTxt: 'Close',
                showFooter: true,

              })
            },
          })
        }

        return (
          <Box
            sx={(theme) => ({
              backgroundColor: lighten(theme.palette.background.default, 0.05),
              display: 'flex',
              gap: '0.5rem',
              p: '8px',
              justifyContent: 'space-between',
            })}
          >
            <Box>
              <Box sx={{display: 'flex', gap: '0.5rem'}}>
                <Button
                  color="success"
                  disabled={
                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                  }
                  onClick={handleStart}
                  variant="contained"
                >
                  Start
                </Button>
                <Button
                  color="error"
                  disabled={
                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                  }
                  onClick={handleStop}
                  variant="contained"
                >
                  Stop
                </Button>
                <Button
                  color="info"
                  // disabled={
                  //   table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
                  // }
                  onClick={handleInsert}
                  variant="contained"
                >
                  Insert
                </Button>
                <Button
                  color="info"
                  disabled={
                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                  }
                  onClick={handleDelete}
                  variant="contained"
                >
                  Delete
                </Button>
              </Box>
            </Box>
            <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
              {/* import MRT sub-components */}
              <MRT_GlobalFilterTextField table={table}/>
              <MRT_ToggleFiltersButton table={table}/>
              <MRT_ShowHideColumnsButton table={table}/>
            </Box>
          </Box>
        );
      },
      renderRowActionMenuItems: ({closeMenu, row, table}) => [
        <MenuItem
          key={0}
          onClick={(evt) => {
            closeMenu();
            const isRunning = row.getValue('state') && row.getValue('state').includes('running');
            openModal({
              content: 'Do you really want to ' + (isRunning ? 'stop' : 'start') + ' the bot "' + row.getValue('name') + '"?',
              showSubmitButton: true,
              showDismissButton: true,
              showFooter: true,
              submitButtonTxt: isRunning ? 'Stop' : 'Start',
              submitFn: () => {
                PushButtonStartStop(row.id, !isRunning)
              },
            })
          }}
          sx={{m: 0}}
        >
          <ListItemIcon>
            {row.getValue('state') && row.getValue('state').includes('running') ? <StopSharp/> : <PlayArrow/>}
          </ListItemIcon>
          {row.original.state && row.original.state.includes('running') ? 'Stop' : 'Start'}
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            closeMenu();
            navigate('/smartbot/' + row.id);
          }}
          sx={{m: 0}}
        >
          <ListItemIcon>
            <Edit/>
          </ListItemIcon>
          Edit
        </MenuItem>,
        <MenuItem
          key={2}
          onClick={() => {
            openModal({
              content: 'Do you really want to delete the bot ' + row.getValue('name') + ' (' + row.getValue('symbol') + ')' + '?',
              showSubmitButton: true,
              showDismissButton: true,
              showFooter: true,
              submitButtonTxt: 'Delete',
              submitFn: () => {
                submitModalDelete(row)
              },
            })
            closeMenu();
          }}
          sx={{m: 0}}
        >
          <ListItemIcon>
            <Delete/>
          </ListItemIcon>
          Delete
        </MenuItem>,
      ],
      muiTableBodyCellProps: ({cell}) => ({
        onClick: (event) => {
          if (cell.column.id === 'name') {
            navigate('/gridbot/' + cell.row.original._id);
          }

        },
        onDoubleClick: (event) => {
          if (cell.column.id === 'name') {
            navigate('/gridbot/' + cell.row.original._id);
          }
        },
        // Only on field name:
        style: {
          cursor: cell.column.id === 'name' ? 'pointer' : 'inherit',
        },
      }),
    });

    return (
      <MaterialReactTable table={table}/>
    );
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <div>
          <GridbotTable/>
        </div>
      </ThemeProvider>
    </React.Fragment>

  )
}

export default GridbotPage
