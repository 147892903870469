// import GridLayout from "react-grid-layout";
import { Responsive, WidthProvider } from "react-grid-layout";
import React, {useState} from "react";
import {CBElementUserVoucher, CBElementUserFee, CBElementUserPayment} from "./CBElementUser";
import {CBElementAccountBalance, CBElementAccountProfit} from "./CBElementAccount";
import {CBElementSmartbotAccumulativeProfit, CBElementSmartbotLastTrades} from "./CBElementSmartbot";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const CBElement = React.forwardRef(({ ElementName, Data, onMouseDown, onMouseUp, onTouchEnd }, ref) => {
  return (
    <div>
      {ElementName === 'account-balance' ? <CBElementAccountBalance ref={ref} Data={Data} /> : null}
      {ElementName === 'account-profit' ? <CBElementAccountProfit ref={ref} Data={Data} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd} /> : null}
      {ElementName === 'smartbot-accumulativeprofit' ? <CBElementSmartbotAccumulativeProfit ref={ref} Data={Data} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd} /> : null}
      {ElementName === 'smartbot-lasttrade' ? <CBElementSmartbotLastTrades ref={ref} Data={Data} /> : null}
      {ElementName === 'user-fee' ? <CBElementUserFee ref={ref} Data={Data} /> : null}
      {ElementName === 'user-payment' ? <CBElementUserPayment ref={ref} Data={Data} /> : null}
      {ElementName === 'user-voucher' ? <CBElementUserVoucher ref={ref} Data={Data} /> : null}

    </div>
  )
})

const CBElements = ({ GridLayoutParameter, ElementNames, Prefix, DefaultGridLayout, Data }) => {
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg')
  const [currentLayout, setCurrentLayout] = useState(JSON.parse(localStorage.getItem(Prefix + 'CBGridLayout')) ?? DefaultGridLayout ?? [])

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint)
  }

  const onLayoutChange = (layout, layouts) => {
    // store Layout:
    currentLayout[currentBreakpoint] = layout
    storeGridLayout(currentLayout)
  }

  const handleContextMenu = (event) => {
    event.preventDefault();

    setContextMenu({
      visible: true,
      x: event.pageX,
      y: event.pageY
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({visible: false, x: 0, y: 0});
  };

  function storeGridLayout(Layout) {
    if (Layout !== undefined) {
      localStorage.setItem(Prefix + 'CBGridLayout', JSON.stringify(Layout))
    }
  }

  // function to reset the style
  function resetStyle() {
    // Reset the Layout
    localStorage.setItem(Prefix + 'CBGridLayout', JSON.stringify(DefaultGridLayout));
    setCurrentLayout(DefaultGridLayout)
  }

  const optionStyle = {
    cursor: 'pointer',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  };

  const cols = {
    // lg: 24, md: 20, sm: 16, xs: 12, xxs: 8
    // lg: 12, md: 12, sm: 12, xs: 12, xxs: 12
    lg: 24, md: 20, sm: 12, xs: 8, xxs: 4
  }

  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }

  // const onResizeStopHandler = (layout, oldItem, newItem, placeholder, e, element) => {
  //   console.log('onResizeStop')
  //   // console.log(layout)
  //   // console.log(oldItem)
  //   // console.log(newItem)
  //   // console.log(placeholder)
  //   // console.log(e)
  //   // console.log(element)
  // }

  // Move over all Elements and write the GridElement Header
  return (
    <div onContextMenu={handleContextMenu} onClick={handleCloseContextMenu}>
      {contextMenu.visible && (
        <div
          style={{
            position: 'fixed',
            top: contextMenu.y,
            left: contextMenu.x,
            zIndex: 1000,
            background: 'black',
            border: '1px solid black',
            fontSize: '1.0rem',
          }}
        >
          <p style={optionStyle} onClick={resetStyle}>Reset style</p>
          {/*<p style={optionStyle}>Option 2</p>*/}
          {/*<p style={optionStyle}>Option 3</p>*/}
        </div>
      )}
      <ResponsiveReactGridLayout
        draggableHandle=".cb-element-draggable-header"
        // measureBeforeMount={false}
        className={Prefix + "layout"}
        layouts={currentLayout}
        cols={GridLayoutParameter.cols ?? cols}
        // rowHeight={GridLayoutParameter.rowHeight ?? 200}
        // containerPadding={[5,5]}
        // margin={[5,5]}
        // breakpoints={GridLayoutParameter.breakpoints ?? breakpoints}
        breakpoints={breakpoints}

        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        // onResizeStop={onResizeStopHandler}
        // compactType="vertical"
    >
      {ElementNames.map((ElementName) => (

        // Load different Elements here and return them
        <div key={ElementName} className="cb-element-main">
          <CBElement key={ElementName + 'CBElements'} ElementName={ElementName} Data={Data} />
        </div>

      ))}
    </ResponsiveReactGridLayout>
      {/*<div style={{clear: 'both'}} >*/}
      {/*Current Breakpoint: {currentBreakpoint} ({cols[currentBreakpoint]} columns)*/}
      {/*<br/>*/}
      {/*Layout: {JSON.stringify(currentLayout[currentBreakpoint])}*/}
      {/*</div>*/}
    </div>
  );
}

export default CBElements;