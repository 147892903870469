import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../state'
import tokenFromLS from '../utils/tokenFromLS'

function useFetchAccount(id) {
  const globalState = useContext(GlobalContext)
  const [accountData, setAccountData] = useState([])
  useEffect(() => {
    const apiHost = process.env.REACT_APP_API_URI
    let resource = `${apiHost}/account`
    if (id === 'add') {
      return [];
    }
    if (id) {
      resource = `${apiHost}/account/${id}`
    }
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const json = await response.json()
        setAccountData(json.data)
      } catch (error) {
        // console.log('error', error)
      }
    }
    fetchData()
  }, [])
  return [accountData, setAccountData]
}

function buildAccountOptions(arr, api = '', noapi = '', type = 'api') {
  /**
   * @param {array} arr - array of accounts
   * @param {string} api - api name (or type) to filter on
   * @param {string} noapi - api name to exclude
   * @param {string} type - api or type filter
   * @returns {array} - array of filtered accounts
   */
  if (arr) {
    let newArray = arr
    if (type === 'api') {
      if (typeof api === 'string' && api.length > 0) {
        newArray = arr.filter(function (item) {
          return item.api === api;
        })
      } else if (typeof api === 'object' && api.length > 0) {
        console.log('array', arr)
        newArray = arr.filter(function (item) {
          return api.includes(item.api);
        })
      } else if (typeof noapi === 'string' && api.length > 0) {
        newArray = arr.filter(function (item) {
          return item.api !== noapi;
        })
      }
    } else  {
      if (typeof api === 'string' && api.length > 0) {
        newArray = arr.filter(function (item) {
          return item.type === api;
        })
      } else if (typeof api === 'object' && api.length > 0) {
        newArray = arr.filter(function (item) {
          return api.includes(item.type);
        })
      } else if (typeof noapi === 'string' && api.length > 0) {
        newArray = arr.filter(function (item) {
          return item.type !== noapi;
        })
      }
    }

    newArray = newArray.map((item) =>
      ({
        name: item.name,
        value: item._id,
      }),
    )
    return newArray
  }
  return []
}

function getExchangeFromAccount(accounts, account_id, responseType = 'api') {
  // console.log('getExchangeFromAccount', accounts, account_id)
  let ReturnData = ''
  if (accounts && account_id) {

    accounts.forEach((item) => {
      if (item._id === account_id) {
        if (responseType === 'api') {
          ReturnData = item.api
        } else {
          ReturnData = item.type
        }
      }
    })
  }
  return ReturnData
}

export { useFetchAccount, buildAccountOptions, getExchangeFromAccount }
