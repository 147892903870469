import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {state as globalState} from "../../state";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import {useQuery} from "react-query";
import tokenfromLS from "../../utils/tokenFromLS";
import CanvasJSReact from "@canvasjs/react-charts";
import CBApi from "../../utils/datafeed/CBApi";

const CBElementAccountBalance = React.forwardRef((props, ref) => {
  // Fetch options
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
    },
  }

  const resource_stats_balance_current = `${process.env.REACT_APP_API_URI}/account/stats/balance_current`
  const fetchAccount_stats_balance_current = () =>
    fetch(resource_stats_balance_current, fetchOptions)
      .then((res) => res.json())
      .then((out) => {
        return out
      })

  const { data: data_stats_balance_current } = useQuery(
    'account_stats_balance_current',
    fetchAccount_stats_balance_current,
    // { refetchInterval: 60000 }
  )

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chartdiv_stats_account_balance')) {
      return
    }

    let root_stats_balance_current = am5.Root.new('chartdiv_stats_account_balance')

    root_stats_balance_current.setThemes([am5themes_Animated.new(root_stats_balance_current)])

    let chart = root_stats_balance_current.container.children.push(
      am5percent.PieChart.new(root_stats_balance_current, {
        endAngle: 270,
      })
    );

    let series = chart.series.push(am5percent.PieSeries.new(root_stats_balance_current, {
      valueField: "value",
      categoryField: "category",
      innerRadius: am5.percent(0),
      outerRadius: am5.percent(80),
      legendLabelText: "[{fill}]{category}[/]",
      legendValueText: "[bold]{valuePercentTotal.formatNumber('0.00')}%[/]",
      // legendValueText: "[bold {fill}]{value}[/]",
      alignLabels: false,
      tooltip: am5.Tooltip.new(root_stats_balance_current, {
        pointerOrientation: "vertical",
        labelText: "[bold]{category}[/]\nPercentage: [bold]{valuePercentTotal.formatNumber('0.00')}%[/]\nAmount: {valueBase}\nUSD: {calcUSD.formatNumber('$#.##')}",
        fontColor: "#000000",
        fontSize: 12,
        fontWeight: "bold"
      })
    }));

    series.labels.template.adapters.add("y", function(y, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        var tick = dataItem.get("tick");
        if (dataItem.get("valuePercentTotal") < 5) {
          target.set("forceHidden", true);
          tick.set("forceHidden", true);
        } else {
          target.set("forceHidden", false);
          tick.set("forceHidden", true);
        }
        return y;
      }
    });

    // if data_stats_balance_current.calcUSD = 0 then show "nothing"
    if (data_stats_balance_current && data_stats_balance_current.calcUSD && data_stats_balance_current.calcUSD !== 0.0) {
      // Move over all subaccounts:
      let SubAccounts = Object.keys(data_stats_balance_current.data)
      let data = []
      for (let i = 0; i < SubAccounts.length; i++) {
        // Lets move over all coins:
        let Coins = Object.keys(data_stats_balance_current.data[SubAccounts[i]])
        for (let j = 0; j < Coins.length; j++) {
          // Ignore all Coins without "calcUSD" in the name:
          if (Coins[j].indexOf('calcUSD') === -1) {
            continue
          }
          let CoinName = Coins[j].slice(0, -7)
          if (CoinName === '') {
            continue
          }
          let dataEntry = {
            // category name without "calcUSD":
            category: CoinName,
            value: data_stats_balance_current.data[SubAccounts[i]][Coins[j]],
            valueBase: data_stats_balance_current.data[SubAccounts[i]][CoinName],
            calcUSD: data_stats_balance_current.data[SubAccounts[i]][CoinName + 'calcUSD'],
          }
          data.push(dataEntry)
        }
      }

      // Sort data by calcUSD:
      data.sort(function(a, b) {
        return b.calcUSD - a.calcUSD;
      })
      // Filter data to show only > 0.01$
      data = data.filter(function (el) {
        return el.calcUSD > 0.02;
      })

      series.data.setAll(data)

    } else {
      series.data.setAll([
        {category: "Nothing", value: 100.0},
      ]);
    }

    // series.labels.template.set("visible", false);
    // series.ticks.template.set("visible", false);
    series.labels.template.setAll({
      radius: 60,
      inside: true,
    })

    // Legend
    // let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {
    //   // Right align legend to chart's center
    //   width: am5.p100,
    //   paddingBottom: 15,
    //   align: "right",
    //   orientation: "vertical",
    // }));
    // legend.data.setAll(series.dataItems);
    // console.log(series.dataItems)
    let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {

      }
    ));
    legend.data.setAll(series.dataItems);


    chart.appear(1000, 100)

    return () => {
      root_stats_balance_current.dispose()
    }
  }, [data_stats_balance_current])

  return (
    <div>
      <div draggable="true" className="cb-element-draggable-header">Balance Overview</div>
      <div className="cb-element-content">
        <div className="cb-element-user-fee">
          <div
            id="chartdiv_stats_account_balance"
            style={{width: '100%', height: '400px'}}
          ></div>
        </div>
      </div>
    </div>
  )
})

const CBElementAccountProfit = React.forwardRef(({Data, onMouseDown, onMouseUp, onTouchEnd}, ref) => {
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState([])
  const chartRef = useRef(null);
  const containerRef = useRef(null);

  let CanvasJSChart = CanvasJSReact.CanvasJSChart;
  let CanvasJS = CanvasJSReact.CanvasJS;

  const toggleDataSeries = (e) => {
    e.dataSeries.visible = !e.dataSeries.visible;
  };

  const options = {
    theme: "dark1",
    animationEnabled: true,
    exportEnabled: true,
    zoomEnabled: true,
    title: {
      text: "Daily Profit",
    },
    // axisX: {
    //   valueFormatString: "YYYY-MM-DD",
    // },
    axisY: {
      title: "USD"
    },
    toolTip: {
      shared: true,
      contentFormatter: function (e) {
        // Date in the first line with total sum of all series
        // All colors and names of the series with their values
        // Total sum of all series
        let total = 0;
        let content = "";
        for (let i = 0; i < e.entries.length; i++) {
          if (i === 0) {
            content += `<strong>${CanvasJS.formatDate(e.entries[i].dataPoint.x, "YYYY-MM-DD")}</strong><br>`;
          }
          content += `<span style="color: ${e.entries[i].dataSeries.color}">${e.entries[i].dataSeries.name}</span>: ${CanvasJS.formatNumber(e.entries[i].dataPoint.y, "#,##0.00 USD")}<br>`;
          total += e.entries[i].dataPoint.y;
        }
        content += `<strong>Total</strong>: ${CanvasJS.formatNumber(total, "#,##0.00 USD")}`;
        return content;
      }
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: chartData
  }

  const containerProps = {
    // height: "calc(100% - 60px)",
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let Resource = `account/stats/profit`

        const response = CBApi.fetch(Resource);
        response.then((data) => {
          if (data?.status === 'ok') {
            // Set raw data
            setData(data)

            // Sort data by exchange and date
            const sortData = data.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              if (a.date < b.date) {
                return -1;
              }
              if (a.date > b.date) {
                return 1;
              }
              return 0;
            });

            // Stack all data with name as key
            let newChartData = []
            let lastName = null
            let lastItem = null
            for (let i = 0; i < sortData.length; i++) {
              if (lastName === sortData[i].name) {
                lastItem.dataPoints.push({x: new Date(sortData[i].date), y: sortData[i].calcUSD})
              } else {
                if (lastItem) {
                  newChartData.push(lastItem)
                }
                lastItem = {
                  type: "stackedColumn",
                  name: sortData[i].name,
                  showInLegend: true,
                  xValueFormatString: "YYYY-MM-DD",
                  yValueFormatString: "#,##0.00 USD",
                  dataPoints: [{x: new Date(sortData[i].date), y: sortData[i].calcUSD}]
                }
                lastName = sortData[i].name
              }
            }
            if (lastItem) {
              newChartData.push(lastItem)
            }

            setChartData(newChartData);
          }
        })

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [Data]);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.render();
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div ref={containerRef}>
      <div draggable="true" className="cb-element-draggable-header">Profit Overview</div>
      <div className="cb-element-content">
        <div className="cb-element-account-profit">
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Today: ${data && data?.todayProfit}</span>
              <span>Yesterday: ${data && data?.yesterdayProfit}</span>
              <span>Difference: ${data && data?.differenceProfit}</span>
              <span>Avg Profit: ${data && data?.avgDailyProfit} (last 30 days)</span>
            </div>


            {/*Today: {data_stats_account_profit && data_stats_account_profit?.today ?? '0.0'}*/}
            {/*<br />*/}
            {/*Yesterday: {data_stats_account_profit && data_stats_account_profit?.yesterday ?? '0.0'}*/}
            {/*<br />*/}
            {/*This Week: {data_stats_account_profit && data_stats_account_profit?.this_week ?? '0.0'}*/}
            {/*<br />*/}
            {/*Last Week: {data_stats_account_profit && data_stats_account_profit?.last_week ?? '0.0'}*/}
            {/*<br />*/}
          </div>
          <CanvasJSChart options = {options} containerProps={containerProps} onRef={ref => chartRef.current = ref}/>

          {/*<div*/}
          {/*  id="chartdiv_stats_account_profit"*/}
          {/*  style={{width: '100%', height: '400px'}}*/}
          {/*></div>*/}
        </div>
      </div>
    </div>
  )
})

export {CBElementAccountBalance, CBElementAccountProfit}