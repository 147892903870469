import React, { useContext, useEffect } from 'react'
import { GlobalContext } from '../../state'
import useGetRights from '../../hooks/useGetRights'
import { useLocation } from 'react-router-dom'
import { TiChevronRight } from 'react-icons/ti'
import Burger from '../Navigation/Burger'
import SidebarNav from '../Navigation/SidebarNav'
import { Outlet } from 'react-router-dom'
import {createTheme} from "@mui/material";

// Dark theme
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#161a1e',
      paper: '#6a7680',
    },
    text: {
      primary: '#e1eeff',
      secondary: '#b3b3b3',
    },
  },
});

const Layout = React.forwardRef(
  ({ toggleMenuActive, setGlobalState }, ref) => {
    // Global state
    const globalState = useContext(GlobalContext)
    const location = useLocation()
    const getRights = useGetRights()

    const menuActiveClass = (isActive, currentClasses) => {
      return isActive ? `${currentClasses} is-active` : `${currentClasses}`
    }

    const locationPathname2Array = (pathname) =>
      pathname.split('/').filter((el) => el)

    useEffect(() => {
      if (!globalState.activeMenuItem) {
        // console.log({ globalState })
        setGlobalState((prevState) => ({
          ...prevState,
          activeMenuItem: prevState.sidebarNavItems.filter(
            (item) =>
              item.path === `/${locationPathname2Array(location.pathname)[0]}`
          )[0].name,
        }))
      }
    })

    return (
      <div ref={ref} className="container">
        <header>
          <div className="flex-container">
            <Burger
              onClick={toggleMenuActive}
              className={menuActiveClass(
                globalState.isMenuActive,
                'hamburger hamburger--squeeze'
              )}
            />
          </div>
        </header>
        <nav>
          <div className="breadcrumb-container">
            {locationPathname2Array(location.pathname).map((item, idx, arr) => {
              if (idx < arr.length - 1)
                return (
                  <div key={idx}>
                    <a href={`/${item}`}>{item}</a>
                    <TiChevronRight />
                  </div>
                )
              return <span key={idx}>{item}</span>
            })}
          </div>
        </nav>
        <main> <Outlet /></main>
        <aside className={menuActiveClass(globalState.isMenuActive, '')}>
          <SidebarNav
            navItems={globalState.sidebarNavItems}
            activeItem={globalState.activeMenuItem}
            rights={getRights()}
            onClick={toggleMenuActive}
          />
        </aside>
        <footer>crazybot.io</footer>
      </div>
    )
  }
)

export default Layout
