export default function sanitizeData(data, wasFetchSuccessful) {
  if (wasFetchSuccessful) {
    return data.map(
      ({ _id, name, state, threecommasid, stats, max_active_deals, max_active_deals_long, max_active_deals_short,
         max_active_deals_long_on, max_active_deals_short_on, usd_amount_min, usd_amount_min_currency,
         cycledelay, panicsellaftermissedtrailing_timer}) => ({
        _id: _id,
        name,
        state: state ? state : 'stop',
        threecommasid,
        stats,
        max_active_deals: max_active_deals ? max_active_deals : -1,
        max_active_deals_long: max_active_deals_long ? max_active_deals_long : -1,
        max_active_deals_short: max_active_deals_short ? max_active_deals_short : -1,
        max_active_deals_long_on: max_active_deals_long_on ? max_active_deals_long_on : -1,
        max_active_deals_short_on: max_active_deals_short_on ? max_active_deals_short_on : -1,
        usd_amount_min: usd_amount_min ? usd_amount_min : 0,
        usd_amount_min_currency: usd_amount_min_currency ? usd_amount_min_currency : 'USDT',
        cycledelay: cycledelay ? cycledelay : 120,
        panicsellaftermissedtrailing_timer: panicsellaftermissedtrailing_timer ? panicsellaftermissedtrailing_timer : 15,
      })
    )
  }
}
