import React, { useEffect, useState, useCallback, useRef } from 'react'
import { GlobalContext, state } from './state'
import { useNavigate } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import debounce from './utils/debounce'
import Layout from './components/Layout/Layout'
import LoginPage from './components/Pages/LoginPage'
import DashboardPage from './components/Pages/Dashboard/DashboardPage'
import AccountPage from './components/Pages/AccountPage/AccountPage'
import AccountDetailPage from './components/Pages/AccountPage/AccountDetailPage'
import ThreeCmaxbotPage from './components/Pages/ThreeCmaxbotPage/ThreeCmaxbotPage'
import ThreeCmaxbotDetailPage from './components/Pages/ThreeCmaxbotPage/ThreeCmaxbotDetailPage'
import SmartbotPage from './components/Pages/SmartbotPage/SmartbotPage'
import SmartbotDetailPage from './components/Pages/SmartbotPage/SmartbotDetailPage'
import GridbotPage from './components/Pages/GridbotPage/GridbotPage'
import GridbotDetailPage from './components/Pages/GridbotPage/GridbotDetailPage'
import BacktestPage from './components/Pages/BacktestPage/BacktestPage'
import BacktestDetailPage from './components/Pages/BacktestPage/BacktestDetailPage'
import SignalPage from './components/Pages/SignalPage/SignalPage'
import BotSetupPage from './components/Pages/BotSetupPage'
import HelpGuidePage from './components/Pages/HelpGuidePage'
import LinksPage from './components/Pages/LinksPage'
import UserPage from './components/Pages/UserPage'
// import {  Router } from ''
import {
  Routes, Route
} from "react-router-dom";
// import { ReactQueryDevtools } from 'react-query/devtools'
import SignalProcessorDetailPage from "./components/Pages/SignalPage/SignalProcessorDetailPage";
import SignalProcessorPage from "./components/Pages/SignalPage/SignalProcessorPage";
import Modal, {ModalProvider} from './components/Modal/Modal'
import {TextField} from "@mui/material";

// Config Overrides (import config-overrides.js)
// import config from './config-overrides'

// revert to 94448cb95fca248bf386877e08a28ede2ac715f9
// Der Merge hat mich verunsichert.

const queryClient = new QueryClient()


function App() {
  const [mainContainerHeight, setMainContainerHeight] = useState(0)
  const [globalState, setGlobalState] = useState(state)
  const mainContainerRef = useRef()
  const navigate = useNavigate()

  const getMainContainerHeight = (appContainer) => {
    return appContainer.getElementsByTagName('main')[0].getBoundingClientRect()
      .height
  }

  const onRefChange = useCallback((node) => {
    if (node !== null) {
      mainContainerRef.current = node
      setMainContainerHeight(getMainContainerHeight(node))
    }
  }, [])

  const resizeListener = useCallback((evt) => {
    if (mainContainerRef.current) {
      setMainContainerHeight(getMainContainerHeight(mainContainerRef.current))
    }
  }, [])

  const toggleMenuActive = (evt) => {
    evt.preventDefault()
    const { currentTarget } = evt
    if (currentTarget.tagName === 'A') {
      setGlobalState((prev) => ({
        ...prev,
        activeMenuItem: currentTarget.parentElement.dataset.itemName,
        isMenuActive: !prev.isMenuActive,
      }))
      navigate(currentTarget.dataset.itemPath)
    } else {
      setGlobalState((prev) => ({
        ...prev,
        isMenuActive: !prev.isMenuActive,
      }))
    }
  }

  useEffect(() => {
    window.addEventListener('resize', debounce(resizeListener))
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [resizeListener])

  return (
    <ModalProvider>
    <GlobalContext.Provider value={globalState}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route index element={<LoginPage setGlobalState={setGlobalState} />}/>
          <Route
            element={<Layout ref={onRefChange} toggleMenuActive={toggleMenuActive} setGlobalState={setGlobalState} />}
          >
            <Route
              path="/dashboard"
              element={<DashboardPage mainContainerHeight={mainContainerHeight} />}
            />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/account/:id" element={<AccountDetailPage />} />
            <Route path="/3cmaxbot" element={<ThreeCmaxbotPage />} />
            <Route path="/3cmaxbot/:id" element={<ThreeCmaxbotDetailPage />} />
            <Route path="/smartbot" element={<SmartbotPage />} />
            <Route path="/smartbot/:id" element={<SmartbotDetailPage />} />
            <Route
              path="/gridbot"
              element={<GridbotPage mainContainerHeight={mainContainerHeight} />}
            />
            <Route path="/gridbot/:id" element={<GridbotDetailPage Global={globalState} />} />
            <Route
              path="/backtest"
              element={<BacktestPage mainContainerHeight={mainContainerHeight} />}
            />
            <Route
              path="/backtest/:backtestId/smartbot/:id"
              element={<SmartbotDetailPage mainContainerHeight={mainContainerHeight} />}
            />
            <Route path="/backtest/add/:bot_id" element={<BacktestDetailPage mainContainerHeight={mainContainerHeight} />} />
            <Route path="/backtest/:id" element={<BacktestDetailPage mainContainerHeight={mainContainerHeight} />} />
            <Route path="/signal" element={<SignalPage mainContainerHeight={mainContainerHeight} />} />
            <Route path="/signalprocessor" element={<SignalProcessorPage mainContainerHeight={mainContainerHeight} />} />
            <Route path="/signalprocessor/:id" element={<SignalProcessorDetailPage mainContainerHeight={mainContainerHeight} />} />
            <Route path="/bot-setup" element={<BotSetupPage />} />
            <Route path="/help" element={<HelpGuidePage />} />
            <Route path="/links" element={<LinksPage />} />
            <Route path="/user" element={<UserPage />} />
          </Route>
        </Routes>
        {/*<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />*/}
       </QueryClientProvider>
    </GlobalContext.Provider>
    </ModalProvider>
  )
}
export default App
