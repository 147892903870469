import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../state'
import tokenFromLS from '../utils/tokenFromLS'

function useFetchUser(id) {
  const globalState = useContext(GlobalContext)
  const [userData, setUserData] = useState([])
  useEffect(() => {
    const apiHost = process.env.REACT_APP_API_URI
    let resource = `${apiHost}/user`
    if (id) {
      resource = `${apiHost}/user/${id}`
    }
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const json = await response.json()
        setUserData(json)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()
  })
  return [userData, setUserData]
}

function useFetchUserPaypment(id) {
  const globalState = useContext(GlobalContext)
  const [userPaymentData, setUserPaymentData] = useState([])
  useEffect(() => {
    const apiHost = process.env.REACT_APP_API_URI
    // const resource = `${apiHost}/user/${id}/payment`
    const resource = `${apiHost}/user/payment`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const json = await response.json()
        setUserPaymentData(json.data)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()

  }, [])
  return [userPaymentData, setUserPaymentData]
}

export { useFetchUser, useFetchUserPaypment }
