const Burger = ({ className, onClick }) => {
  return (
    <div onClick={onClick} className={className}>
      <div className='hamburger-box'>
        <div className='hamburger-inner'></div>
      </div>
    </div>
  );
};

export default Burger;
