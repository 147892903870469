import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../state'
import tokenFromLS from '../utils/tokenFromLS'
import {useQuery} from "react-query";

function useFetcher(url, method, sanatizer = null, data = {}, options = {}) {
  const globalState = useContext(GlobalContext)
  const [responseData, setResponseData] = useState(null)

  useEffect(() => {
    const apiHost = process.env.REACT_APP_API_URI
    let resource = `${apiHost}/${url}`

    let fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState?.token)}`,
      },
    }

    switch (method) {
      case 'GET':
        fetchOptions = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokenFromLS(globalState?.token)}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        break
      case 'POST':
        fetchOptions = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${tokenFromLS(globalState?.token)}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        break
      default:
        break
    }

    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const json = await response.json()
        if (sanatizer) {
          setResponseData(sanatizer(json))
        } else {
          setResponseData(json)
        }
      } catch (error) {
        return error
      }
    }
    fetchData()


    // const fetchBots = () =>
    //   fetch(resource, fetchOptions).then((res) => res.json())
    // // useQuery call
    // const {isError, isLoading, isSuccess, error, data} = useQuery(
    //   'gridbots',
    //   fetchBots,
    //   {refetchInterval: 60000}
    // )


  }, [])
  return [responseData, setResponseData]
}

export default useFetcher
