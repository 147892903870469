import { useEffect } from 'react'
import {
  BiDetail,
  BiUserCircle,
  BiBot,
  BiBrain,
  BiGrid,
  BiShoppingBag,
  BiBroadcast,
  BiCog,
  BiBuoy,
  BiLinkAlt,
} from 'react-icons/bi'
import {
  GiProcessor,
} from 'react-icons/gi'

const SidebarNav = ({ onClick, navItems, activeItem, rights }) => {
  const iconsMap = {
    BiDetail,
    BiUserCircle,
    BiBot,
    BiBrain,
    BiGrid,
    BiShoppingBag,
    BiBroadcast,
    BiCog,
    BiBuoy,
    BiLinkAlt,
    GiProcessor,
  }

  useEffect(() => {
    // console.log(rights)
  }, [])

  const renderItem = (item, DynamicIcon) => (
    <li
      key={item.name}
      className={activeItem === item.name ? 'active' : ''}
      data-item-name={item.name}
    >
      <a data-item-path={item.path} onClick={onClick}>
        <DynamicIcon key={item.icon} size={36} />
        {item.name}
      </a>
    </li>
  )

  const renderItems = (item) => {
    const DynamicIcon = iconsMap[item.icon]

    if (item.checkRight) {
      if (
        rights[item.right] &&
        item.right in rights &&
        rights[item.right] === true
      ) {
        return renderItem(item, DynamicIcon)
      } else {
        return null
      }
    } else {
      return renderItem(item, DynamicIcon)
    }
  }

  return <ul className="sidebar-nav">{navItems.map(renderItems)}</ul>
}

export default SidebarNav
