import { useContext } from 'react'
import { GlobalContext } from '../state'

const useGetRights = () => {
  const globalState = useContext(GlobalContext)
  return () => {
    if (Object.keys(globalState.rights).length) {
      return globalState.rights
    }
    return JSON.parse(localStorage.getItem('rights'))
  }
}

export default useGetRights
