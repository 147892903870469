import tokenFromLS from "../tokenFromLS";
import {state as globalState} from "../../state";
import CBApi from "./CBApi";

class CBExchange extends CBApi {

  static fetchOHLCVData(exchange, symbol, resolution, from, to, countBack) {
    let url = `exchange/ohlcv/${exchange}/${symbol}/${resolution}?since=${from}&to=${to}`;
    if (countBack && countBack > 0) {
      url += `&countBack=${countBack}`;
    }
    return CBExchange.fetch(url);
  }

  static fetchSymbols() {
    const url = `exchange/symbols`;
    return CBExchange.fetch(url);
  }
}

export default CBExchange;