import {useEffect, useState} from "react";
import CBAPIClass from "../utils/cbapi";
import {sanitizeBacktestData} from "../utils/sanatizer";

function useFetchBacktest() {
  const [backtestData, setBacktestData] = useState([])
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await CBAPIClass.fetch('backtest')
        const json = await response.json()

        // Set isSuccess:
        json.isSuccess = json.status === 'ok'

        setBacktestData(json)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()

  }, [])
  return [backtestData, setBacktestData]
}
function useFetchBacktestId(Id) {
  const [backtestData, setBacktestData] = useState([])
  useEffect(() => {

    // Check, the id is valid:
    if (!Id.match(/^[0-9a-fA-F]{24}$/)) {
      return
    }
    const fetchData = async () => {
      try {
        const response = await CBAPIClass.fetch('backtest/' + Id)
        const json = await response.json()

        // Set isSuccess:
        json.isSuccess = json.status === 'ok'

        setBacktestData(sanitizeBacktestData(json.data))
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()

  }, [])
  return [backtestData, setBacktestData]
}

export { useFetchBacktest, useFetchBacktestId }
