import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import SwitchCheckbox from '../Switch/SwitchCheckbox'

const Dismiss = ({ onClick }) => {
  return (
    <span className="dismiss" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="currentColor"
        className="bi bi-chevron-down"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </span>
  )
}

const Fieldset = ({
  legend = '',
  columns,
  dismiss = false,
  collapsed = false,
  switchable = false,
  disabled = false,
  children,
}) => {
  const [collapsedState, setCollapsedState] = useState(collapsed)
  const [fieldsetDisabled, setFieldsetDisabled] = useState(disabled)
  const fieldsetRef = useRef()

  const handleFieldsetSwitch = () => {
    setFieldsetDisabled((prevState) => !prevState)
  }

  return (
    <fieldset
      disabled={fieldsetDisabled}
      ref={fieldsetRef}
      className={collapsedState ? 'hide' : ''}
    >
      {dismiss ? (
        <Dismiss
          onClick={() => {
            setCollapsedState((prevState) => !prevState)
          }}
        />
      ) : null}
      {switchable ? (
        <legend className="with-switch">
          <span>{legend}</span>
          <SwitchCheckbox
            isFieldsetSwitch={true}
            checked={fieldsetDisabled}
            onChange={handleFieldsetSwitch}
          />
        </legend>
      ) : (
        <legend>
          <span>{legend}</span>
        </legend>
      )}

      <div style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
        {children}
      </div>
    </fieldset>
  )
}

Fieldset.propTypes = {
  legend: PropTypes.string,
  columns: PropTypes.number.isRequired,
  dismiss: PropTypes.bool,
  collapsed: PropTypes.bool,
  switchable: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Fieldset
