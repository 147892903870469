import tokenFromLS from "./tokenFromLS";
import {state as globalState} from "../state";

class CBAPIClass {
  static API_URL = process.env.REACT_APP_API_URI;

  static async fetch(path, method = 'GET', params) {
    const resource = `${CBAPIClass.API_URL}/${path}`;
    const fetchOptions = {
      method: method,
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    const response = await fetch(resource, fetchOptions)

    return response

    // return fetch(resource, fetchOptions)
    //   .then(response => response.json())
    //   .catch(error => console.error('Error:', error));
  }
}

export default CBAPIClass;