import React, {useContext, useState} from "react";
import {GlobalContext} from "../../../state";
import tokenfromLS from "../../../utils/tokenFromLS";
import {useQuery} from "react-query";
import {ReactTabulator} from "react-tabulator";
import {sanitizeSignalData} from "../../../utils/sanatizer";
import {SignalTableColumns} from './tableColumns'
// import {Link} from "react-router-dom";
// import {BiEditAlt} from "react-icons/bi";
// import {MdDelete} from "react-icons/md";
import Modal from "../../Modal/Modal";


const SignalPage = (mainContainerHeight) => {

  const [showModalDelete, setShowModalDelete] = useState(false)
  // const [accountData, setAccountData] = useFetcher(
  //   `signal/last`,
  //   'GET',
  //   sanitizeSignalData,
  //   null,
  //   {refetchInterval: 60000}
  // )

  // Global state
  const globalState = useContext(GlobalContext)
  // Resource URI
  const resource = `${process.env.REACT_APP_API_URI}/signal/last`
  // Fetch options
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
    },
  }
  // Fetch Fn for useQuery
  const fetchData = () =>
    fetch(resource, fetchOptions).then((res) => res.json())
  // useQuery call
  const {isError, isLoading, isSuccess, error, data} = useQuery(
    'signal',
    fetchData,
    {refetchInterval: 60000}
  )

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>`An error has occurred: ${error.message}`</span>
  }

  // const EditButton = (props) => {
  //   const rowData = props.cell._cell.row.data
  //   return (
  //     <Link to={`/signalprocessor/${rowData._id}`}>
  //       <BiEditAlt size={26}/>
  //     </Link>
  //   )
  // }

  const dismissModalDelete = () => {
    setShowModalDelete(null)
  }

  const submitModalDelete = async () => {
    const id = showModalDelete._id
    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/signalprocessor/${id}`
      const response = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokenfromLS()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      const json = await response.json()
      if (json.hasOwnProperty('ok')) {
        // let navigate = useNavigate()
        // navigate('/account');
        console.log('ok')
        setShowModalDelete(null)
      } else {
        console.log('error: ' + json)
        //   setformSubmitMsg(json)
      }
    } catch (err) {
      console.log(err)
    }
  }

  // const ButtonDelete = (props) => {
  //   const rowData = props.cell._cell.row.data
  //   return (
  //     <div onClick={(e) => {
  //       e.preventDefault()
  //       setShowModalDelete(rowData)
  //     }}><MdDelete size={26}/></div>
  //   )
  // }

  // const SignalProcessorTableColumns = [
  //   {
  //     title: '_id',
  //     field: '_id',
  //     visible: false,
  //   },
  //   {
  //     title: 'Edit',
  //     field: 'custom',
  //     width: 48,
  //     formatter: reactFormatter(<EditButton/>),
  //     hozAlign: 'center',
  //     vertAlign: 'middle',
  //   },
  //   {
  //     title: 'Name',
  //     field: 'name',
  //     hozAlign: 'center',
  //     vertAlign: 'middle',
  //     headerFilter: 'input',
  //     headerFilterFunc: 'like',
  //   },
  //   {
  //     title: 'Account',
  //     field: 'account_id',
  //     hozAlign: 'center',
  //     vertAlign: 'middle',
  //     headerFilter: 'input',
  //     headerFilterFunc: 'like',
  //   },
  //   {
  //     title: 'Date',
  //     field: 'lastdate',
  //     hozAlign: 'center',
  //     vertAlign: 'middle',
  //     headerFilter: 'input',
  //     headerFilterFunc: 'like',
  //   },
  //   {
  //     title: 'Delete',
  //     field: 'custom',
  //     width: 48,
  //     formatter: reactFormatter(<ButtonDelete/>),
  //     hozAlign: 'center',
  //     vertAlign: 'middle',
  //   },
  // ]

  return (
    <React.Fragment>
      <div>
        <>
          <h1>Incoming Signals (last 50)</h1>
        </>
        {data ? (
          <ReactTabulator
            options={{height: mainContainerHeight}}
            columns={SignalTableColumns}
            data={sanitizeSignalData(data, isSuccess)}
          />
        ) : null}
      </div>


    </React.Fragment>

  );
};

export default SignalPage;
