import React, {useEffect, useState} from "react";
import tokenFromLS from "../../utils/tokenFromLS";
import {state as globalState} from "../../state";
import {ReactTabulator} from "react-tabulator";
import {useFetchUserPaypment} from "../../hooks/useFetchUser";
import {useModal} from "../Modal/Modal";
import InputField from "../EditFields/Input/InputField";
import Select from "../EditFields/Select/Select";


const CBElementUserFee = React.forwardRef((props, ref) => {
  const [userFee, setUserFee] = useState([])

  function getUserFee() {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/user/fee`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const ReturnData = await response.json()
        if (ReturnData.status === 'ok') {
          return ReturnData.data
        } else {
          console.log('error', ReturnData)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()
  }

  useEffect(() => {
    // console.log('useEffect', botData)
    getUserFee().then((data) => {
      setUserFee(data)
    })
  }, [])

  return (
    <div>
      <div draggable="true" className="cb-element-draggable-header">Balance Overview</div>
      <div className="cb-element-content">
        <div className="cb-element-user-fee">
          {Object.entries(userFee).map(([key, value]) => (
            <div key={key}> {key}: {(value?.feeleft ?? 0.0).toFixed(2)}$</div>
          ))}
        </div>
      </div>
    </div>
  )
})

const CBElementUserPayment = React.forwardRef((props, ref) => {
  const UserPaymentTableColumns = [
    {
      title: 'id',
      field: '_id',
      visible: false,
    },
    {
      title: 'Name',
      field: 'signalgroup',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Amount',
      field: 'amount',
      hozAlign: 'right',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
      formatter: 'money',
      formatterParams: {symbol: '$'},
    },
    {
      title: 'Type',
      field: 'type',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Date',
      field: 'createdate',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
      // formatter: 'datetime',
      // formatterParams: {
      //   inputFormat: 'YYYY-MM-DD HH:mm:ss',
      //   outputFormat: 'YYYY-MM-DD HH:mm:ss',
      // },
    },
  ]

  const [userPayment] = useFetchUserPaypment()

  return (
    <div>
      <div draggable="true" className="cb-element-draggable-header">Payment Overview</div>
      <div className="cb-element-content">
        <div className="cb-element-payment-overview">
          <ReactTabulator
            columns={UserPaymentTableColumns}
            data={userPayment}
          />
        </div>
      </div>
    </div>
  )
})

const CBElementUserVoucher = React.forwardRef((props, ref) => {
  const [voucher, setVoucher] = useState({vouchertype: 'voucher', vouchercode: ''});
  // const [vouchertype, setVoucherType] = useState('voucher');
  const {openModal} = useModal();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (voucher.vouchercode === '') {
      openModal({
        content: 'Please enter a voucher code.',
        showDismissButton: true,
        showFooter: true,
      });
      return;
    }

    const apiHost = process.env.REACT_APP_API_URI;
    const resource = `${apiHost}/user/voucher`;

    try {
      const response = await fetch(resource, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
        },
        // body: JSON.stringify({vouchertype: vouchertype, vouchercode: voucher}),
        body: JSON.stringify(voucher),
      });

      const data = await response.json();

      if (data.status === 'ok') {

        // Call the function that opens the modal
        openModal({
          content: data.data ?? 'Voucher successfully redeemed. We will manually check the voucher and update your balance. We need to check the voucher manually, so it may take some time. Thank you for your patience.',
          showSubmitButton: true,
          showDismissButton: false,
          showFooter: true,
          submitFn: () => {
            // Refresh the page
            window.location.reload();
          },
        });
      } else {
        openModal({
          content: data.error_description ?? 'An error occurred while redeeming the voucher. Please try again later.',
          showDismissButton: true,
          showFooter: true,
        });
      }
    } catch (error) {
      openModal({
        content: 'An error occurred while redeeming the voucher. Please try again later.',
        showDismissButton: true,
        showFooter: true,
      });
    }

  };

  return (
    <div>
      <div draggable="true" className="cb-element-draggable-header">Commit your Voucher</div>
      <div className="cb-element-content">
        <div className="cb-element-user-voucher">
          <form style={{display: 'flex', alignItems: 'center'}} onSubmit={handleSubmit}>
            <label>
              {/*Type:*/}
              <div>
                {/*<select value={vouchertype} onChange={e => setVoucherType(e.target.value)}>*/}
                {/*  <option value="voucher">Voucher</option>*/}
                {/*  <option value="amazon">Amazon</option>*/}
                {/*</select>*/}
                {/*<input type="text" name="voucher" size={13} value={voucher} onChange={e => setVoucher(e.target.value)}/>*/}
                <Select
                  name="vouchertype"
                  label="Type"
                  value={voucher.vouchertype}
                  options={[
                    {name: 'Voucher', value: 'voucher'},
                    {name: 'Amazon', value: 'amazon'},
                  ]}
                  onChange={function (evt) {
                    setVoucher((prevState) => ({
                      ...prevState,
                      vouchertype: evt.target.value,
                    }))
                  }}
                />
                <InputField
                  type="text"
                  name="voucher"
                  label="Code"
                  value={voucher.vouchercode}
                  onChange={function (evt) {
                    setVoucher((prevState) => ({
                      ...prevState,
                      vouchercode: evt.target.value,
                    }))
                  }}
                />
                <input type="submit" value="Submit" className="submit-button"/>
              </div>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
});

export {CBElementUserFee, CBElementUserPayment, CBElementUserVoucher}
