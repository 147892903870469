import React, {useContext, useEffect, useState} from 'react'
import {GlobalContext} from '../../../state'
import {useQuery} from 'react-query'
import 'react-tabulator/lib/styles.css' // required styles
import 'react-tabulator/lib/css/tabulator.min.css' // theme
import {reactFormatter, ReactTabulator} from 'react-tabulator'
import tokenfromLS from '../../../utils/tokenFromLS'
import sanitizeData from './sanitizeData'
// import ThreeCMaxBotTableColumns from './tableColumns'
import {Link} from "react-router-dom";
import {BiCaretRight, BiDownArrowAlt, BiEditAlt, BiImageAdd, BiStop, BiUpArrowAlt} from "react-icons/bi";
import {MdDelete} from "react-icons/md";
import Modal from "../../Modal/Modal";

const ThreeCmaxbotPage = (mainContainerHeight) => {
  const [showModalDelete, setShowModalDelete] = useState(false)

  // Global state
  const globalState = useContext(GlobalContext)
  // API Host Address
  const apiHost = process.env.REACT_APP_API_URI
  // Resource URI
  const resource = `${apiHost}/3cmaxbot`
  // Fetch options
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
    },
  }
  // Fetch Fn for useQuery
  const fetchAccounts = () =>
    fetch(resource, fetchOptions).then((res) => res.json())
  // useQuery call
  const {isError, isLoading, isSuccess, error, data} = useQuery(
    '3cmaxbot',
    fetchAccounts,
    {refetchInterval: 60000}
  )

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>`An error has occurred: ${error.message}`</span>
  }

  const EditButton = (props) => {
    const rowData = props.cell._cell.row.data
    return (
      <a href={`/3cmaxbot/${rowData._id}`}>
        <BiEditAlt color="#454b66" size={26}/>
      </a>
    )
  }

  const PushButtonStartStop = async (id, start) => {
    try {
      let RequestURL = ``
      let Method = 'GET'
      if (start) {
        RequestURL = `${process.env.REACT_APP_API_URI}/3cmaxbot/${id}/start`
      } else {
        RequestURL = `${process.env.REACT_APP_API_URI}/3cmaxbot/${id}/stop`
      }
      console.log('PushButtonStartStop 1', id, start)
      const response = await fetch(RequestURL, {
        method: Method,
        headers: {
          Authorization: `Bearer ${tokenfromLS()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      console.log('PushButtonStartStop 2')

      const json = await response.json()
      if (json.hasOwnProperty('ok')) {
        // navigate('/3cmaxbot');
        console.log('ok')
      } else {
        console.log('error: ' + json)
        //   setformSubmitMsg(json)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const StartStopButton = (props) => {
    const rowData = props.cell._cell.row.data
    const [isRunning, setIsRunning] = useState(rowData.state.includes('running'))

    useEffect(() => {
      setIsRunning(rowData.state.includes('running'))
    }, [rowData])

    const icon = isRunning ? (
      <BiStop color="#454b66" size={26}/>
    ) : (
      <BiCaretRight color="#454b66" size={26}/>
    )

    return (
      <div
        onClick={(evt) => {
          PushButtonStartStop(rowData._id, !isRunning)
        }}
      >
        {icon}
      </div>
    )
  }

  const ActiveMaxDeals = (props) => {
    const rowData = props.cell._cell.row.data
    const UnlimitedSign = '*'

    const max_active_deals = parseInt(
      rowData.max_active_deals ? rowData.max_active_deals : 0
    )
    const active_long_bots = parseInt(
      rowData.stats && rowData.stats.active_long_bots
        ? rowData.stats.active_long_bots
        : 0
    )
    const active_short_bots = parseInt(
      rowData.stats && rowData.stats.active_short_bots
        ? rowData.stats.active_short_bots
        : 0
    )
    let max_active_deals_long = parseInt(
      rowData.max_active_deals_long ? rowData.max_active_deals_long : 0
    )
    let max_active_deals_short = parseInt(
      rowData.max_active_deals_short ? rowData.max_active_deals_short : 0
    )
    if (max_active_deals_long > max_active_deals)
      max_active_deals_long = max_active_deals
    if (max_active_deals_short > max_active_deals)
      max_active_deals_short = max_active_deals
    const OutputStringLong =
      (active_long_bots === -1 ? UnlimitedSign : active_long_bots) +
      ' / ' +
      (max_active_deals_long === -1 ? UnlimitedSign : max_active_deals_long)
    const OutputStringShort =
      (active_short_bots === -1 ? UnlimitedSign : active_short_bots) +
      ' / ' +
      (max_active_deals_short === -1 ? UnlimitedSign : max_active_deals_short)

    const OutputColorLong =
      rowData.stats && rowData.stats.enabled_long_bots ? '#7aab58' : '#ffffff'
    const OutputColorShort =
      rowData.stats && rowData.stats.enabled_short_bots ? '#7aab58' : '#ffffff'

    return (
      <div>
        <BiUpArrowAlt color={OutputColorLong}/>
        {OutputStringLong} - <BiDownArrowAlt color={OutputColorShort}/>
        {OutputStringShort}
      </div>
    )
  }

  const BalanceMin = (props) => {
    const rowData = props.cell._cell.row.data
    const UnlimitedSign = '*'

    let account_balance_sum_usd = parseFloat(
      rowData.stats && rowData.stats.account_balance_sum_usd
        ? rowData.stats.account_balance_sum_usd
        : -1
    )
    let usd_amount_min = parseFloat(
      rowData.usd_amount_min ? rowData.usd_amount_min : -1
    )
    if (account_balance_sum_usd === -1) account_balance_sum_usd = UnlimitedSign
    else
      account_balance_sum_usd =
        account_balance_sum_usd.toFixed(2) +
        ' ' +
        (rowData.usd_amount_min_currency
          ? rowData.usd_amount_min_currency
          : 'USDT')
    if (usd_amount_min === -1) usd_amount_min = UnlimitedSign
    else
      usd_amount_min =
        usd_amount_min.toFixed(2) +
        ' ' +
        (rowData.usd_amount_min_currency
          ? rowData.usd_amount_min_currency
          : 'USDT')

    return (
      <div>
        {account_balance_sum_usd} / {usd_amount_min}
      </div>
    )
  }

  const dismissModalDelete = () => {
    setShowModalDelete(null)
  }

  const submitModalDelete = async () => {
    const id = showModalDelete._id
    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/3cmaxbot/${id}`
      const response = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokenfromLS()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      const json = await response.json()
      if (json.hasOwnProperty('ok')) {
        // let navigate = useNavigate()
        // navigate('/account');
        console.log('ok')
        setShowModalDelete(null)
      } else {
        console.log('error: ' + json)
        //   setformSubmitMsg(json)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const ButtonDelete = (props) => {
    const rowData = props.cell._cell.row.data
    return (
      <div onClick={(e) => {
        e.preventDefault()
        setShowModalDelete(rowData)
      }} ><MdDelete size={26} /></div>
    )
  }

  const TableColumns = [
    {
      title: '_id',
      field: '_id',
      visible: false,
    },
    {
      title: 'Edit',
      field: 'custom',
      width: 48,
      formatter: reactFormatter(<EditButton/>),
      hozAlign: 'center',
      vertAlign: 'middle',
    },
    {
      title: 'Start Stop',
      field: 'custom',
      width: 48,
      formatter: reactFormatter(<StartStopButton/>),
      hozAlign: 'center',
      vertAlign: 'middle',
    },
    {
      title: 'Name',
      field: 'name',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: '3Commas ID',
      field: 'threecommasid',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Active / Max Deals',
      field: 'activemaxdeals',
      hozAlign: 'center',
      vertAlign: 'middle',
      formatter: reactFormatter(<ActiveMaxDeals/>),
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Balance / Min',
      field: 'balancemin',
      hozAlign: 'center',
      vertAlign: 'middle',
      formatter: reactFormatter(<BalanceMin/>),
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'State',
      field: 'state',
      hozAlign: 'center',
      vertAlign: 'middle',
      headerFilter: 'input',
      headerFilterFunc: 'like',
    },
    {
      title: 'Delete',
      field: 'custom',
      width: 48,
      formatter: reactFormatter(<ButtonDelete/>),
      hozAlign: 'center',
      vertAlign: 'middle',
    },
  ]

  return (
    <React.Fragment>
      {showModalDelete ? (
        <Modal
          dismissFn={dismissModalDelete}
          submitFn={submitModalDelete}
          showFooter={true}
          // showLoader={showModalLoader}
        >
          <p>
            Do you really want to delete the bot {showModalDelete.name}?
          </p>
        </Modal>
      ) : null}
      <div>
        <ReactTabulator
          options={{height: mainContainerHeight}}
          columns={TableColumns}
          data={sanitizeData(data, isSuccess)}
        />
        <a href={`/3cmaxbot/add`}>
          <BiImageAdd color="#454b66" size={26}/>
        </a>
      </div>
    </React.Fragment>

  )
};

export default ThreeCmaxbotPage;
