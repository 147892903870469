import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SwitchCheckbox from '../Switch/SwitchCheckbox'
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'

function InputField({
  type,
  name,
  label,
  value: initialValue = '',
  value = '',
  disabled = false,
  precision = 0,
  uom,
  height,
  checked = false,
  gridcolumns = 'initial',
  pattern = '',
  tooltip = '',
  onChange = () => {},
  // priorValue = initialValue,
  priorValue = value,
  optionsvalue,
  minvalue,
  maxvalue,
  maxLength,
}) {
  const [tooltipStyle, setTooltipStyle] = useState('')
  // const [value, setValue] = useState(initialValue)

  const gridColumnStyle = (columns) => {
    if (columns !== 'initial') {
      return { gridColumn: '1 / span ' + columns }
    }
    return { gridColumn: columns }
  }

  const handleValidation = (e) => {
    const cursorPosition = e.target.selectionStart
    if (pattern !== '') {
      // Pattern for email address
      // const regexx =
      //   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let PatternToCheck = pattern
      if (pattern === 'integer') {
        PatternToCheck = '^[-]*[0-9]+$'
      } else if (pattern === 'word') {
        PatternToCheck = '^[0-9]+$'
      } else if (pattern === 'decimal') {
        PatternToCheck = '^[-]*[0-9]+(.[0-9]{1,2})?$'
      } else if (pattern === 'numeric') {
        PatternToCheck = '^[-]*[0-9]*\\.?[0-9]*$'
      } else if (pattern === 'email') {
        PatternToCheck =
          '^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$'
        // } else if (pattern == 'phone') {
        //   PatternToCheck = /^\d{10}$/
      } else if (pattern === 'url') {
        PatternToCheck = '^(ftp|http|https)://[^ "]+$'
      } else if (pattern === 'date') {
        PatternToCheck = '^d{4}-d{2}-d{2}$/'
      } else if (pattern === 'time') {
        PatternToCheck = '^([0-1][0-9]|2[0-3]):[0-5][0-9]$'
      } else if (pattern === 'datetime') {
        PatternToCheck = '^d{4}-d{2}-d{2}T([0-1][0-9]|2[0-3]):[0-5][0-9]$'
      } else if (pattern === 'objectid') {
        PatternToCheck = '^[0-9a-fA-F]{24}$'
      } else if (pattern === 'alphanumeric') {
        PatternToCheck = '^[a-zA-Z0-9]+$'
      } else if (pattern === 'alphanumericwithspace') {
        PatternToCheck = '^[a-zA-Z0-9 ]+$'
      } else if (pattern === 'alphanumericwithspaceandminus') {
        PatternToCheck = '^[a-zA-Z0-9 -\.]+$'
      } else if (pattern === 'alphanumericwithspecialchar') {
        PatternToCheck = '^[a-zA-Z0-9!@#$%^&*()_+-=[]{};\'\\:"|<,./<>?]+$'
      } else if (pattern === 'alphanumericwithspaceandspecialchar') {
        PatternToCheck = '^[a-zA-Z0-9!@#$%^&*()_+-=[]{};\'\\:"|<,./<>? ]+$'
      } else if (pattern === 'alphanumericwithspaceandspecialcharandcomma') {
        PatternToCheck = '^[a-zA-Z0-9!@#$%^&*()_+-=[]{};\'\\:"|<,./<>? ,]+$'
      } else if (
        pattern === 'alphanumericwithspaceandspecialcharandcommaanddot'
      ) {
        PatternToCheck = '^[a-zA-Z0-9!@#$%^&*()_+-=[]{};\'\\:"|<,./<>? ,.]+$'
      } else if (
        pattern === 'alphanumericwithspaceandspecialcharandcommaanddotandcolon'
      ) {
        PatternToCheck = '^[a-zA-Z0-9!@#$%^&*()_+-=[]{};\'\\:"|<,./<>? ,.:]+$'
      } else if (
        pattern ===
        'alphanumericwithspaceandspecialcharandcommaanddotandcolonandforwardslash'
      ) {
        PatternToCheck = '^[a-zA-Z0-9!@#$%^&*()_+-=[]{};\'\\:"|<,./<>? ,.:/]+$'
      } else if (
        pattern ===
        'alphanumericwithspaceandspecialcharandcommaanddotandcolonandforwardslashandbackslash'
      ) {
        PatternToCheck =
          '^[a-zA-Z0-9!@#$%^&*()_+-=[]{};\'\\:"|<,./<>? ,.:/\\\\]+$'
      }

      // Check, if field has correct pattern
      const regex = new RegExp(PatternToCheck, 'i')
      if (!regex.test(e.target.value)) {
        // Send previous value to e.target.value
        e.target.value = priorValue
        // console.log('invalid: ' + e.target.value)

        // } else {
        // priorValue = e.target.value
        // console.log('valid: ' + e.target.value)
      }

      // Cleanup empty values to 0
      if (
        pattern === 'numeric' ||
        pattern === 'decimal' ||
        pattern === 'integer'
      ) {
        if (e.target.value === '') {
          e.target.value = 0
        }
      }

      // Add precision to decimal values
      if (
        precision > 0 &&
        (pattern === 'decimal' || pattern === 'numeric') &&
        e.target.value !== 0 &&
        e.target.value !== '-'
      ) {
        e.target.value = parseFloat(e.target.value).toFixed(precision)
        e.target.setSelectionRange(cursorPosition, cursorPosition)
      }

      // Min / Max check:
      if (pattern === 'integer' || pattern === 'decimal' || pattern === 'numeric' || pattern === 'integer') {
        if (minvalue !== undefined && e.target.value < minvalue) {
          e.target.value = minvalue
        }
        if (maxvalue !== undefined && e.target.value > maxvalue) {
          e.target.value = maxvalue
        }
      }
    }
    // setValue(e.target.value);
    value = e.target.value
    onChange(e)
  }

  const toggleTooltip = (evt) =>
    setTooltipStyle((prevState) => (prevState ? '' : 'show-tooltip'))

  function handleMultiSelectEvent(event) {
    console.log('event: ', event)
     const selectedValues = event.map((item) => item.value)
      console.log('selectedValues: ', selectedValues)



    onChange(event)
  }

  switch (type) {
    case 'hidden':
      return <input name={name} type={type} value={value} disabled={disabled}/>
    case 'switch':
      return (
        <SwitchCheckbox
          name={name}
          label={label}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
      )
    case 'textarea':
      return (
        <div className="textarea-control" style={gridColumnStyle(gridcolumns)}>
          <div>
            <label htmlFor={name}>{label}</label>
          </div>
          <textarea
            name={name}
            label={label}
            // placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            // height={height}
            // rows={10}
            // rows={rows}
            // cols={cols}
            maxLength={maxLength}
            // pattern={pattern}
            // precision={precision}
            // required={required}
            // readOnly={readOnly}
          >
            {value}
          </textarea>
        </div>
      )
    case 'multiselect': // https://react-select.com/home
      const animatedComponents = makeAnimated();

      // convert all comma values to options:
      const selectoptionsvalue = optionsvalue?.split(',').map((item) => {
        return { value: item, label: item }
      })
      const selectvalue = value?.split(',').map((item) => {
        return { value: item, label: item }
      })
      const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: '#161a1e' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? '#161a1e'
              : isFocused
              ? '#161a1e'
              : null,
            color: isDisabled
              ? '#ccc'
              : isSelected
              ? '#ccc'
              : isFocused
              ? '#ccc'
              : null,
            cursor: isDisabled ? 'not-allowed' : 'default',
          }
        },
      }
      return (
          <div className="multiselect-control" style={gridColumnStyle(gridcolumns)}>
            <div>
              <label htmlFor={name}>{label}</label>
            </div>
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={selectvalue}
                isMulti
                options={selectoptionsvalue}
                onChange={(event) => handleMultiSelectEvent(event)}
                // onChange={onChange}
                styles={colorStyles}
            />
          </div>
        )
    default:
      return (
        <div className="input-control" style={gridColumnStyle(gridcolumns)}>
          <div>
            <label className={tooltip ? 'with-tooltip' : ''} htmlFor={name}>
              {label}
              {tooltip ? (
                <span onClick={toggleTooltip}>
                  <BsFillQuestionCircleFill/>
                </span>
              ) : null}
            </label>
            {tooltip ? (
              <div className="tooltip-wrapper">
                <span
                  dangerouslySetInnerHTML={{__html: tooltip}}
                  className={
                    tooltipStyle ? `tooltip ${tooltipStyle}` : `tooltip`
                  }
                ></span>{' '}
              </div>
            ) : null}
          </div>
          <input
            name={name}
            type={type}
            value={value}
            disabled={disabled}
            className="bot-input"
            // onChange={handleValidation(onChange)}
            onChange={(event) => handleValidation(event)}
          />
          {uom ? <div className="unit-of-measure">{uom}</div> : null}
        </div>
      )
  }
}

InputField.propTypes = {
  type: PropTypes.oneOf(['text', 'textarea', 'switch', 'hidden', 'password', 'multiselect']).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  gridcolumn: PropTypes.string,
  pattern: PropTypes.string,
  value: PropTypes.any,
  initialvalue: PropTypes.any,
  disabled: PropTypes.bool,
  precision: PropTypes.number,
  uom: PropTypes.string,
  height: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func,
  optionsvalue: PropTypes.any,
  minvalue: PropTypes.number,
  maxvalue: PropTypes.number,
  maxLength: PropTypes.number,
}

export default InputField
