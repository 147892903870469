import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {BsFillQuestionCircleFill} from "react-icons/bs";

export default function Select({
  name,
  label,
  value = '',
  disabled = false,
  options = [],
  gridcolumns = 'initial',
  tooltip = '',
  onChange = () => {},
}) {
  const gridColumnStyle = (columns) => {
    if (columns !== 'initial') {
      return { gridColumn: '1 / span ' + columns }
    }
    return { gridColumn: columns }
  }

  const [localValue, setLocalValue] = useState(value)
  const [tooltipStyle, setTooltipStyle] = useState('')

  const localOnChange = () => (evt) => {
    const options = evt.target.options
    const index = options.selectedIndex
    onChange.call(null, evt)
    setLocalValue(options[index].value)
  }

  const toggleTooltip = (evt) =>
    setTooltipStyle((prevState) => (prevState ? '' : 'show-tooltip'))

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <div className="input-control" style={gridColumnStyle(gridcolumns)}>
      <div>
        <label className={tooltip ? 'with-tooltip' : ''} htmlFor={name}>
          {label}
          {tooltip ? (
            <span onClick={toggleTooltip}>
                  <BsFillQuestionCircleFill />
                </span>
          ) : null}
        </label>
        {tooltip ? (
          <div className="tooltip-wrapper">
                <span
                  dangerouslySetInnerHTML={{ __html: tooltip }}
                  className={
                    tooltipStyle ? `tooltip ${tooltipStyle}` : `tooltip`
                  }
                ></span>{' '}
          </div>
        ) : null}
      </div>
      <div className="custom-select">
        <select
          value={localValue}
          name={name}
          disabled={disabled}
          onChange={localOnChange()}
        >
          {options.length
            ? options.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.name}
                </option>
              ))
            : null}
        </select>
        <span className="custom-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            className="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </span>
      </div>
    </div>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  tooltip: PropTypes.string,
  onChange: PropTypes.func,
}
