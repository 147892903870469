// import React from 'react'
// import React, { useContext, useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import {
//   BiCaretRight,
//   BiEditAlt,
//   BiStop,
//   BiUpArrowAlt,
//   BiDownArrowAlt,
// } from 'react-icons/bi'
// import { reactFormatter } from 'react-tabulator'
// import tokenfromLS from '../../../utils/tokenFromLS'
// import { GlobalContext } from '../../../state'
// import { useNavigate } from 'react-router-dom'
// import useFetcher from '../../../hooks/useFetcher'
// import {MdDelete} from "react-icons/md";

const SignalTableColumns = [
  {
    title: '_id',
    field: '_id',
    visible: false,
  },
  {
    title: 'Name',
    field: 'name',
    hozAlign: 'center',
    vertAlign: 'middle',
    headerFilter: 'input',
    headerFilterFunc: 'like',
  },
  {
    title: 'Symbol',
    field: 'symbol',
    hozAlign: 'center',
    vertAlign: 'middle',
    headerFilter: 'input',
    headerFilterFunc: 'like',
  },
  {
    title: 'Action',
    field: 'action',
    hozAlign: 'center',
    vertAlign: 'middle',
    headerFilter: 'input',
    headerFilterFunc: 'like',
  },
  {
    title: 'Date',
    field: 'createdate',
    hozAlign: 'center',
    vertAlign: 'middle',
    headerFilter: 'input',
    headerFilterFunc: 'like',
  },
]


export {SignalTableColumns}
